import React from "react";
import styled from "@emotion/styled";

import { planIcons } from "../../../lib/icons/PlanIcon";
import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { sysBorderPrimary, sysWarningSubtle } from "../../../lib/themes/colors";
import { Flex, Text } from "../../../styles/utility-components";

interface PaidContentBannerProps {
  paidPlanType: "basic" | "growth" | "expert";
  description: React.ReactNode;
  backgroundColor?: string;
  contentAlign?: "center" | "left";
}

function PaidContentBanner({
  paidPlanType,
  description,
  backgroundColor = sysWarningSubtle,
  contentAlign = "center",
}: PaidContentBannerProps) {
  return (
    <Wrapper backgroundColor={backgroundColor}>
      <Flex justifyContent={contentAlign} alignItems="center" gap="8px">
        <UIcon svg={planIcons[paidPlanType]} size={16} />
        <Text>{description}</Text>
      </Flex>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ backgroundColor: string }>(({ backgroundColor }) => ({
  borderBottom: sysBorderPrimary,
  backgroundColor: backgroundColor,
  padding: "16px 24px",
}));

export default PaidContentBanner;
