import React from "react";
import styled from "@emotion/styled";

import UIcon from "../../../../lib/icons/untitled_ui/UIcon";
import { truncateString } from "../../../../lib/utils/truncateString";
import { Flex } from "../../../../styles/utility-components";
import TooltipComponent from "../../../ui/tooltip/Tooltip";

type Props = {
  children: React.ReactNode;
  supplierName: string;
  totalNotices?: number | null;
  aliases?: string[] | null;
};

export function TooltipContent({ aliases, supplierName, totalNotices }: Omit<Props, "children">) {
  return (
    <Flex gap={12} alignItems="top">
      <StyledIcon icon="magicWand" size={24} />
      <Flex column>
        {aliases && aliases.length > 0 ? (
          <div>
            <p>
              When searching for mentions of {truncateString(supplierName, 100)}, we will also look
              for:
            </p>
            <ul>{aliases?.map((alias) => <li key={alias}>{alias}</li>)}</ul>
          </div>
        ) : (
          <p>We will only search for mentions of {truncateString(supplierName, 100)}.</p>
        )}

        {totalNotices ? <TotalNotices>{`${totalNotices} Direct awards`}</TotalNotices> : null}
      </Flex>
    </Flex>
  );
}

export function SupplierTooltip(props: Props) {
  return (
    <TooltipComponent content={<TooltipContent {...props} />} side="left" delayDuration={200}>
      {props.children}
    </TooltipComponent>
  );
}

const StyledIcon = styled(UIcon)({
  // required as something is reducing the width within the tooltip
  minWidth: 24,
});

const TotalNotices = styled.div(({ theme }) => ({
  width: "fit-content",
  justifyContent: "center",
  alignItems: "center",
  padding: "4px 8px",
  borderRadius: 28,
  background: theme.colors.sysPrimarySubtle,
}));
