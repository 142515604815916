import React, { useState } from "react";
import { App } from "antd5";

import { useAddNoticeToList } from "../../../../lib/hooks/api/notices/lists/useAddNoticeToList";
import { useCreateNoticeList } from "../../../../lib/hooks/api/notices/lists/useCreateNoticeList";
import { useNoticeLists } from "../../../../lib/hooks/api/notices/lists/useNoticeLists";
import { useNoticeListsForRecord } from "../../../../lib/hooks/api/notices/lists/useNoticeListsForRecord";
import { useRemoveNoticeFromList } from "../../../../lib/hooks/api/notices/lists/useRemoveNoticeFromList";
import { ListForm } from "../../../user_lists/UserListsModals";
import { CreateListFormProps, SaveToList } from "./SaveToList";

export function SaveToNoticeList({ entityId }: { entityId: string }) {
  const { message } = App.useApp();
  const { data: lists, isLoading, isError } = useNoticeLists();
  const { data: savedNoticeLists } = useNoticeListsForRecord(entityId);
  const { mutate: addToList } = useAddNoticeToList({
    onSuccess: () => message.success("Notice saved to list"),
    onError: () => message.error("Failed to save notice to list"),
  });
  const { mutate: removeFromList } = useRemoveNoticeFromList({
    onSuccess: () => message.success("Notice removed from list"),
    onError: () => message.error("Failed to remove notice from list"),
  });
  const [searchValue, setSearchValue] = useState("");

  const items =
    lists?.allLists.map((list) => ({
      id: list.guid,
      label: list.name,
      isSaved: savedNoticeLists?.some((x) => x.guid === list.guid) ?? false,
    })) ?? [];
  const filteredItems = searchValue
    ? items.filter((item) => item.label.toLowerCase().includes(searchValue.toLowerCase()))
    : items;

  const onItemClick = (id: string, isSaved: boolean) => {
    const list = lists?.allLists.find((x) => x.guid === id);
    if (!list) {
      return;
    }

    // TODO: add missing params
    if (isSaved) {
      removeFromList({
        recordGuid: entityId,
        list,
        contextSource: "Save drawer",
        signals: [],
        recordStage: "",
      });
    } else {
      addToList({
        recordGuid: entityId,
        list,
        contextSource: "Save drawer",
        signals: [],
        recordStage: "",
      });
    }
  };

  return (
    <SaveToList
      items={filteredItems}
      isLoading={isLoading}
      isError={isError}
      onItemClick={onItemClick}
      entityId={entityId}
      entityType="notice"
      onSearch={(text) => setSearchValue(text)}
      CreateListForm={CreateNoticeList}
    />
  );
}

function CreateNoticeList(props: CreateListFormProps) {
  const { message } = App.useApp();
  const { mutate: createList } = useCreateNoticeList({
    onSuccess: () => {
      message.success("Notice list created");
      props.onSuccess();
    },
    onError: () =>
      message.error("Unable to create your list. Please contact us if the problem persists"),
  });

  return (
    <ListForm
      {...props}
      listName=""
      onSubmit={(newList) => createList(newList)}
      isSubmitting={false}
    />
  );
}
