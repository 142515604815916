/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DeleteRequest,
  ListPendingUploads200Response,
  ListPendingUploadsRequest,
  UpdateRequest,
  UrlUploadRequest,
} from '../models';
import {
    DeleteRequestFromJSON,
    DeleteRequestToJSON,
    ListPendingUploads200ResponseFromJSON,
    ListPendingUploads200ResponseToJSON,
    ListPendingUploadsRequestFromJSON,
    ListPendingUploadsRequestToJSON,
    UpdateRequestFromJSON,
    UpdateRequestToJSON,
    UrlUploadRequestFromJSON,
    UrlUploadRequestToJSON,
} from '../models';

export interface DeleteOperationRequest {
    deleteRequest: DeleteRequest;
}

export interface ListPendingUploadsOperationRequest {
    listPendingUploadsRequest: ListPendingUploadsRequest;
}

export interface UpdateOperationRequest {
    updateRequest: UpdateRequest;
}

export interface UrlUploadOperationRequest {
    urlUploadRequest: UrlUploadRequest;
}

/**
 * 
 */
export class DocumentsApi extends runtime.BaseAPI {

    /**
     * 
     */
    async _deleteRaw(requestParameters: DeleteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.deleteRequest === null || requestParameters.deleteRequest === undefined) {
            throw new runtime.RequiredError('deleteRequest','Required parameter requestParameters.deleteRequest was null or undefined when calling _delete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/services/data-svc/documents/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteRequestToJSON(requestParameters.deleteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * 
     */
    async _delete(requestParameters: DeleteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this._deleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async fileUploadRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/services/data-svc/documents/file-upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async fileUpload(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.fileUploadRaw(initOverrides);
    }

    /**
     * 
     */
    async listPendingUploadsRaw(requestParameters: ListPendingUploadsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListPendingUploads200Response>> {
        if (requestParameters.listPendingUploadsRequest === null || requestParameters.listPendingUploadsRequest === undefined) {
            throw new runtime.RequiredError('listPendingUploadsRequest','Required parameter requestParameters.listPendingUploadsRequest was null or undefined when calling listPendingUploads.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/services/data-svc/documents/list-pending-uploads`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListPendingUploadsRequestToJSON(requestParameters.listPendingUploadsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListPendingUploads200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async listPendingUploads(requestParameters: ListPendingUploadsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListPendingUploads200Response> {
        const response = await this.listPendingUploadsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateRaw(requestParameters: UpdateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.updateRequest === null || requestParameters.updateRequest === undefined) {
            throw new runtime.RequiredError('updateRequest','Required parameter requestParameters.updateRequest was null or undefined when calling update.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/services/data-svc/documents/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRequestToJSON(requestParameters.updateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * 
     */
    async update(requestParameters: UpdateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.updateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async urlUploadRaw(requestParameters: UrlUploadOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.urlUploadRequest === null || requestParameters.urlUploadRequest === undefined) {
            throw new runtime.RequiredError('urlUploadRequest','Required parameter requestParameters.urlUploadRequest was null or undefined when calling urlUpload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/services/data-svc/documents/url-upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UrlUploadRequestToJSON(requestParameters.urlUploadRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * 
     */
    async urlUpload(requestParameters: UrlUploadOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.urlUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
