import { lightTheme } from "../../styles/theme";

export const toastOptions = {
  unstyled: true,
  style: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    backgroundColor: lightTheme.colors.sysBackgroundInverse,
    color: lightTheme.colors.sysTextWhite,
    borderRadius: "4px",
    padding: "10px 16px",
    fontSize: "14px",
    boxShadow: `
      hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px
    `,
  },
};

export const toasterStyles = {
  display: "flex",
  justifyContent: "center",
};
