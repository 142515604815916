import React, { ReactNode } from "react";
import { InfoOutlined, MailOutlined, MessageOutlined, PhoneOutlined } from "@ant-design/icons";

import { useSupportChat } from "lib/utils/useSupportChat";

import css from "./ContactUsWidget.module.scss";

interface ContactMethodProps {
  icon: ReactNode;
  title: string;
  description: ReactNode;
  onClick?: () => void;
  href?: string;
  newTab?: boolean;
  showIconCircleBackground?: boolean;
}

function ContactMethodButton({
  icon,
  title,
  description,
  onClick,
  href,
  newTab,
  showIconCircleBackground = true,
}: ContactMethodProps) {
  return (
    <a
      className={css.contactMethod}
      onClick={onClick}
      href={href}
      target={newTab ? "_blank" : undefined}
      rel="noreferrer"
    >
      <div className={showIconCircleBackground ? css.iconCircle : css.iconNoCircle}>{icon}</div>
      <div>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </a>
  );
}

export function ChatWithUsButton({
  triggeredFrom,
  showIconCircleBackground = true,
}: {
  triggeredFrom: string;
  showIconCircleBackground?: boolean;
}): JSX.Element {
  const { openSupportChat } = useSupportChat();
  return (
    <ContactMethodButton
      icon={<MessageOutlined className={css.icon} />}
      title="Chat with us"
      description={
        <span>
          Live chat is available
          <br />
          Mon-Fri, 9am-6pm GMT
        </span>
      }
      onClick={() => openSupportChat(triggeredFrom)}
      showIconCircleBackground={showIconCircleBackground}
    />
  );
}

export function EmailUsButton({
  showIconCircleBackground = true,
}: {
  showIconCircleBackground?: boolean;
}): JSX.Element {
  return (
    <ContactMethodButton
      icon={<MailOutlined className={css.icon} />}
      title="Email us"
      description="team@stotles.com"
      href="mailto:team@stotles.com"
      showIconCircleBackground={showIconCircleBackground}
    />
  );
}

export function BookCallButton({
  showIconCircleBackground = true,
}: {
  showIconCircleBackground?: boolean;
}): JSX.Element {
  return (
    <ContactMethodButton
      icon={<PhoneOutlined className={css.icon} />}
      title="Book a call"
      description="Find a time that suits you"
      href="https://meetings.hubspot.com/nonoka-sugawara/stotles-introduction"
      newTab
      showIconCircleBackground={showIconCircleBackground}
    />
  );
}

export function WatchVideoButton(): JSX.Element {
  return (
    <ContactMethodButton
      icon={<InfoOutlined className={css.icon} />}
      title="Watch a short video"
      description={
        <span>
          See what Stotles Pro
          <br />
          can do in just 4 minutes
        </span>
      }
      // This link does not need to be migrated as it refers to something we no longer use
      // In addition, this button will be gone sone when the new subscription page is released
      href="https://help.stotles.com/en/articles/5121095-what-is-stotles-pro"
      newTab
    />
  );
}
