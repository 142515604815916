import * as React from "react";
import classNames from "classnames";

import { FeatureType } from "components/paywall/paywallUtils";
import { assertCurrentUser } from "lib/currentUser";
import { EventData } from "lib/tracking";
import { ActionableBanner } from "./ActionableBanner";
import PaywallCTAModal from "./PaywallCTAModal";
import SubscriptionDowngradeModal from "./SubscriptionDowngradeModal";
import { SubscriptionPlans } from "./SubscriptionPlans";
import { SubscriptionPlansDetails } from "./SubscriptionPlansDetails";

import css from "./Subscription.module.scss";

type Props = {
  className?: string;
  triggerBookADemoForm?: (value: boolean) => void;
  trackingData?: EventData;
};

function Subscription({ className, triggerBookADemoForm, trackingData }: Props): JSX.Element {
  const [paywallCTAModalOpen, setPaywallCTAModalOpen] = React.useState(false);
  const [downgradeModalOpen, setDowngradeModalOpen] = React.useState(false);
  const currentPlanisFreemium = assertCurrentUser().subscription === "freemium";

  return (
    <div className={classNames(css.container, className)}>
      <div className={css.heading}>
        <h1>
          Plans to help you build public sector pipeline<span className={css.dot}>.</span>
        </h1>
        <p>From reactive tender tracking to proactive opportunity creation</p>
      </div>

      <SubscriptionPlans
        triggerBookADemoForm={triggerBookADemoForm ? triggerBookADemoForm : setPaywallCTAModalOpen}
        triggerDowngradeModal={setDowngradeModalOpen}
      />
      <SubscriptionPlansDetails
        triggerBookADemoForm={triggerBookADemoForm ? triggerBookADemoForm : setPaywallCTAModalOpen}
        triggerDowngradeModal={setDowngradeModalOpen}
      />
      {currentPlanisFreemium && (
        <ActionableBanner
          setBookADemoModalOpen={
            triggerBookADemoForm ? triggerBookADemoForm : setPaywallCTAModalOpen
          }
        />
      )}
      <PaywallCTAModal
        isOpen={paywallCTAModalOpen}
        onClose={() => setPaywallCTAModalOpen(false)}
        showBookADemoForm={true}
        featureType={trackingData?.["Data type"] as FeatureType}
      />
      <SubscriptionDowngradeModal
        isOpen={downgradeModalOpen}
        onClose={() => setDowngradeModalOpen(false)}
      />
    </div>
  );
}

export default Subscription;
