import React from "react";

import { ProviderTypes } from "lib/generated/integration-api";

export type ProviderMetadata = {
  name: string;
  description: string;
  learnMoreModal: {
    tagline: string;
    adminMessage: JSX.Element;
    contentBlocks: {
      subtitle: string;
      text: string;
    }[];
    setupLink: string;
  };
  createNoticeModal: {
    [entityName: string]: { title: string; subtitle: string };
  };
};

export const PROVIDER_METADATA: Record<ProviderTypes, ProviderMetadata> = {
  HUBSPOT: {
    name: "HubSpot",
    description:
      "Connect to your Hubspot account to send relevant notices direct to your workflow.",
    learnMoreModal: {
      tagline: "Instantly create objects in your CRM",
      adminMessage: (
        <>
          <p>
            In order to setup this integration, you will need to be <b>Super Admin on HubSpot</b>.
            See how you can view HubSpot Super Admin permissions in our guide{" "}
            <a
              target="_blank"
              href="https://www.notion.so/HubSpot-Administration-Guide-d58c0cc3ab1947d39c1ae821a873be44"
              rel="noreferrer"
            >
              here
            </a>
            .{" "}
          </p>
          <p>
            Add your CRM admins to your Stoles account{" "}
            <a
              target="_blank"
              href="https://app.stotles.com/account-management/my-team"
              rel="noreferrer"
            >
              here
            </a>{" "}
            if needed.
          </p>
        </>
      ),

      setupLink: "https://help.stotles.com/set-up-hubspot",
      contentBlocks: [
        {
          subtitle: "Shortcut your workflow",
          text: "Cut out the unnecessary processes that go between Stotles' insights and HubSpot. Instead, store relevant deals and contacts to your CRM in a few simple clicks.",
        },
        {
          subtitle: "Enhance team productivity",
          text: "Not only does this integration save time, but ensures your team members have immediate access to the information they need to better serve your customers.",
        },
        {
          subtitle: "Keep track of where your successes come from",
          text: "Showing ROI is always crucial for the tools you love. By seamlessly linking back to the source of an deal, you can show exactly where your top leads are coming from without any extra input.",
        },
      ],
    },
    createNoticeModal: {
      Deal: {
        title: "Create a deal in HubSpot",
        subtitle: "Add this notice to your deals in HubSpot.",
      },
    },
  },
  SALESFORCE: {
    name: "Salesforce",
    description:
      "Connect to your Salesforce account to send relevant notices direct to your workflow.",
    learnMoreModal: {
      tagline: "Instantly create objects in your CRM",
      adminMessage: (
        <>
          <p>
            In order to setup this integration, you will need to be{" "}
            <b>System Administrator on Salesforce</b>. See how you can view Salesforce System
            Administrator permissions in our guide{" "}
            <a
              target="_blank"
              href="https://stotles.notion.site/Salesforce-Administration-Guide-9405b2d3017c458d9109f98985288db9"
              rel="noreferrer"
            >
              here.
            </a>{" "}
          </p>
          <p>
            Add your CRM admins to your Stoles account{" "}
            <a
              target="_blank"
              href="https://app.stotles.com/account-management/my-team"
              rel="noreferrer"
            >
              here
            </a>{" "}
            if needed.
          </p>
        </>
      ),
      setupLink: "https://help.stotles.com/setting-up-salesforce",
      contentBlocks: [
        {
          subtitle: "Shortcut your workflow",
          text: "Cut out the unnecessary processes that go between Stotles' insights and Salesforce. Instead, store relevant opportunities and contacts to your CRM in a few simple clicks.",
        },
        {
          subtitle: "Enhance team productivity",
          text: "Not only does this integration save time, but ensures your team members have immediate access to the information they need to better serve your customers.",
        },
        {
          subtitle: "Keep track of where your successes come from",
          text: "Showing ROI is always crucial for the tools you love. By seamlessly linking back to the source of an deal, you can show exactly where your top leads are coming from without any extra input.",
        },
      ],
    },
    createNoticeModal: {
      Lead: {
        title: "Create a lead in Salesforce",
        subtitle: "Add this notice to your leads in Salesforce.",
      },
      Opportunity: {
        title: "Create an opportunity in Salesforce",
        subtitle: "Add this notice to your opportunities in Salesforce.",
      },
    },
  },
};
