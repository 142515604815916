import React, { useState } from "react";
import styled from "@emotion/styled";
import { Button, Drawer, Segmented } from "antd5";

import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { sysTextDefault } from "../../../lib/themes/colors";
import { Flex } from "../../../styles/utility-components";
import { SaveToLists } from "./lists/SaveToLists";
import { SaveToOpportunity } from "./opportunity/SaveToOpportunity";
import { EntityType, SharedSaveProps } from "./types";

type Props = {
  isOpen: boolean;
  onClose: () => void;
} & SharedSaveProps;

const hasList: Record<EntityType, boolean> = {
  buyer: true,
  supplier: true,
  notice: true,
  framework: false,
  document: false,
  contact: false,
};

export function SaveToDrawer({
  isOpen,
  onClose,
  entityType,
  entityId,
  defaultOpportunityValues,
}: Props) {
  const [selectedTab, setSelectedTab] = useState<"opportunity" | "list">(
    entityType === "supplier" ? "list" : "opportunity",
  );
  return (
    <Drawer
      placement="right"
      closable={true}
      open={isOpen}
      width={486}
      onClose={onClose}
      styles={{ header: { display: "none" }, body: { padding: "16px 24px" } }}
    >
      <Flex column height={"100%"} flexGrow={1} gap={8}>
        <Flex justifyContent="flex-end">
          <Button
            icon={<UIcon icon="xClose" color={sysTextDefault} size={16} />}
            type="text"
            onClick={onClose}
          />
        </Flex>
        {hasList[entityType] ? (
          <Flex column height={"calc(100% - 32px)"} gap={24} flexGrow={1}>
            <Segmented
              options={[
                { value: "opportunity", label: "Opportunity" },
                { value: "list", label: "List" },
              ]}
              onChange={(value: "opportunity" | "list") => setSelectedTab(value)}
              value={selectedTab}
              block
            />
            <ContentContainer hasList={true}>
              {selectedTab === "opportunity" ? (
                <SaveToOpportunity
                  entityType={entityType}
                  entityId={entityId}
                  defaultOpportunityValues={defaultOpportunityValues}
                />
              ) : (
                <SaveToLists entityType={entityType} entityId={entityId} />
              )}
            </ContentContainer>
          </Flex>
        ) : (
          <ContentContainer hasList={false}>
            <SaveToOpportunity
              entityType={entityType}
              entityId={entityId}
              defaultOpportunityValues={defaultOpportunityValues}
            />
          </ContentContainer>
        )}
      </Flex>
    </Drawer>
  );
}

const ContentContainer = styled.div<{ hasList: boolean }>((hasList) => ({
  height: hasList ? "calc(100% - 64px)" : "calc(100% - 32px)",
}));
