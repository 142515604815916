import React from "react";
import { DateTime } from "luxon";

import { DEFAULT_PREVIEW_LOOKBACK } from "components/account_management/feed_settings/FeedSettingsPreview";
import RecordCardList from "components/feed/RecordCardList";
import { RecordPreviewHeading } from "components/feed/RecordPreviewHeading";
import { FeedPreviewPageSources } from "components/onboarding/SharedOnboarding";
import { RecordPreview } from "lib/types/models";
import { PreviewGuidMatchMap, SelfServeQuery, Signal } from "../../lib/StotlesApi";

import css from "./FeedPreviewOutputs.module.scss";

type Props = {
  recordPreviews: RecordPreview[];
  totalResults: number;
  isLoading: boolean;
  guidMatchMap?: PreviewGuidMatchMap;
  pageSource: FeedPreviewPageSources;
  signalMap?: Map<string, Signal>;
  feedCriteria: SelfServeQuery;
  companyId?: number;
};

export function FeedPreviewOutputs({
  recordPreviews,
  totalResults,
  isLoading,
  guidMatchMap,
  pageSource,
  signalMap,
  feedCriteria,
  companyId,
}: Props) {
  const monthlyAverageResults =
    (totalResults / DateTime.now().diff(DEFAULT_PREVIEW_LOOKBACK, "days").days) * 30.5;

  return (
    <div className={css.feedPreviewOutputs}>
      <div className={css.header}>
        <RecordPreviewHeading
          totalResults={totalResults}
          displayedResults={recordPreviews.length}
          isLoading={isLoading}
          monthlyAverageResults={monthlyAverageResults}
          recordListCountLabel={"in last 6 months"}
        />
      </div>
      <div className={css.recordListContainer}>
        <RecordCardList
          records={recordPreviews}
          popoverPlacement="left"
          guidMatchMap={guidMatchMap}
          disableRecordNavigation={pageSource === FeedPreviewPageSources.ONBOARDING}
          showTenderStage={pageSource !== FeedPreviewPageSources.ONBOARDING}
          signalMap={signalMap}
          feedCriteria={feedCriteria}
          companyId={companyId}
        />
      </div>
    </div>
  );
}
