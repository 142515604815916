import { stringify } from "csv-stringify/sync";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function exportDataToCsv(filename: string, data: Array<any>, header: boolean = true): void {
  const output = stringify(data, { header });
  const blob = new Blob([output], { type: "text/csv" });
  const e = document.createEvent("MouseEvents"),
    a = document.createElement("a");
  a.download = filename;
  a.href = window.URL.createObjectURL(blob);
  a.dataset.downloadurl = ["text/csv", a.download, a.href].join(":");
  e.initEvent("click", true, false);
  a.dispatchEvent(e);
}
