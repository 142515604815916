import React from "react";

export const emptyStateIcons = {
  notifications: (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 110 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.7312 28.5364V25.7064C51.7312 23.9481 53.1568 22.5227 54.9149 22.5227C56.6731 22.5227 58.0987 23.9481 58.0987 25.7064V28.5364"
        fill="white"
      />
      <path
        d="M51.7312 28.5364V25.7064C51.7312 23.9481 53.1568 22.5227 54.9149 22.5227C56.6731 22.5227 58.0987 23.9481 58.0987 25.7064V28.5364"
        stroke="#2569F9"
        strokeWidth="1.76874"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.9149 28.1826C63.8747 28.1826 71.5411 34.5282 71.5411 44.1013L72.0739 54.4895C72.1892 56.7344 72.7892 58.9284 73.8334 60.92L76.5778 66.1533C77.0978 67.627 76.3245 69.2437 74.8508 69.7637C74.548 69.8705 74.2296 69.925 73.9091 69.925H35.9213C34.3584 69.925 33.0913 68.6579 33.0913 67.095C33.0913 66.7745 33.1459 66.4554 33.2526 66.1533L35.9966 60.92C37.0407 58.9284 37.6407 56.7344 37.756 54.4895L38.2887 44.1013C38.2887 34.5282 45.9552 28.1826 54.9149 28.1826Z"
        fill="white"
        stroke="#2569F9"
        strokeWidth="1.76874"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.9149 31.0127C62.8425 31.0127 68.4352 37.2675 68.4352 44.5691L68.8774 54.8292C68.9453 56.4097 69.2545 57.9712 69.7929 59.4584L72.579 65.1983C72.8451 65.9334 72.4651 66.7442 71.7308 67.0102C71.5758 67.0668 71.4131 67.0951 71.2489 67.0951H60.2728C59.6396 67.0951 59.0835 66.6748 58.9116 66.0657L56.915 59.0126C56.5634 57.7717 56.3681 56.4918 56.3328 55.2028L56.3264 54.7733V40.7522C56.3264 37.5111 55.5828 34.4436 54.2577 31.8723C54.1091 31.5833 54.2223 31.2283 54.5117 31.0793C54.5916 31.0378 54.6808 31.0155 54.7713 31.014C54.823 31.0131 54.8711 31.0127 54.9149 31.0127Z"
        fill="#CADBFE"
      />
      <path
        d="M41.2722 56.9341H53.8537M57.8737 56.9341H61.2293"
        stroke="#4F86FA"
        strokeWidth="1.76874"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.66 36.8099C30.66 29.6897 35.1997 24.4822 41.2722 22.6599"
        stroke="#4F86FA"
        strokeWidth="1.76874"
        strokeLinecap="round"
      />
      <path
        d="M25 36.8099C25 26.8416 31.961 19.5512 41.2722 17"
        stroke="#4F86FA"
        strokeWidth="1.76874"
        strokeLinecap="round"
      />
      <path
        d="M78.6161 36.8099C78.6161 29.6897 74.0761 24.4822 68.0036 22.6599"
        stroke="#4F86FA"
        strokeWidth="1.76874"
        strokeLinecap="round"
      />
      <path
        d="M84.276 36.8099C84.276 26.8416 77.315 19.5512 68.0036 17"
        stroke="#4F86FA"
        strokeWidth="1.76874"
        strokeLinecap="round"
      />
      <path
        d="M51.0237 72.7549C51.0237 75.0995 52.924 76.9999 55.2687 76.9999C57.6133 76.9999 59.5137 75.0995 59.5137 72.7549"
        stroke="#2569F9"
        strokeWidth="1.76874"
        strokeLinecap="round"
      />
    </svg>
  ),
  noResults: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 110 94"
      fill="none"
    >
      <path
        d="M34.7817 48.8762C34.7817 48.6986 34.9004 48.5426 35.0718 48.4952L54.5853 43.0944C54.6538 43.0753 54.7272 43.0753 54.7962 43.0944L74.3094 48.4952C74.4809 48.5426 74.5995 48.6986 74.5995 48.8762V71.2016C74.5995 71.3757 74.4856 71.5297 74.3189 71.5803L54.8057 77.5211C54.7308 77.5437 54.6507 77.5437 54.5753 77.5211L35.0621 71.5803C34.8956 71.5297 34.7817 71.3757 34.7817 71.2016V48.8762Z"
        fill="white"
        stroke="#2569F9"
        strokeWidth="1.31853"
        strokeLinejoin="round"
      />
      <path d="M73.4153 50.8203V70.5983L56.0107 75.8724L73.4153 50.8203Z" fill="#CADBFE" />
      <path
        d="M54.6905 43.4365L74.7311 48.7106L54.6905 53.9848L34.6499 48.7106L54.6905 43.4365Z"
        fill="#CADBFE"
      />
      <path
        d="M34.0115 48.3941C34.1427 48.2334 34.3561 48.1645 34.5566 48.2182L54.9809 53.6909C55.3501 53.7901 55.4946 54.2373 55.2526 54.5337L49.143 62.0193C48.6182 62.6622 47.7649 62.938 46.9627 62.7229L28.3148 57.7261C27.5754 57.5278 27.2866 56.6334 27.7706 56.0405L34.0115 48.3941Z"
        fill="white"
      />
      <mask
        id="mask0_2859_435"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="27"
        y="48"
        width="29"
        height="15"
      >
        <path
          d="M34.0115 48.3941C34.1427 48.2334 34.3561 48.1645 34.5566 48.2182L54.9809 53.6909C55.3501 53.7901 55.4946 54.2373 55.2526 54.5337L49.143 62.0193C48.6182 62.6622 47.7649 62.938 46.9627 62.7229L28.3148 57.7261C27.5754 57.5278 27.2866 56.6334 27.7706 56.0405L34.0115 48.3941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2859_435)">
        <path
          d="M34.2154 49.4919L54.6392 54.9649L55.3222 52.4174L34.8979 46.9448L34.2154 49.4919ZM54.2315 53.7001L48.1215 61.1857L50.1647 62.8528L56.2742 55.3678L54.2315 53.7001ZM47.304 61.4494L28.6561 56.4527L27.9735 59.0001L46.6215 63.9968L47.304 61.4494ZM28.7921 56.8741L35.033 49.2282L32.9901 47.5606L26.7491 55.2069L28.7921 56.8741ZM28.6561 56.4527C28.8409 56.5023 28.9131 56.7259 28.7921 56.8741L26.7491 55.2069C25.6602 56.5408 26.3101 58.5544 27.9735 59.0001L28.6561 56.4527ZM48.1215 61.1857C47.9247 61.4267 47.6046 61.5301 47.304 61.4494L46.6215 63.9968C47.9247 64.3459 49.3113 63.8982 50.1647 62.8528L48.1215 61.1857ZM54.6392 54.9649C54.0849 54.8161 53.8682 54.1447 54.2315 53.7001L56.2742 55.3678C57.1212 54.3298 56.616 52.764 55.3222 52.4174L54.6392 54.9649ZM34.8979 46.9448C34.1962 46.7568 33.4494 46.9978 32.9901 47.5606L35.033 49.2282C34.8362 49.4692 34.5161 49.5726 34.2154 49.4919L34.8979 46.9448Z"
          fill="#2569F9"
        />
      </g>
      <path
        d="M49.0259 38.3274L54.6555 43.3226L35.2936 48.5109L29.6298 43.4853C29.3941 43.2762 29.4856 42.8889 29.79 42.8074L47.6883 38.0114C48.1588 37.8852 48.6614 38.0039 49.0259 38.3274Z"
        fill="#CADBFE"
        stroke="#2569F9"
        strokeWidth="1.31853"
      />
      <path
        d="M60.2428 38.3022L54.5947 43.2947L74.0409 48.5056L79.7212 43.485C79.9575 43.2761 79.8662 42.8882 79.5614 42.8066L61.5787 37.9879C61.1088 37.862 60.6072 37.98 60.2428 38.3022Z"
        fill="#CADBFE"
        stroke="#2569F9"
        strokeWidth="1.31853"
      />
      <path
        d="M75.1999 48.468C75.0686 48.3074 74.8555 48.2387 74.6551 48.2924L54.4742 53.7002C54.1045 53.7994 53.96 54.2472 54.2026 54.5436L60.2198 61.9025C60.7451 62.5444 61.598 62.8192 62.3991 62.6045L80.8042 57.6727C81.5436 57.4749 81.8327 56.5788 81.3474 55.986L75.1999 48.468Z"
        fill="white"
      />
      <mask
        id="mask1_2859_435"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="54"
        y="48"
        width="28"
        height="15"
      >
        <path
          d="M75.1999 48.468C75.0686 48.3074 74.8555 48.2387 74.6551 48.2924L54.4742 53.7002C54.1045 53.7994 53.96 54.2472 54.2026 54.5436L60.2198 61.9025C60.7451 62.5444 61.598 62.8192 62.3991 62.6045L80.8042 57.6727C81.5436 57.4749 81.8327 56.5788 81.3474 55.986L75.1999 48.468Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_2859_435)">
        <path
          d="M74.9963 49.5651L54.8154 54.9732L54.1329 52.4258L74.3138 47.0181L74.9963 49.5651ZM55.223 53.7079L61.2408 61.0669L59.1992 62.7362L53.182 55.3772L55.223 53.7079ZM62.0578 61.3306L80.4629 56.3988L81.1454 58.9456L62.7402 63.8775L62.0578 61.3306ZM80.3268 56.8202L74.1793 49.3019L76.2209 47.6325L82.3684 55.1509L80.3268 56.8202ZM80.4629 56.3988C80.2778 56.4478 80.2055 56.672 80.3268 56.8202L82.3684 55.1509C83.4591 56.4847 82.8093 58.5 81.1454 58.9456L80.4629 56.3988ZM61.2408 61.0669C61.4375 61.3079 61.7572 61.4108 62.0578 61.3306L62.7402 63.8775C61.4381 64.2266 60.0526 63.7799 59.1992 62.7362L61.2408 61.0669ZM54.8154 54.9732C55.3702 54.8244 55.587 54.1525 55.223 53.7079L53.182 55.3772C52.3334 54.3397 52.8386 52.7728 54.1329 52.4258L54.8154 54.9732ZM74.3138 47.0181C75.0152 46.8302 75.761 47.0706 76.2209 47.6325L74.1793 49.3019C74.376 49.5429 74.6962 49.6458 74.9963 49.5651L74.3138 47.0181Z"
          fill="#2569F9"
        />
      </g>
      <path
        d="M54.6909 77.1899V62.8986M54.6909 58.7305V60.2046"
        stroke="#2569F9"
        strokeWidth="1.31853"
        strokeLinecap="round"
      />
      <path
        d="M54.6909 43.9629V48.7096"
        stroke="#2569F9"
        strokeWidth="1.31853"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.1159 65.5166L68.6582 66.187C68.3069 66.2824 68.0996 66.6448 68.1956 66.9965C68.2916 67.3478 68.6539 67.5545 69.0052 67.4591L71.4624 66.7887C71.8137 66.6928 72.0209 66.3304 71.925 65.9792C71.8295 65.6279 71.4672 65.4206 71.1159 65.5166ZM71.5014 69.5814C71.8527 69.4854 72.06 69.1231 71.964 68.7718C71.8685 68.4206 71.5062 68.2133 71.1549 68.3093L66.1313 69.679C65.7801 69.775 65.5733 70.1373 65.6688 70.4885C65.7648 70.8398 66.1271 71.0471 66.4784 70.9511L71.5014 69.5814Z"
        fill="#4F86FA"
      />
      <path
        d="M56.8 19.7026C57.6739 19.7026 58.3822 18.9942 58.3822 18.1203C58.3822 17.2465 57.6739 16.5381 56.8 16.5381C55.9261 16.5381 55.2178 17.2465 55.2178 18.1203C55.2178 18.9942 55.9261 19.7026 56.8 19.7026Z"
        stroke="#4F86FA"
        strokeWidth="1.05482"
      />
      <path
        d="M72.0944 33.9428C72.9684 33.9428 73.6767 33.2344 73.6767 32.3606C73.6767 31.4867 72.9684 30.7783 72.0944 30.7783C71.2205 30.7783 70.5122 31.4867 70.5122 32.3606C70.5122 33.2344 71.2205 33.9428 72.0944 33.9428Z"
        fill="#4F86FA"
      />
      <path
        d="M59.9644 25.5039L64.3725 29.9121M64.4394 25.5039L60.0313 29.9121"
        stroke="#4F86FA"
        strokeWidth="1.31853"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.0332 30.5654L45.1977 33.7299M45.1977 30.5654L42.0332 33.7299"
        stroke="#4F86FA"
        strokeWidth="1.31853"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.2033 27.0863C50.0773 27.0863 50.7856 26.378 50.7856 25.5041C50.7856 24.6302 50.0773 23.9219 49.2033 23.9219C48.3294 23.9219 47.6211 24.6302 47.6211 25.5041C47.6211 26.378 48.3294 27.0863 49.2033 27.0863Z"
        fill="#4F86FA"
      />
    </svg>
  ),
  noDocuments: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 110 94"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.3826 24.7256L75.0192 65.8349L75.5251 69.9589C75.6887 71.2878 74.7435 72.4966 73.4152 72.6602L37.9304 77.017C36.602 77.18 35.3927 76.2353 35.2295 74.9065L29.7665 30.4135C29.6849 29.7491 30.1573 29.1445 30.8216 29.0629C30.8258 29.0624 30.8301 29.0619 30.8342 29.0614L33.7782 28.7312"
        fill="white"
      />
      <path
        d="M70.133 24.6234C70.076 24.209 69.6943 23.919 69.2798 23.9759C68.8654 24.0327 68.5757 24.4148 68.6321 24.8292L70.133 24.6234ZM75.0194 65.8355L75.7707 65.7428C75.7707 65.7398 75.7701 65.7362 75.7695 65.7325L75.0194 65.8355ZM33.8628 29.4846C34.2785 29.438 34.5777 29.0632 34.5311 28.6475C34.4844 28.2317 34.1096 27.9325 33.6939 27.9792L33.8628 29.4846ZM36.0744 27.7121C35.6587 27.7588 35.3595 28.1336 35.4061 28.5493C35.4527 28.965 35.8276 29.2642 36.2432 29.2176L36.0744 27.7121ZM39.0225 28.9058C39.4388 28.8591 39.7375 28.4844 39.6908 28.0686C39.6442 27.6529 39.2697 27.3537 38.854 27.4004L39.0225 28.9058ZM68.6321 24.8292L74.2686 65.9385L75.7695 65.7325L70.133 24.6234L68.6321 24.8292ZM74.2674 65.9276L74.774 70.0517L76.2773 69.8675L75.7707 65.7428L74.2674 65.9276ZM74.774 70.0517C74.8861 70.9655 74.2365 71.7968 73.3227 71.9089L73.5075 73.4123C75.2514 73.1984 76.4912 71.6114 76.2773 69.8675L74.774 70.0517ZM73.3227 71.9089L37.8384 76.2657L38.0233 77.7697L73.5075 73.4123L73.3227 71.9089ZM37.8384 76.2657C36.9253 76.3778 36.0937 75.7282 35.9815 74.8151L34.4779 74.9999C34.6921 76.7438 36.2793 77.9836 38.0233 77.7697L37.8384 76.2657ZM35.9815 74.8151L30.5184 30.3218L29.0149 30.5065L34.4779 74.9999L35.9815 74.8151ZM30.5184 30.3218C30.4879 30.0727 30.665 29.846 30.9141 29.8154L30.7295 28.3118C29.65 28.4444 28.8823 29.427 29.0149 30.5065L30.5184 30.3218ZM30.9141 29.8154C30.9157 29.8152 30.9173 29.815 30.9188 29.8148L30.75 28.3094C30.7432 28.3101 30.7363 28.311 30.7295 28.3118L30.9141 29.8154ZM30.9188 29.8148L33.8628 29.4846L33.6939 27.9792L30.75 28.3094L30.9188 29.8148ZM36.2432 29.2176L39.0225 28.9058L38.854 27.4004L36.0744 27.7121L36.2432 29.2176ZM38.854 27.4004L36.0744 27.7121L36.2432 29.2176L39.0225 28.9058L38.854 27.4004Z"
        fill="#2569F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.8482 27.3125L72.9503 64.5684L73.409 68.3059C73.5569 69.5099 72.7122 70.6049 71.5221 70.7509L39.745 74.6526C38.5556 74.7986 37.4709 73.9406 37.3231 72.7366L32.3846 32.5161C32.3031 31.8518 32.7755 31.2472 33.4398 31.1656L37.3709 30.6829"
        fill="#CADBFE"
      />
      <path
        d="M41.6606 18.6664C41.6606 17.746 42.4066 17 43.327 17H70.9321C71.3739 17 71.7974 17.1754 72.1101 17.4877L80.2558 25.6286C80.5685 25.9411 80.7442 26.3651 80.7442 26.8072V64.7183C80.7442 65.6388 79.9983 66.3847 79.0779 66.3847H43.327C42.4066 66.3847 41.6606 65.6388 41.6606 64.7183V18.6664Z"
        fill="white"
        stroke="#2569F9"
        strokeWidth="1.51487"
      />
      <path
        d="M71.2007 17.6982V24.7255C71.2007 25.7295 72.0145 26.5434 73.0185 26.5434H77.8255"
        stroke="#2569F9"
        strokeWidth="1.51487"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.7676 57.4463H63.5222M47.7676 26.543H63.5222M47.7676 33.8143H73.8233M47.7676 41.6916H73.8233M47.7676 49.569H73.8233"
        stroke="#4F86FA"
        strokeWidth="1.51487"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  startingState: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 110 94"
      fill="none"
    >
      <path
        d="M54.8476 61.1522C65.3833 61.1522 73.9237 52.6118 73.9237 42.0761C73.9237 31.5407 65.3833 23 54.8476 23C44.3119 23 35.7715 31.5407 35.7715 42.0761C35.7715 52.6118 44.3119 61.1522 54.8476 61.1522Z"
        fill="#F3F7FF"
        stroke="#2569F9"
        strokeWidth="1.42359"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.7911 56.8783C52.7928 57.0634 53.8115 57.1591 54.8479 57.1665C63.1822 57.1665 69.9379 50.4107 69.9379 42.0764C69.9379 33.7424 63.1822 26.9863 54.8479 26.9863C52.7068 26.9863 50.6705 27.4321 48.8255 28.236C45.6196 29.633 42.9931 32.1112 41.4069 35.2101C40.3526 37.2697 39.7578 39.6036 39.7578 42.0764C39.7578 44.32 40.2475 46.4491 41.1257 48.363C41.7527 49.7296 42.5777 50.9858 43.5639 52.0962"
        fill="white"
      />
      <path
        d="M51.7911 56.8783C52.7928 57.0634 53.8115 57.1591 54.8479 57.1665C63.1822 57.1665 69.9379 50.4107 69.9379 42.0764C69.9379 33.7424 63.1822 26.9863 54.8479 26.9863C52.7068 26.9863 50.6705 27.4321 48.8255 28.236C45.6196 29.633 42.9931 32.1112 41.4069 35.2101C40.3526 37.2697 39.7578 39.6036 39.7578 42.0764C39.7578 44.32 40.2475 46.4491 41.1257 48.363C41.7527 49.7296 42.5777 50.9858 43.5639 52.0962"
        stroke="#2569F9"
        strokeWidth="1.42359"
        strokeLinecap="round"
      />
      <path
        d="M45.3364 53.792C46.5596 54.7862 47.9416 55.5926 49.4386 56.1677"
        stroke="#2569F9"
        strokeWidth="1.42359"
        strokeLinecap="round"
      />
      <path d="M70.5073 57.166L73.9239 60.5826" stroke="#2569F9" strokeWidth="1.42359" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.3715 60.0307C72.2941 61.1086 72.2941 62.8562 73.3715 63.9336L79.6843 70.2464C80.7617 71.3238 82.5093 71.3238 83.5872 70.2464C84.6651 69.1684 84.6651 67.4208 83.5872 66.3435L77.275 60.0307C76.197 58.9533 74.4495 58.9533 73.3715 60.0307Z"
        fill="#CADBFE"
        stroke="#2569F9"
        strokeWidth="1.42359"
      />
      <path
        d="M76.2017 61.1523L82.4655 67.4161"
        stroke="white"
        strokeWidth="1.42359"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.1463 35.5275C51.1463 42.1318 56.5001 47.4857 63.1044 47.4857C64.4016 47.4857 65.651 47.279 66.8206 46.8969C64.9113 51.6352 60.2704 54.98 54.8476 54.98C47.7211 54.98 41.9438 49.2025 41.9438 42.076C41.9438 35.6184 46.6876 30.2687 52.8802 29.3213C51.7801 31.1311 51.1463 33.2553 51.1463 35.5275Z"
        fill="#CADBFE"
      />
      <path
        d="M55.1323 31.542C54.4074 31.542 53.6984 31.6133 53.0134 31.7494M50.9441 32.3825C47.0486 34.0195 44.313 37.8709 44.313 42.3613"
        stroke="#4F86FA"
        strokeWidth="1.42359"
        strokeLinecap="round"
      />
      <path
        d="M85.4129 42.2343H80.7572M89.014 37.8057H79.2334M93.0001 37.8057H91.7354"
        stroke="#4F86FA"
        strokeWidth="1.42359"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.1633 54.7616H29.5077M31.5007 49.7637H21.7202M18.4037 49.7637H16"
        stroke="#4F86FA"
        strokeWidth="1.42359"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  loadError: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 110 94"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8758 72.6557H22.6273C21.0395 72.6557 19.7524 71.3685 19.7524 69.7809C19.7524 69.2397 19.9052 68.71 20.193 68.2515L48.4064 23.3458C49.2509 22.0014 51.0254 21.5963 52.3701 22.441C52.7359 22.6707 53.045 22.9801 53.275 23.3458L81.488 68.2515C82.3332 69.5962 81.9278 71.3707 80.5831 72.2151C80.1253 72.5033 79.5949 72.6557 79.0537 72.6557H35.6856H25.8758Z"
        fill="white"
      />
      <path
        d="M32.6128 72.6557H28.5941M25.8758 72.6557H22.6273C21.0395 72.6557 19.7524 71.3685 19.7524 69.7809C19.7524 69.2397 19.9052 68.71 20.193 68.2515L48.4064 23.3458C49.2509 22.0014 51.0254 21.5963 52.3701 22.441C52.7359 22.6707 53.045 22.9801 53.275 23.3458L81.488 68.2515C82.3332 69.5962 81.9278 71.3707 80.5831 72.2151C80.1253 72.5033 79.5949 72.6557 79.0537 72.6557H35.6856H25.8758Z"
        stroke="#2569F9"
        strokeWidth="1.79678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.1673 60.2435L78.6108 69.0426C78.8192 69.3804 78.715 69.8231 78.3772 70.0323C78.2637 70.102 78.1329 70.1394 77.9992 70.1394H23.6831C23.2862 70.1394 22.9644 69.8181 22.9644 69.4207C22.9644 69.287 23.0016 69.1562 23.0719 69.0426L39.1378 43.0664C47.1054 53.4143 59.376 60.094 73.1673 60.2435Z"
        fill="#CADBFE"
      />
      <path
        d="M53.1765 54.4131V38.6014C53.1765 37.3114 52.1308 36.2656 50.8407 36.2656C49.5506 36.2656 48.5049 37.3114 48.5049 38.6014V54.4131C48.5049 55.7032 49.5506 56.7489 50.8407 56.7489C52.1308 56.7489 53.1765 55.7032 53.1765 54.4131Z"
        fill="#CADBFE"
        stroke="#2569F9"
        strokeWidth="1.79678"
        strokeLinecap="round"
      />
      <path
        d="M50.8407 65.0144C52.1308 65.0144 53.1765 63.9687 53.1765 62.6786C53.1765 61.3885 52.1308 60.3428 50.8407 60.3428C49.5506 60.3428 48.5049 61.3885 48.5049 62.6786C48.5049 63.9687 49.5506 65.0144 50.8407 65.0144Z"
        fill="#CADBFE"
        stroke="#2569F9"
        strokeWidth="1.79678"
        strokeLinecap="round"
      />
      <path
        d="M73.0145 31.5252C74.2047 31.5252 75.1707 30.5599 75.1707 29.369C75.1707 28.1783 74.2047 27.2129 73.0145 27.2129C71.8236 27.2129 70.8584 28.1783 70.8584 29.369C70.8584 30.5599 71.8236 31.5252 73.0145 31.5252Z"
        stroke="#4F86FA"
        strokeWidth="1.43742"
      />
      <path
        d="M90.8436 39.6248C92.0345 39.6248 92.9998 38.6594 92.9998 37.4686C92.9998 36.2778 92.0345 35.3125 90.8436 35.3125C89.6534 35.3125 88.6875 36.2778 88.6875 37.4686C88.6875 38.6594 89.6534 39.6248 90.8436 39.6248Z"
        fill="#4F86FA"
      />
      <path
        d="M75.4243 40.9746L81.4313 46.9817M81.5226 40.9746L75.5156 46.9817"
        stroke="#4F86FA"
        strokeWidth="1.79678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 45.5127L21.3122 49.825M21.3122 45.5127L17 49.825"
        stroke="#4F86FA"
        strokeWidth="1.79678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.7723 40.7722C27.9632 40.7722 28.9285 39.8069 28.9285 38.6161C28.9285 37.4253 27.9632 36.46 26.7723 36.46C25.5816 36.46 24.6162 37.4253 24.6162 38.6161C24.6162 39.8069 25.5816 40.7722 26.7723 40.7722Z"
        fill="#4F86FA"
      />
    </svg>
  ),
  userRequiredContent: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 110 94"
      fill="none"
    >
      <path
        d="M79.9532 18H54.7196C51.9323 18 49.6729 20.2595 49.6729 23.0467V48.2804C49.6729 51.0676 51.9323 53.3271 54.7196 53.3271H79.9532C82.7405 53.3271 85 51.0676 85 48.2804V23.0467C85 20.2595 82.7405 18 79.9532 18Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.2336 38.5829V23.0465C50.2336 20.569 52.2422 18.5605 54.7196 18.5605H79.9533C82.4307 18.5605 84.4393 20.569 84.4393 23.0465V47.9998C84.4393 50.4772 82.4307 52.4858 79.9533 52.4858H72.1538"
        fill="white"
      />
      <path
        d="M50.2336 38.5829V23.0465C50.2336 20.569 52.2422 18.5605 54.7196 18.5605H79.9533C82.4307 18.5605 84.4393 20.569 84.4393 23.0465V47.9998C84.4393 50.4772 82.4307 52.4858 79.9533 52.4858H72.1538"
        stroke="#4F86FA"
        strokeWidth="1.40187"
        strokeLinecap="round"
        strokeDasharray="1.12 4.49"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.2467 65.103H29.486C27.0084 65.103 25 63.0944 25 60.617V35.6637C25 33.1862 27.0084 31.1777 29.486 31.1777H54.7196C57.197 31.1777 59.2056 33.1862 59.2056 35.6637V43.5713"
        fill="white"
      />
      <path
        d="M36.2467 65.103H29.486C27.0084 65.103 25 63.0944 25 60.617V35.6637C25 33.1862 27.0084 31.1777 29.486 31.1777H54.7196C57.197 31.1777 59.2056 33.1862 59.2056 35.6637V43.5713M42.5043 65.103H38.7664"
        stroke="#2569F9"
        strokeWidth="1.40187"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9052 59.059L49.3594 59.3147C47.6951 58.8947 46.4542 58.8767 45.6377 59.262C44.3368 59.8749 43.3224 61.095 43.623 62.9999H30.159C28.6105 62.9999 27.3552 61.7444 27.3552 60.1962V36.084C27.3552 34.5356 28.6105 33.2803 30.159 33.2803L30.53 33.2807C32.739 44.3652 39.5921 53.7072 48.9052 59.059Z"
        fill="#CADBFE"
      />
      <path
        d="M60.9455 72.7392C61.9016 72.9063 62.7606 73.1945 63.5238 73.6027L64.0543 74.2364C63.7481 74.814 63.7958 75.5435 64.2332 76.0841L65.4685 77.6093C66.0528 78.3315 67.112 78.4431 67.8343 77.8583L77.3956 70.1155C78.1178 69.5312 78.2289 68.4713 77.6446 67.7497L76.4092 66.2244C75.9046 65.6009 75.0461 65.4327 74.3563 65.7747L74.0311 65.2319C74.0591 65.2678 74.0777 65.2919 74.0855 65.3037C73.8068 64.5736 73.5612 63.7717 73.3498 62.8969C72.253 58.6106 71.4545 56.971 69.6528 53.8308C68.4517 51.737 66.9236 49.6857 65.0692 47.6763C64.6352 47.0327 63.0747 46.572 62.0805 47.2427C61.0863 47.9133 61.0347 49.4614 61.4687 50.1046C61.5343 50.2022 61.7132 50.431 61.9818 50.7646L61.9033 50.7551L60.4588 48.6119C60.0248 47.9686 58.4255 47.5239 57.4313 48.1945C56.4371 48.8654 56.4242 50.3973 56.8582 51.0405L57.7375 52.3442L57.7363 52.3437L56.0451 49.8366C55.6033 49.1816 54.0668 48.8424 53.0726 49.513C52.0784 50.1831 52.0027 51.6102 52.4446 52.2652L52.679 52.6128L48.6887 47.136C47.9418 46.0286 46.5298 45.6746 45.5356 46.3452C44.5414 47.0158 44.3412 48.4571 45.0881 49.5646C47.7169 53.2807 49.6812 56.0878 50.9816 57.9848C52.2814 59.8824 53.7803 61.9263 55.4777 64.1177C51.207 62.2039 48.6808 61.1912 47.8997 61.0807C46.7277 60.9147 45.0534 61.4816 45.6663 63.4857C46.2792 65.4904 54.101 70.9263 60.9455 72.7392Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.2203 61.0687L47.2214 61.1192C47.232 61.3261 47.2724 61.5538 47.3492 61.8044C47.9621 63.8091 54.8262 67.4702 61.6713 69.2837C62.5477 69.4368 63.981 70.1882 65.9711 71.5373L63.5246 73.6037C62.7614 73.1954 61.9023 72.9072 60.9462 72.7401C54.1018 70.9272 46.2799 65.4913 45.667 63.4867C45.1668 61.851 46.1902 61.1725 47.2203 61.0687ZM46.4061 46.0225L46.4027 46.0326C46.1543 46.9881 46.221 48.1583 46.6803 48.8399C49.3898 52.3356 51.3945 55.0328 52.6943 56.9298C53.6739 58.3592 55.387 59.9074 56.2461 62.1549C56.4333 62.6455 56.4333 63.3543 56.2461 64.2818C55.5933 63.7216 54.9866 63.2091 54.4259 62.7454C53.1546 61.0682 52.0068 59.4812 50.9818 57.9857C49.6819 56.0887 47.7176 53.2816 45.0889 49.5655C44.3419 48.4581 44.5421 47.0168 45.5363 46.3462C45.8032 46.1662 46.0999 46.0601 46.4061 46.0225Z"
        fill="#CADBFE"
      />
      <path
        d="M63.5247 73.6036C62.7615 73.1954 61.9024 72.9077 60.9464 72.7401C54.1019 70.9272 46.28 65.4913 45.6671 63.4866C45.0542 61.4825 46.7286 60.9156 47.9006 61.0816C48.6817 61.1926 51.2073 62.2047 55.4779 64.1186C53.7806 61.9277 52.2822 59.8832 50.9819 57.9862C49.6821 56.0887 47.7172 53.2816 45.0884 49.5655C44.3415 48.458 44.5422 47.0168 45.5364 46.3462C46.5307 45.6756 47.9421 46.0296 48.689 47.137L56.1385 57.7423"
        stroke="#2569F9"
        strokeWidth="1.40187"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.1383 57.742C55.3173 56.5252 54.0859 54.6994 52.4446 52.2657C52.0028 51.6108 52.0785 50.1837 53.0727 49.5136C54.0669 48.8429 55.6033 49.1822 56.0452 49.8371C57.548 52.0656 58.6757 53.7371 59.4276 54.8519"
        stroke="#2569F9"
        strokeWidth="1.40187"
        strokeLinecap="round"
      />
      <path
        d="M59.1019 54.3667C58.6915 53.7588 57.944 52.6502 56.8584 51.0409C56.4244 50.3977 56.4373 48.8657 57.4315 48.1949C58.4257 47.5242 60.0249 47.969 60.4589 48.6128L61.9034 50.7554"
        stroke="#2569F9"
        strokeWidth="1.40187"
        strokeLinecap="round"
      />
      <path
        d="M62.8449 52.1451C62.5752 51.7452 62.1165 51.0656 61.4688 50.1056C61.0348 49.4619 61.0864 47.9138 62.0806 47.2432C63.0748 46.5725 64.6354 47.0332 65.0694 47.6768C66.9238 49.6862 68.4518 51.7374 69.6529 53.8312C71.4546 56.9714 72.2531 58.611 73.3499 62.8974C73.5613 63.7722 73.8069 64.574 74.0856 65.3041"
        stroke="#2569F9"
        strokeWidth="1.40187"
        strokeLinecap="round"
      />
      <path
        d="M74.0436 65.9763L64.4823 73.7191C63.76 74.304 63.649 75.3632 64.2338 76.0849L65.4686 77.6101C66.0535 78.3324 67.1127 78.444 67.835 77.8591L77.3963 70.1163C78.118 69.532 78.2295 68.4722 77.6447 67.7505L76.4094 66.2253C75.8251 65.503 74.7658 65.392 74.0436 65.9763Z"
        stroke="#2569F9"
        strokeWidth="1.40187"
      />
      <path
        d="M74.68 67.0935L65.3351 74.6608C65.0945 74.8554 65.0575 75.2087 65.2527 75.4492L66.3018 76.7457C66.497 76.9862 66.8502 77.0232 67.0908 76.8287L76.4351 69.2614C76.6762 69.0662 76.7132 68.7135 76.5181 68.473L75.4684 67.1765C75.2738 66.936 74.9205 66.8989 74.68 67.0935Z"
        fill="#CADBFE"
      />
      <path
        d="M67.3364 31.2412V39.8053"
        stroke="#4F86FA"
        strokeWidth="1.40187"
        strokeLinecap="round"
      />
      <path
        d="M63.0547 35.5234H71.6184"
        stroke="#4F86FA"
        strokeWidth="1.40187"
        strokeLinecap="round"
      />
    </svg>
  ),
  success: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 110 94"
      fill="none"
    >
      <path
        d="M76.8793 17V20.0725"
        stroke="#4F86FA"
        strokeWidth="1.28019"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.8793 24.1689V27.2414"
        stroke="#4F86FA"
        strokeWidth="1.28019"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.7584 22.1211H74.8309"
        stroke="#4F86FA"
        strokeWidth="1.28019"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.9275 22.1211H82"
        stroke="#4F86FA"
        strokeWidth="1.28019"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.3768 69.7441V71.7924"
        stroke="#4F86FA"
        strokeWidth="1.28019"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.3768 75.8887V77.937"
        stroke="#4F86FA"
        strokeWidth="1.28019"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.2802 73.8408H32.3285"
        stroke="#4F86FA"
        strokeWidth="1.28019"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.4252 73.8408H38.4735"
        stroke="#4F86FA"
        strokeWidth="1.28019"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.8424 61.1031C71.5547 60.2311 72.1948 59.3191 72.7626 58.3753C74.0121 56.2988 74.9129 54.0682 75.4628 51.7685C76.0195 49.4431 76.2181 47.0466 76.0568 44.6673C75.9165 42.5913 75.5023 40.5286 74.813 38.5378C74.1299 36.5628 73.1759 34.6584 71.9505 32.8821C71.2208 31.8235 70.3943 30.8105 69.4715 29.855C68.1166 28.4521 66.6305 27.2527 65.0513 26.2577C63.3548 25.1885 61.5507 24.3551 59.6867 23.7586C57.3527 23.0114 54.9255 22.6356 52.4972 22.6328C50.6081 22.6307 48.7186 22.8545 46.8725 23.3049C44.795 23.812 42.7723 24.6065 40.8684 25.6895C39.1942 26.6416 37.6116 27.8166 36.1629 29.2154C34.3424 30.9733 32.8677 32.9549 31.7402 35.0755C30.6364 37.1518 29.8654 39.3615 29.4284 41.6251C28.9782 43.9572 28.8825 46.3466 29.1428 48.7068C29.4802 51.7654 30.4155 54.7749 31.9518 57.5457C32.9348 59.3186 34.1639 60.9936 35.6398 62.5216C37.9827 64.9478 40.7179 66.7652 43.6475 67.9696C47.2105 69.4352 51.0608 69.9943 54.842 69.6415C58.0153 69.345 61.1405 68.4059 64.0061 66.82"
        fill="white"
      />
      <path
        d="M70.8424 61.1031C71.5547 60.2311 72.1948 59.3191 72.7626 58.3753C74.0121 56.2988 74.9129 54.0682 75.4628 51.7685C76.0195 49.4431 76.2181 47.0466 76.0568 44.6673C75.9165 42.5913 75.5023 40.5286 74.813 38.5378C74.1299 36.5628 73.1759 34.6584 71.9505 32.8821C71.2208 31.8235 70.3943 30.8105 69.4715 29.855C68.1166 28.4521 66.6305 27.2527 65.0513 26.2577C63.3548 25.1885 61.5507 24.3551 59.6867 23.7586C57.3527 23.0114 54.9255 22.6356 52.4972 22.6328C50.6081 22.6307 48.7186 22.8545 46.8725 23.3049C44.795 23.812 42.7723 24.6065 40.8684 25.6895C39.1942 26.6416 37.6116 27.8166 36.1629 29.2154C34.3424 30.9733 32.8677 32.9549 31.7402 35.0755C30.6364 37.1518 29.8654 39.3615 29.4284 41.6251C28.9782 43.9572 28.8825 46.3466 29.1428 48.7068C29.4802 51.7654 30.4155 54.7749 31.9518 57.5457C32.9348 59.3186 34.1639 60.9936 35.6398 62.5216C37.9827 64.9478 40.7179 66.7652 43.6475 67.9696C47.2105 69.4352 51.0608 69.9943 54.842 69.6415C58.0153 69.345 61.1405 68.4059 64.0061 66.82"
        stroke="#2569F9"
        strokeWidth="1.28019"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.7166 65.8407C67.2046 64.8831 68.5279 63.6884 69.3887 62.7236L65.7166 65.8407Z"
        fill="white"
      />
      <path
        d="M65.7166 65.8407C67.2046 64.8831 68.5279 63.6884 69.3887 62.7236"
        stroke="#2569F9"
        strokeWidth="1.28019"
        strokeLinecap="round"
      />
      <path
        d="M74.0628 47.529C73.9164 47.5316 73.7694 47.5331 73.6224 47.5331C60.6904 47.5331 50.1022 37.4509 49.2148 24.6816C38.9216 26.3052 31.0483 35.2574 31.0483 46.0573C31.0483 58.0081 40.6882 67.6961 52.5802 67.6961C63.9795 67.6961 73.3101 58.7937 74.0628 47.529Z"
        fill="#CADBFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.374 45.3555C43.7477 44.777 42.7763 44.8214 42.2038 45.4548C41.6318 46.0877 41.6754 47.0699 42.3022 47.6485L49.5322 54.325C50.4088 55.1346 51.7694 55.0726 52.5703 54.1862C52.6041 54.1483 52.6041 54.1483 52.6364 54.1099L62.9542 41.5654C63.4965 40.9059 63.4074 39.9267 62.755 39.3784C62.1026 38.8301 61.1338 38.9202 60.5915 39.5797L50.8912 51.3734L44.374 45.3555Z"
        fill="white"
        stroke="#2569F9"
        strokeWidth="1.28019"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.2963 26.9414C44.2977 27.316 43.3222 27.7713 42.3795 28.3074C40.9155 29.1403 39.5307 30.1684 38.2634 31.3923C37.7439 31.894 37.2565 32.4165 36.8015 32.9572M35.3933 34.84C35.0315 35.3869 34.6984 35.9475 34.3943 36.5196C34.1697 36.9421 33.9608 37.371 33.7677 37.8053"
        fill="white"
      />
      <path
        d="M45.521 27.5403C45.8518 27.4162 46.0198 27.0472 45.8953 26.7162C45.7714 26.3852 45.4022 26.2176 45.0714 26.3417L45.521 27.5403ZM36.3116 32.5447C36.084 32.8152 36.1187 33.219 36.3892 33.4466C36.6597 33.6742 37.0635 33.6395 37.2911 33.369L36.3116 32.5447ZM35.927 35.193C36.1221 34.8981 36.0413 34.501 35.7464 34.3059C35.4516 34.1108 35.0545 34.1916 34.8594 34.4864L35.927 35.193ZM33.1827 37.5449C33.0391 37.8679 33.1845 38.2462 33.5075 38.3898C33.8305 38.5334 34.2088 38.388 34.3525 38.065L33.1827 37.5449ZM45.0714 26.3417C44.0411 26.7281 43.0354 27.1977 42.0629 27.7506L42.6959 28.8634C43.6089 28.3442 44.5537 27.9032 45.521 27.5403L45.0714 26.3417ZM42.0629 27.7506C40.5523 28.6098 39.1248 29.67 37.8186 30.9314L38.7079 31.8524C39.9363 30.666 41.2779 29.6701 42.6959 28.8634L42.0629 27.7506ZM37.8186 30.9314C37.2832 31.4485 36.7809 31.9871 36.3116 32.5447L37.2911 33.369C37.732 32.8451 38.2043 32.3387 38.7079 31.8524L37.8186 30.9314ZM34.8594 34.4864C34.4861 35.0504 34.1427 35.6286 33.829 36.2187L34.9594 36.8197C35.2539 36.2655 35.5765 35.7226 35.927 35.193L34.8594 34.4864ZM33.829 36.2187C33.5973 36.6546 33.3818 37.097 33.1827 37.5449L34.3525 38.065C34.5395 37.6443 34.7418 37.2289 34.9594 36.8197L33.829 36.2187Z"
        fill="#4F86FA"
      />
    </svg>
  ),
  download: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 110 94"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.8189 51.6192C75.8189 56.4944 75.8189 63.808 75.8189 73.559C75.8189 74.907 74.726 75.9999 73.378 75.9999H29.4409C28.0929 75.9999 27 74.907 27 73.559V34.1988C27 32.8507 28.0929 31.7578 29.4409 31.7578H44.6969C46.0449 31.7578 47.1378 32.8507 47.1378 34.1988C47.1378 35.7154 48.3674 36.9448 49.8839 36.9448H73.378C74.726 36.9448 75.8189 38.0377 75.8189 39.3858C75.8189 40.2267 75.8189 40.9479 75.8189 41.5492"
        fill="white"
      />
      <path
        d="M75.8189 51.6192C75.8189 56.4944 75.8189 63.808 75.8189 73.559C75.8189 74.907 74.726 75.9999 73.378 75.9999H29.4409C28.0929 75.9999 27 74.907 27 73.559V34.1988C27 32.8507 28.0929 31.7578 29.4409 31.7578H44.6969C46.0449 31.7578 47.1378 32.8507 47.1378 34.1988C47.1378 35.7154 48.3674 36.9448 49.8839 36.9448H73.378C74.726 36.9448 75.8189 38.0377 75.8189 39.3858C75.8189 40.2267 75.8189 40.9479 75.8189 41.5492V51.6192Z"
        stroke="#2569F9"
        strokeWidth="1.52559"
        strokeLinecap="round"
      />
      <path
        d="M75.8189 51.6192C75.8189 56.4944 75.8189 63.808 75.8189 73.559C75.8189 74.907 74.726 75.9999 73.378 75.9999H29.4409C28.0929 75.9999 27 74.907 27 73.559V34.1988C27 32.8507 28.0929 31.7578 29.4409 31.7578H44.6969C46.0449 31.7578 47.1378 32.8507 47.1378 34.1988C47.1378 35.7154 48.3674 36.9448 49.8839 36.9448H73.378C74.726 36.9448 75.8189 38.0377 75.8189 39.3858C75.8189 40.2267 75.8189 40.9479 75.8189 41.5492"
        stroke="#2569F9"
        strokeWidth="1.52559"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.3569 36.4353C29.3569 35.2173 30.3442 34.23 31.5621 34.23H42.9039C44.122 34.23 45.1093 35.2173 45.1093 36.4353C45.1093 37.9568 46.3426 39.1902 47.8645 39.1902H71.2567C72.4748 39.1902 73.4621 40.1775 73.4621 41.3955V71.6588C73.4621 72.8769 72.4748 73.8642 71.2567 73.8642H31.5621C30.3442 73.8642 29.3569 72.8769 29.3569 71.6588V36.4353Z"
        fill="#CADBFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.4186 43.9626H81.1853C82.5333 43.9626 83.6263 45.0555 83.6263 46.4036C83.6263 46.616 83.5982 46.8273 83.5433 47.0327L76.3022 74.1883C76.0172 75.2568 75.0494 76.0001 73.9436 76.0001H29.3828C28.3717 76.0001 27.5521 75.1805 27.5521 74.1693C27.5521 74.0101 27.5729 73.8514 27.6139 73.6976L28.8621 69.0171L35.06 45.7747C35.345 44.7061 36.3127 43.9626 37.4186 43.9626Z"
        fill="white"
        stroke="#2569F9"
        strokeWidth="1.52559"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.7266 70.7276L74.4539 71.7473C74.1689 72.8158 73.2016 73.5591 72.0953 73.5591H32.3488C31.6748 73.5591 31.1284 73.0129 31.1284 72.3386C31.1284 72.2324 31.1422 72.1269 31.1696 72.0243L37.5186 48.2154C37.8035 47.1468 38.7712 46.4036 39.8773 46.4036L49.5983 46.4036C50.1207 59.8678 61.1556 70.6348 74.7266 70.7276Z"
        fill="#CADBFE"
      />
      <path
        d="M51.4094 21.6614C52.4206 21.6614 53.2402 20.8418 53.2402 19.8307C53.2402 18.8197 52.4206 18 51.4094 18C50.3983 18 49.5787 18.8197 49.5787 19.8307C49.5787 20.8418 50.3983 21.6614 51.4094 21.6614Z"
        stroke="#4F86FA"
        strokeWidth="1.22047"
      />
      <path
        d="M70.3268 31.4251C71.338 31.4251 72.1575 30.6055 72.1575 29.5944C72.1575 28.5833 71.338 27.7637 70.3268 27.7637C69.3156 27.7637 68.4961 28.5833 68.4961 29.5944C68.4961 30.6055 69.3156 31.4251 70.3268 31.4251Z"
        fill="#4F86FA"
      />
      <path
        d="M56.1431 26.3953L61.244 31.4957M61.3215 26.3953L56.2206 31.4957"
        stroke="#4F86FA"
        strokeWidth="1.52559"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};
