import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "antd5";
import classNames from "classnames";

// The next line is disabled because,
// we are dynamically generating classNames from props
// eslint-disable-next-line css-modules/no-unused-class
import css from "./Tag.module.scss";

export type TagVariant = "default" | "active" | "success" | "warning" | "error";

export type TagProps = {
  id?: string;
  label?: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: () => void;
  isContainerClickable?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  ariaLabel?: string;
  variant?: TagVariant;
};

export default function Tag({
  id,
  label,
  icon,
  onClick,
  isContainerClickable = false,
  isLoading,
  disabled = false,
  className,
  ariaLabel,
  variant = "default",
}: TagProps) {
  const [isTextTruncated, setIsTextTruncated] = useState(false);
  const labelRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const checkTruncation = () => {
      const element = labelRef.current;
      if (element) {
        // If the element is overflowing, set the state
        const isOverflowing = element.offsetWidth < element.scrollWidth;
        setIsTextTruncated(isOverflowing);
      }
    };

    checkTruncation();

    // Watch for size changes
    const resizeObserver = new ResizeObserver(() => {
      checkTruncation();
    });

    if (labelRef.current) {
      resizeObserver.observe(labelRef.current.parentElement as Element);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [label]);

  if (isLoading) {
    return <div className={css.loadingContainer} role="status" aria-live="polite" />;
  }

  const containerClassName = classNames(css.container, css[variant], className, {
    [css.clickableContainer]: onClick && disabled === false && isContainerClickable === true,
  });

  const iconClassName = classNames({
    [css.clickableIcon]: onClick && disabled === false && isContainerClickable === false,
  });

  const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick == null) return;
    if (disabled === true) return;
    if (isContainerClickable === false) return;

    e.stopPropagation();
    onClick();
  };

  const handleIconClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (onClick == null) return;
    if (disabled === true) return;
    if (isContainerClickable === true) return;

    e.stopPropagation();
    onClick();
  };

  const labelContent = (
    <span
      ref={labelRef}
      className={css.label}
      aria-label={`${ariaLabel || (typeof label === "string" ? label : "Tag")}-label`}
    >
      {label}
    </span>
  );

  return (
    <div
      id={id}
      className={containerClassName}
      onClick={handleContainerClick}
      role={onClick && isContainerClickable && disabled === false ? "button" : undefined}
      tabIndex={onClick && isContainerClickable && disabled === false ? 0 : undefined}
      aria-label={`${ariaLabel || (typeof label === "string" ? label : "Tag")}-container`}
    >
      {label && isTextTruncated ? (
        <Tooltip title={label} mouseEnterDelay={0.5} placement="top">
          {labelContent}
        </Tooltip>
      ) : (
        labelContent
      )}
      {icon && (
        <span
          className={iconClassName}
          onClick={handleIconClick}
          role={onClick && isContainerClickable && disabled === false ? "button" : undefined}
          tabIndex={onClick && isContainerClickable && disabled === false ? 0 : undefined}
          aria-label={`${ariaLabel || (typeof label === "string" ? label : "Tag")}-action`}
        >
          {icon}
        </span>
      )}
    </div>
  );
}
