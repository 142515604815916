/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListPendingUploads200ResponseResultsInner
 */
export interface ListPendingUploads200ResponseResultsInner {
    /**
     * Pending document ID
     * @type {string}
     * @memberof ListPendingUploads200ResponseResultsInner
     */
    uploadTaskId: string;
    /**
     * 
     * @type {string}
     * @memberof ListPendingUploads200ResponseResultsInner
     */
    uploadCreatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof ListPendingUploads200ResponseResultsInner
     */
    uploadCreatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ListPendingUploads200ResponseResultsInner
     */
    uploadUpdatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ListPendingUploads200ResponseResultsInner
     */
    uploadStatus: string;
    /**
     * Document publish date
     * @type {string}
     * @memberof ListPendingUploads200ResponseResultsInner
     */
    publishedAt: string;
    /**
     * Document description
     * @type {string}
     * @memberof ListPendingUploads200ResponseResultsInner
     */
    description?: string;
    /**
     * Document category
     * @type {string}
     * @memberof ListPendingUploads200ResponseResultsInner
     */
    category: string;
    /**
     * Document title
     * @type {string}
     * @memberof ListPendingUploads200ResponseResultsInner
     */
    title?: string;
    /**
     * Document file name
     * @type {string}
     * @memberof ListPendingUploads200ResponseResultsInner
     */
    fileName?: string;
    /**
     * Document organisations
     * @type {Array<string>}
     * @memberof ListPendingUploads200ResponseResultsInner
     */
    organisations: Array<string>;
    /**
     * Document source URL
     * @type {string}
     * @memberof ListPendingUploads200ResponseResultsInner
     */
    sourceUrl?: string;
    /**
     * Document alternative source
     * @type {string}
     * @memberof ListPendingUploads200ResponseResultsInner
     */
    alternativeSource?: string;
}

/**
 * Check if a given object implements the ListPendingUploads200ResponseResultsInner interface.
 */
export function instanceOfListPendingUploads200ResponseResultsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uploadTaskId" in value;
    isInstance = isInstance && "uploadCreatedBy" in value;
    isInstance = isInstance && "uploadCreatedAt" in value;
    isInstance = isInstance && "uploadUpdatedAt" in value;
    isInstance = isInstance && "uploadStatus" in value;
    isInstance = isInstance && "publishedAt" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "organisations" in value;

    return isInstance;
}

export function ListPendingUploads200ResponseResultsInnerFromJSON(json: any): ListPendingUploads200ResponseResultsInner {
    return ListPendingUploads200ResponseResultsInnerFromJSONTyped(json, false);
}

export function ListPendingUploads200ResponseResultsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListPendingUploads200ResponseResultsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uploadTaskId': json['uploadTaskId'],
        'uploadCreatedBy': json['uploadCreatedBy'],
        'uploadCreatedAt': json['uploadCreatedAt'],
        'uploadUpdatedAt': json['uploadUpdatedAt'],
        'uploadStatus': json['uploadStatus'],
        'publishedAt': json['publishedAt'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'category': json['category'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'organisations': json['organisations'],
        'sourceUrl': !exists(json, 'sourceUrl') ? undefined : json['sourceUrl'],
        'alternativeSource': !exists(json, 'alternativeSource') ? undefined : json['alternativeSource'],
    };
}

export function ListPendingUploads200ResponseResultsInnerToJSON(value?: ListPendingUploads200ResponseResultsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uploadTaskId': value.uploadTaskId,
        'uploadCreatedBy': value.uploadCreatedBy,
        'uploadCreatedAt': value.uploadCreatedAt,
        'uploadUpdatedAt': value.uploadUpdatedAt,
        'uploadStatus': value.uploadStatus,
        'publishedAt': value.publishedAt,
        'description': value.description,
        'category': value.category,
        'title': value.title,
        'fileName': value.fileName,
        'organisations': value.organisations,
        'sourceUrl': value.sourceUrl,
        'alternativeSource': value.alternativeSource,
    };
}

