import React from "react";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { BuyerListFormValues } from "components/buyers/utils/types";
import { Input, Radio } from "components/form_components/Inputs";
import { GetBuyerListsPermissionEnum } from "lib/generated/app-api/apis/DefaultApi";
import { useBuyerLists } from "lib/hooks/api/buyer_lists/useBuyerLists";

type CreateBuyerListFormProps = {
  onSubmit: (values: BuyerListFormValues) => void;
  entityIds: string[];
  footer: React.ReactNode;
};

export function CreateBuyerListForm({ onSubmit, entityIds, footer }: CreateBuyerListFormProps) {
  const { data: buyerLists } = useBuyerLists(GetBuyerListsPermissionEnum.CreateBuyerListEntries, {
    enabled: !!window.currentUser,
  });

  const { control, handleSubmit, getValues } = useForm<BuyerListFormValues>({
    defaultValues: { sharingType: "PRIVATE", name: "", buyerGuids: entityIds },
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        control={control}
        name="name"
        label="List name"
        placeholder="Type list name here..."
        rules={{
          required: { value: true, message: "Buyer list name is required" },
          validate: () => {
            const newName = getValues("name");
            if (buyerLists?.all.map((l) => l.name.toLowerCase()).includes(newName.toLowerCase())) {
              return "This name is already in use";
            }
            return true;
          },
        }}
      />
      <Radio
        name="sharingType"
        control={control}
        label="Shared with"
        optionType="default"
        options={[
          { label: "Private", value: "PRIVATE" },
          { label: "Team", value: "TEAM" },
        ]}
      />
      {footer}
    </Form>
  );
}

const Form = styled.form({
  display: "flex",
  flexDirection: "column",
  height: "100%",
});
