import * as React from "react";
import { DevCycleProvider, DevCycleUser } from "@devcycle/react-client-sdk";
import { ThemeProvider } from "@emotion/react";
import { captureException, ErrorBoundary, withScope } from "@sentry/react";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { App, ConfigProvider, Layout } from "antd5";
import { Toaster } from "sonner";
import userflow from "userflow.js";

import { AdminBar } from "components/admin_bar/AdminBar";
import { APIError } from "lib/Api";
import { UserDataProvider } from "lib/data_providers/UserDataProvider";
import { OpenAPIProvider, STOTLES_OPEN_API } from "lib/openApiContext";
import { DialogManagerProvider } from "lib/providers/DialogManager";
import { PaywallManagerProvider } from "lib/providers/Paywall";
import { ProHelperProvider } from "lib/providers/ProHelper";
import { RecordViewerProvider } from "lib/providers/RecordViewer";
import { ResponsivenessSvcProvider } from "lib/providers/Responsive";
import StotlesAPI from "lib/StotlesApi";
import { ApiContextProvider } from "lib/stotlesApiContext";
import * as tracking from "lib/tracking";
import { IntegrationAPIProvider } from "../../lib/integrationApiContext";
import {
  CreateStotlesDataProvidersContainer,
  StotlesDataContextProvider,
} from "../../lib/providers/StotlesData";
import { lightTheme } from "../../styles/theme";
import BookADemoFromUrl from "../account_management/BookADemoFromUrl";
import AppContentLayout from "./AppContentLayout";
import { DefaultTheme } from "./DefaultTheme";
import { toasterStyles, toastOptions } from "./ToastOptions";

import css from "./AppLayout.module.scss";

const HIDE_ADMIN_BAR_KEY = "hideAdminBar";

const STOTLES_API = new StotlesAPI();
const REACT_QUERY_CLIENT = new QueryClient({
  mutationCache: new MutationCache({
    onError: (err, _variables, _context, mutation) => {
      withScope((scope) => {
        scope.setContext("mutation", {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });
        if (mutation.options.mutationKey) {
          scope.setFingerprint(Array.from(mutation.options.mutationKey) as string[]);
        }
        captureException(err);
      });
    },
  }),
  queryCache: new QueryCache({
    onError: (err, query) => {
      withScope((scope) => {
        scope.setContext("query", { queryHash: query.queryHash });
        captureException(err);
      });
    },
  }),
  defaultOptions: {
    queries: {
      staleTime: 10000,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (error instanceof APIError) {
          if (error.statusCode === 504) {
            return false;
          }
        }
        return failureCount < 3;
      },
    },
  },
});

const devCycleUserDetails: DevCycleUser | undefined = window.currentUser
  ? {
      user_id: window.currentUser.guid,
      customData: {
        company_id: window.currentUser.company.guid,
        company_name: window.currentUser.company.name,
        team_id: window.currentUser.team.id,
        team_name: window.currentUser.team.name,
        has_frameworks: window.currentUser.active_data_types.includes("FRAMEWORKS"),
        has_awards: window.currentUser.active_data_types.includes("AWARDS"),
        has_contacts: window.currentUser.active_data_types.includes("CONTACTS"),
        has_documents: window.currentUser.active_data_types.includes("DOCUMENTS"),
        has_buyer_supplier_data: window.currentUser.active_data_types.includes("BUYERS|SUPPLIERS"),
        subscription: window.currentUser.subscription,
        payment_type: window.currentUser.company.payment_type,
        partner_programme: window.currentUser.company.partner_programme?.name || null,
        use_supplier_name: window.currentUser.use_supplier_name,
      },
    }
  : undefined;

type Props<T extends object> = {
  pageName?: string;
  pageViewProps?: Record<string, string | number | undefined | Record<string, unknown> | string[]>; // These will be added to the page view event first triggered when page is opened
  hideMenuItems?: boolean;
  hideUserItems?: boolean;
  displayCustomAction?: React.ReactNode;
  disableLogoNav?: boolean; // If true, navigating through logo click is disabled
  trackingData?: tracking.EventData; // These feed through to all tracking events happening on this page
  contentClassName?: string;
  textLogo?: boolean;
  AdminBarComp?: React.ComponentType<T>;
  adminBarContent?: () => React.ReactNode;
};

function AppLayout<T extends object>({
  children,
  pageName,
  pageViewProps,
  hideMenuItems,
  hideUserItems,
  displayCustomAction,
  disableLogoNav,
  trackingData,
  contentClassName,
  textLogo,
  adminBarContent,
}: React.PropsWithChildren<Props<T>>): JSX.Element {
  // TODO: Read current user from local storage or a cookie.
  const { flash, guestUser } = window;
  const { message } = App.useApp();

  React.useEffect(() => {
    if (guestUser) {
      tracking.identifyByEmail(guestUser);
    }
  }, [guestUser]);

  React.useEffect(() => {
    if (window.guestUser) {
      tracking.identifyByEmail(window.guestUser);
    }
  }, []);

  React.useEffect(() => {
    tracking.pageView(pageName, {
      ...pageViewProps,
    });
  }, [pageName, pageViewProps]);

  React.useEffect(() => {
    for (const [flashType, flashMessage] of flash) {
      if (!flashMessage) continue;
      let messageFn;
      if (flashType === "error") {
        messageFn = message["error"];
      } else if (flashType === "alert") {
        messageFn = message["warning"];
      } else {
        messageFn = message["info"];
      }
      messageFn(flashMessage, 5);
    }
  }, [flash, message]);

  const stotlesData = React.useMemo(
    () => CreateStotlesDataProvidersContainer(STOTLES_API, STOTLES_OPEN_API),
    [],
  );

  React.useEffect(() => {
    const loadUserflow = async () => {
      const currentUser = window.currentUser;
      const USERFLOW_TOKEN = window.userflowToken;

      if (currentUser && USERFLOW_TOKEN) {
        try {
          await userflow.load();

          userflow.init(USERFLOW_TOKEN);
          userflow.identify(
            currentUser?.guid,
            {
              name: currentUser?.first_name + " " + currentUser?.last_name,
              first_name: currentUser?.first_name,
              last_name: currentUser?.last_name,
              email: currentUser?.email,
              data_types: currentUser?.active_data_types,
              signed_up_at: currentUser?.created_at,
              device_type: window.innerWidth > 992 ? "Desktop" : "Mobile",
            },
            {
              signature: window.userflowUserSignature,
            },
          );
          userflow.group(
            currentUser?.company.guid,
            {
              name: currentUser?.company.name,
              payment_type: currentUser?.company.payment_type || "Not set",
              signed_up_at: currentUser?.company.created_at,
            },
            { signature: window.userflowGroupSignature },
          );
        } catch (e) {
          console.warn("Error loading userflow", e);
        }
      }
    };

    void loadUserflow();
  }, []);

  // Currently, the order of these is very important (and can cause some errors if it isn't
  // exactly right).
  // TODO: consider refactoring into a single object?
  return (
    <ConfigProvider prefixCls="ant5" theme={DefaultTheme}>
      <App>
        <ErrorBoundary showDialog>
          <DevCycleProvider
            config={{
              sdkKey: window.dev_cycle_sdk_key,
              user: devCycleUserDetails,
              options: { enableEdgeDB: true },
            }}
          >
            <QueryClientProvider client={REACT_QUERY_CLIENT}>
              <ApiContextProvider api={STOTLES_API}>
                <IntegrationAPIProvider>
                  <OpenAPIProvider>
                    <UserDataProvider>
                      <ThemeProvider theme={lightTheme}>
                        <ResponsivenessSvcProvider>
                          <Layout className={css.pageLayout}>
                            <StotlesDataContextProvider instance={stotlesData}>
                              <PaywallManagerProvider
                                trackingPageName={pageName}
                                trackingExtraProps={pageViewProps}
                              >
                                <DialogManagerProvider>
                                  <BookADemoFromUrl>
                                    <ProHelperProvider>
                                      <RecordViewerProvider>
                                        <tracking.TrackingProvider data={trackingData ?? {}}>
                                          <AdminBar content={adminBarContent ?? (() => null)} />
                                          <Toaster
                                            offset={24}
                                            position="bottom-center"
                                            toastOptions={toastOptions}
                                            style={toasterStyles}
                                          />
                                          <AppContentLayout
                                            hideMenuItems={hideMenuItems}
                                            hideUserItems={hideUserItems}
                                            displayCustomAction={displayCustomAction}
                                            disableLogoNav={disableLogoNav}
                                            textLogo={textLogo}
                                            contentClassName={contentClassName}
                                          >
                                            {children}
                                          </AppContentLayout>
                                        </tracking.TrackingProvider>
                                      </RecordViewerProvider>
                                    </ProHelperProvider>
                                  </BookADemoFromUrl>
                                </DialogManagerProvider>
                              </PaywallManagerProvider>
                            </StotlesDataContextProvider>
                          </Layout>
                        </ResponsivenessSvcProvider>
                      </ThemeProvider>
                    </UserDataProvider>
                  </OpenAPIProvider>
                </IntegrationAPIProvider>
              </ApiContextProvider>
              {sessionStorage.getItem(HIDE_ADMIN_BAR_KEY) !== "true" && (
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="top-left"
                  toggleButtonProps={{ className: css.reactQuery }}
                />
              )}
            </QueryClientProvider>
          </DevCycleProvider>
        </ErrorBoundary>
      </App>
    </ConfigProvider>
  );
}

export function withAppLayout<T extends object>(
  Component: React.ComponentType<T>,
  appLayoutProps: Props<T> | ((props: T) => Props<T>),
): (props: T) => JSX.Element {
  // eslint-disable-next-line react/display-name
  return function (props: T): JSX.Element {
    const { AdminBarComp, ...rest }: Props<T> =
      typeof appLayoutProps === "function" ? appLayoutProps(props) : appLayoutProps;

    return (
      <AppLayout
        // this is helpful for when you need the appLayoutProps to be dynamic, eg. properties
        // for an amplitude event which are based off the props, (buyer id in BuyerDetailsPage
        // for example)
        {...rest}
        // use a function to only trigger the render in the admin bar itself
        // for now feed same props from rails as the main page comp
        adminBarContent={() => (AdminBarComp ? <AdminBarComp {...props} /> : null)}
      >
        <Component {...props} />
      </AppLayout>
    );
  };
}
