/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddBuyersRequest,
  AllBuyersStatsRequest,
  AllBuyersStatsResponse,
  AsyncRecordExportRequest,
  AsyncRecordExportResponse,
  AvailableBuyerFilters,
  AvailableTeamResponse,
  BuyerFilterRequest,
  BuyerListDetails,
  BuyerListDto,
  BuyersBySupplierRequest,
  BuyersBySupplierResponse,
  BuyersRelationshipStatsRequest,
  BuyersRelationshipStatsResponse,
  CommentDto,
  ContactSearchRequestDto,
  ContactSearchResponseDto,
  CreateAccountErrorResponse,
  CreateAccountRequest,
  CreateAccountResponse,
  CreateBuyerListRequest,
  CreateCommentRequest,
  CreateLeadSubscriptionContentRequest,
  CreateLeadSubscriptionRequest,
  CreatePinnedSavedViewRequest,
  CreateQualificationRequest,
  CreateQualificationResponse,
  CreateSavedViewRequest,
  CreateSavedViewResponse,
  DeleteLeadSubscriptionContentRequest,
  DeletePinnedSavedViewRequest,
  DeleteQualificationRequest,
  DescribeRecordListRequest,
  DescribeRecordListResponse,
  ExportOutreachLeadsRequest,
  FeedbackRequest,
  GenerateOutreachRequest,
  GenerateOutreachResponse,
  GetAppApiTokenResponse,
  GetAssignedNoticesResponse,
  GetBuyerListPermissionsResponse,
  GetBuyerListsResponse,
  GetIndustryTemplatesResponse,
  GetLeadSubscriptionsResponse,
  GetLeadSubscriptionsStatusResponse,
  GetNotificationSettingsResponse,
  GetProcurementStageCommentsResponse,
  GetPublicReportsResponse,
  GetQualificationStageResponse,
  GetQualifiedNoticesResponse,
  GetRecordListsResponse,
  GetRecordSummaryDetailsRequest,
  GetRecordSummaryDetailsResponse,
  GetReportsResponse,
  GetSelectedStandardViewIdsResponse,
  GetSignalSettingsResponse,
  GetUsersResponse,
  IndexRequest,
  ListNewSavedViewsResponse,
  ListPinnedSavedViewRequest,
  ListPinnedSavedViewsResponse,
  MarkAsCompleteRequest,
  MarkNotificationsAsReadRequest,
  MarkNotificationsAsUnreadRequest,
  NotificationsIndexResponse,
  OnboardingTemplateDto,
  PartnersCompetitorsRequest,
  PartnersCompetitorsResponse,
  PqlActionedRequest,
  ProductTourCompletionStateResponse,
  RecordSearchRequestDto,
  RecordSearchResponseDto,
  RecordTimelineDto,
  RemoveBuyersRequest,
  RequestOutreachContactsRequest,
  SearchReferenceOrgsRequest,
  SearchReferenceOrgsResponse,
  SearchRegionsResponse,
  SendVerificationEmailResponse,
  SubmitAndProcessOrgCleaningTask400Response,
  SubmitAndProcessOrgCleaningTaskRequest,
  SubmitEditOrgCleaningTaskRequest,
  SubmitOrgCleaningTaskRequest,
  SubmitOrgDecisionLogRequest,
  SubscriptionContentsResponse,
  SubscriptionDowngradeRequest,
  SupplierExportRequest,
  SupplierListSummaryResponse,
  SupplierSearchRequest,
  SupplierSearchResponse,
  SupplierSignalBuyerResponse,
  SupplierSignalBuyersRequest,
  SupplierSignalStatsRequest,
  SupplierSignalStatsResponse,
  SupplierStatsRequest,
  SupplierStatsResponse,
  SupplierSummaryDto,
  SuppliersV3Request,
  SuppliersV3Response,
  SwitchUserTeamRequest,
  TeamManagementUsersRequest,
  TeamManagementUsersResponse,
  UpdateBuyerListPermissionsRequest,
  UpdateBuyerListRequest,
  UpdateCommentRequest,
  UpdateIndustryRequest,
  UpdateLeadAssigneeRequest,
  UpdateLeadSubscriptionContentRequest,
  UpdateLeadSubscriptionRequest,
  UpdateNotificationSettingsRequest,
  UpdatePinnedSavedViewRequest,
  UpdatePubSecExperienceRequest,
  UpdateSavedViewRequest,
} from '../models';
import {
    AddBuyersRequestFromJSON,
    AddBuyersRequestToJSON,
    AllBuyersStatsRequestFromJSON,
    AllBuyersStatsRequestToJSON,
    AllBuyersStatsResponseFromJSON,
    AllBuyersStatsResponseToJSON,
    AsyncRecordExportRequestFromJSON,
    AsyncRecordExportRequestToJSON,
    AsyncRecordExportResponseFromJSON,
    AsyncRecordExportResponseToJSON,
    AvailableBuyerFiltersFromJSON,
    AvailableBuyerFiltersToJSON,
    AvailableTeamResponseFromJSON,
    AvailableTeamResponseToJSON,
    BuyerFilterRequestFromJSON,
    BuyerFilterRequestToJSON,
    BuyerListDetailsFromJSON,
    BuyerListDetailsToJSON,
    BuyerListDtoFromJSON,
    BuyerListDtoToJSON,
    BuyersBySupplierRequestFromJSON,
    BuyersBySupplierRequestToJSON,
    BuyersBySupplierResponseFromJSON,
    BuyersBySupplierResponseToJSON,
    BuyersRelationshipStatsRequestFromJSON,
    BuyersRelationshipStatsRequestToJSON,
    BuyersRelationshipStatsResponseFromJSON,
    BuyersRelationshipStatsResponseToJSON,
    CommentDtoFromJSON,
    CommentDtoToJSON,
    ContactSearchRequestDtoFromJSON,
    ContactSearchRequestDtoToJSON,
    ContactSearchResponseDtoFromJSON,
    ContactSearchResponseDtoToJSON,
    CreateAccountErrorResponseFromJSON,
    CreateAccountErrorResponseToJSON,
    CreateAccountRequestFromJSON,
    CreateAccountRequestToJSON,
    CreateAccountResponseFromJSON,
    CreateAccountResponseToJSON,
    CreateBuyerListRequestFromJSON,
    CreateBuyerListRequestToJSON,
    CreateCommentRequestFromJSON,
    CreateCommentRequestToJSON,
    CreateLeadSubscriptionContentRequestFromJSON,
    CreateLeadSubscriptionContentRequestToJSON,
    CreateLeadSubscriptionRequestFromJSON,
    CreateLeadSubscriptionRequestToJSON,
    CreatePinnedSavedViewRequestFromJSON,
    CreatePinnedSavedViewRequestToJSON,
    CreateQualificationRequestFromJSON,
    CreateQualificationRequestToJSON,
    CreateQualificationResponseFromJSON,
    CreateQualificationResponseToJSON,
    CreateSavedViewRequestFromJSON,
    CreateSavedViewRequestToJSON,
    CreateSavedViewResponseFromJSON,
    CreateSavedViewResponseToJSON,
    DeleteLeadSubscriptionContentRequestFromJSON,
    DeleteLeadSubscriptionContentRequestToJSON,
    DeletePinnedSavedViewRequestFromJSON,
    DeletePinnedSavedViewRequestToJSON,
    DeleteQualificationRequestFromJSON,
    DeleteQualificationRequestToJSON,
    DescribeRecordListRequestFromJSON,
    DescribeRecordListRequestToJSON,
    DescribeRecordListResponseFromJSON,
    DescribeRecordListResponseToJSON,
    ExportOutreachLeadsRequestFromJSON,
    ExportOutreachLeadsRequestToJSON,
    FeedbackRequestFromJSON,
    FeedbackRequestToJSON,
    GenerateOutreachRequestFromJSON,
    GenerateOutreachRequestToJSON,
    GenerateOutreachResponseFromJSON,
    GenerateOutreachResponseToJSON,
    GetAppApiTokenResponseFromJSON,
    GetAppApiTokenResponseToJSON,
    GetAssignedNoticesResponseFromJSON,
    GetAssignedNoticesResponseToJSON,
    GetBuyerListPermissionsResponseFromJSON,
    GetBuyerListPermissionsResponseToJSON,
    GetBuyerListsResponseFromJSON,
    GetBuyerListsResponseToJSON,
    GetIndustryTemplatesResponseFromJSON,
    GetIndustryTemplatesResponseToJSON,
    GetLeadSubscriptionsResponseFromJSON,
    GetLeadSubscriptionsResponseToJSON,
    GetLeadSubscriptionsStatusResponseFromJSON,
    GetLeadSubscriptionsStatusResponseToJSON,
    GetNotificationSettingsResponseFromJSON,
    GetNotificationSettingsResponseToJSON,
    GetProcurementStageCommentsResponseFromJSON,
    GetProcurementStageCommentsResponseToJSON,
    GetPublicReportsResponseFromJSON,
    GetPublicReportsResponseToJSON,
    GetQualificationStageResponseFromJSON,
    GetQualificationStageResponseToJSON,
    GetQualifiedNoticesResponseFromJSON,
    GetQualifiedNoticesResponseToJSON,
    GetRecordListsResponseFromJSON,
    GetRecordListsResponseToJSON,
    GetRecordSummaryDetailsRequestFromJSON,
    GetRecordSummaryDetailsRequestToJSON,
    GetRecordSummaryDetailsResponseFromJSON,
    GetRecordSummaryDetailsResponseToJSON,
    GetReportsResponseFromJSON,
    GetReportsResponseToJSON,
    GetSelectedStandardViewIdsResponseFromJSON,
    GetSelectedStandardViewIdsResponseToJSON,
    GetSignalSettingsResponseFromJSON,
    GetSignalSettingsResponseToJSON,
    GetUsersResponseFromJSON,
    GetUsersResponseToJSON,
    IndexRequestFromJSON,
    IndexRequestToJSON,
    ListNewSavedViewsResponseFromJSON,
    ListNewSavedViewsResponseToJSON,
    ListPinnedSavedViewRequestFromJSON,
    ListPinnedSavedViewRequestToJSON,
    ListPinnedSavedViewsResponseFromJSON,
    ListPinnedSavedViewsResponseToJSON,
    MarkAsCompleteRequestFromJSON,
    MarkAsCompleteRequestToJSON,
    MarkNotificationsAsReadRequestFromJSON,
    MarkNotificationsAsReadRequestToJSON,
    MarkNotificationsAsUnreadRequestFromJSON,
    MarkNotificationsAsUnreadRequestToJSON,
    NotificationsIndexResponseFromJSON,
    NotificationsIndexResponseToJSON,
    OnboardingTemplateDtoFromJSON,
    OnboardingTemplateDtoToJSON,
    PartnersCompetitorsRequestFromJSON,
    PartnersCompetitorsRequestToJSON,
    PartnersCompetitorsResponseFromJSON,
    PartnersCompetitorsResponseToJSON,
    PqlActionedRequestFromJSON,
    PqlActionedRequestToJSON,
    ProductTourCompletionStateResponseFromJSON,
    ProductTourCompletionStateResponseToJSON,
    RecordSearchRequestDtoFromJSON,
    RecordSearchRequestDtoToJSON,
    RecordSearchResponseDtoFromJSON,
    RecordSearchResponseDtoToJSON,
    RecordTimelineDtoFromJSON,
    RecordTimelineDtoToJSON,
    RemoveBuyersRequestFromJSON,
    RemoveBuyersRequestToJSON,
    RequestOutreachContactsRequestFromJSON,
    RequestOutreachContactsRequestToJSON,
    SearchReferenceOrgsRequestFromJSON,
    SearchReferenceOrgsRequestToJSON,
    SearchReferenceOrgsResponseFromJSON,
    SearchReferenceOrgsResponseToJSON,
    SearchRegionsResponseFromJSON,
    SearchRegionsResponseToJSON,
    SendVerificationEmailResponseFromJSON,
    SendVerificationEmailResponseToJSON,
    SubmitAndProcessOrgCleaningTask400ResponseFromJSON,
    SubmitAndProcessOrgCleaningTask400ResponseToJSON,
    SubmitAndProcessOrgCleaningTaskRequestFromJSON,
    SubmitAndProcessOrgCleaningTaskRequestToJSON,
    SubmitEditOrgCleaningTaskRequestFromJSON,
    SubmitEditOrgCleaningTaskRequestToJSON,
    SubmitOrgCleaningTaskRequestFromJSON,
    SubmitOrgCleaningTaskRequestToJSON,
    SubmitOrgDecisionLogRequestFromJSON,
    SubmitOrgDecisionLogRequestToJSON,
    SubscriptionContentsResponseFromJSON,
    SubscriptionContentsResponseToJSON,
    SubscriptionDowngradeRequestFromJSON,
    SubscriptionDowngradeRequestToJSON,
    SupplierExportRequestFromJSON,
    SupplierExportRequestToJSON,
    SupplierListSummaryResponseFromJSON,
    SupplierListSummaryResponseToJSON,
    SupplierSearchRequestFromJSON,
    SupplierSearchRequestToJSON,
    SupplierSearchResponseFromJSON,
    SupplierSearchResponseToJSON,
    SupplierSignalBuyerResponseFromJSON,
    SupplierSignalBuyerResponseToJSON,
    SupplierSignalBuyersRequestFromJSON,
    SupplierSignalBuyersRequestToJSON,
    SupplierSignalStatsRequestFromJSON,
    SupplierSignalStatsRequestToJSON,
    SupplierSignalStatsResponseFromJSON,
    SupplierSignalStatsResponseToJSON,
    SupplierStatsRequestFromJSON,
    SupplierStatsRequestToJSON,
    SupplierStatsResponseFromJSON,
    SupplierStatsResponseToJSON,
    SupplierSummaryDtoFromJSON,
    SupplierSummaryDtoToJSON,
    SuppliersV3RequestFromJSON,
    SuppliersV3RequestToJSON,
    SuppliersV3ResponseFromJSON,
    SuppliersV3ResponseToJSON,
    SwitchUserTeamRequestFromJSON,
    SwitchUserTeamRequestToJSON,
    TeamManagementUsersRequestFromJSON,
    TeamManagementUsersRequestToJSON,
    TeamManagementUsersResponseFromJSON,
    TeamManagementUsersResponseToJSON,
    UpdateBuyerListPermissionsRequestFromJSON,
    UpdateBuyerListPermissionsRequestToJSON,
    UpdateBuyerListRequestFromJSON,
    UpdateBuyerListRequestToJSON,
    UpdateCommentRequestFromJSON,
    UpdateCommentRequestToJSON,
    UpdateIndustryRequestFromJSON,
    UpdateIndustryRequestToJSON,
    UpdateLeadAssigneeRequestFromJSON,
    UpdateLeadAssigneeRequestToJSON,
    UpdateLeadSubscriptionContentRequestFromJSON,
    UpdateLeadSubscriptionContentRequestToJSON,
    UpdateLeadSubscriptionRequestFromJSON,
    UpdateLeadSubscriptionRequestToJSON,
    UpdateNotificationSettingsRequestFromJSON,
    UpdateNotificationSettingsRequestToJSON,
    UpdatePinnedSavedViewRequestFromJSON,
    UpdatePinnedSavedViewRequestToJSON,
    UpdatePubSecExperienceRequestFromJSON,
    UpdatePubSecExperienceRequestToJSON,
    UpdateSavedViewRequestFromJSON,
    UpdateSavedViewRequestToJSON,
} from '../models';

export interface AddBuyersToListRequest {
    id: string;
    addBuyersRequest: AddBuyersRequest;
}

export interface AllBuyersStatsOperationRequest {
    allBuyersStatsRequest: AllBuyersStatsRequest;
}

export interface AllBuyersStatsCsvRequest {
    allBuyersStatsRequest: AllBuyersStatsRequest;
}

export interface AllBuyersStatsXlsxRequest {
    allBuyersStatsRequest: AllBuyersStatsRequest;
}

export interface AsyncRecordExportOperationRequest {
    asyncRecordExportRequest: AsyncRecordExportRequest;
}

export interface BuyersRelationshipStatsCsvRequest {
    buyersRelationshipStatsRequest: BuyersRelationshipStatsRequest;
}

export interface BuyersRelationshipStatsXlsxRequest {
    buyersRelationshipStatsRequest: BuyersRelationshipStatsRequest;
}

export interface ContactsSearchRequest {
    contactSearchRequestDto: ContactSearchRequestDto;
}

export interface ContactsSearchCsvRequest {
    contactSearchRequestDto: ContactSearchRequestDto;
}

export interface ContactsSearchXlsxRequest {
    contactSearchRequestDto: ContactSearchRequestDto;
}

export interface CreateAccountOperationRequest {
    createAccountRequest: CreateAccountRequest;
}

export interface CreateBuyerListOperationRequest {
    createBuyerListRequest: CreateBuyerListRequest;
}

export interface CreateLeadSubscriptionOperationRequest {
    createLeadSubscriptionRequest: CreateLeadSubscriptionRequest;
}

export interface CreateLeadSubscriptionContentOperationRequest {
    createLeadSubscriptionContentRequest: CreateLeadSubscriptionContentRequest;
}

export interface CreatePinnedSavedViewOperationRequest {
    createPinnedSavedViewRequest: CreatePinnedSavedViewRequest;
}

export interface CreateProcurementStageCommentRequest {
    companyGuid: string;
    procurementStageId: string;
    createCommentRequest: CreateCommentRequest;
}

export interface CreateProcurementStageQualificationRequest {
    createQualificationRequest: CreateQualificationRequest;
}

export interface CreateSavedViewOperationRequest {
    createSavedViewRequest: CreateSavedViewRequest;
}

export interface DeleteBuyerListRequest {
    id: string;
}

export interface DeleteCommentRequest {
    id: string;
}

export interface DeleteDocumentRequest {
    body: object;
}

export interface DeleteLeadSubscriptionContentOperationRequest {
    deleteLeadSubscriptionContentRequest: DeleteLeadSubscriptionContentRequest;
}

export interface DeletePinnedSavedViewOperationRequest {
    deletePinnedSavedViewRequest: DeletePinnedSavedViewRequest;
}

export interface DeleteProcurementStageQualificationRequest {
    deleteQualificationRequest: DeleteQualificationRequest;
}

export interface DescribeRecordListOperationRequest {
    describeRecordListRequest: DescribeRecordListRequest;
}

export interface DowngradeSubscriptionRequest {
    subscriptionDowngradeRequest: SubscriptionDowngradeRequest;
}

export interface EditOrgCleaningTaskRequest {
    id: string;
    submitEditOrgCleaningTaskRequest: SubmitEditOrgCleaningTaskRequest;
}

export interface ExportOutreachLeadsOperationRequest {
    exportOutreachLeadsRequest: ExportOutreachLeadsRequest;
}

export interface ExportSearchRecordsCsvRequest {
    recordSearchRequestDto: RecordSearchRequestDto;
}

export interface ExportSearchRecordsXlsxRequest {
    recordSearchRequestDto: RecordSearchRequestDto;
}

export interface ExportSuppliersRequest {
    supplierExportRequest: SupplierExportRequest;
}

export interface FetchBuyerFiltersRequest {
    buyerFilterRequest?: BuyerFilterRequest;
}

export interface FetchBuyerSignalStatsRequest {
    buyersRelationshipStatsRequest: BuyersRelationshipStatsRequest;
}

export interface FetchPartnersOrCompetitorsForBuyerRequest {
    id: number;
    partnersCompetitorsRequest: PartnersCompetitorsRequest;
}

export interface FetchSuppliersForBuyerRequest {
    buyerId: number;
    suppliersV3Request: SuppliersV3Request;
}

export interface GenerateOutreachOperationRequest {
    generateOutreachRequest: GenerateOutreachRequest;
}

export interface GetBuyerListRequest {
    id: string;
}

export interface GetBuyerListPermissionsRequest {
    id: string;
}

export interface GetBuyerListsRequest {
    permission?: GetBuyerListsPermissionEnum;
}

export interface GetBuyerStatsSupplierSignalRequest {
    supplierSignalBuyersRequest: SupplierSignalBuyersRequest;
}

export interface GetBuyersBySupplierRequest {
    id: number;
    buyersBySupplierRequest: BuyersBySupplierRequest;
}

export interface GetCompanyBuyerListsRequest {
    companyGuid: string;
}

export interface GetLeadSubscriptionStatusRequest {
    token: string;
}

export interface GetOnboardingTemplateRequest {
    templateId: string;
}

export interface GetProcurementStageCommentsRequest {
    companyGuid: string;
    procurementStageId: string;
}

export interface GetProductTourStatesRequest {
    indexRequest: IndexRequest;
}

export interface GetRecordSummaryDetailsOperationRequest {
    getRecordSummaryDetailsRequest: GetRecordSummaryDetailsRequest;
}

export interface GetReportsRequest {
    companyGuid: string;
}

export interface GetStoredRecordSummaryRequest {
    getRecordSummaryDetailsRequest: GetRecordSummaryDetailsRequest;
}

export interface GetSupplierRequest {
    guid: string;
}

export interface GetSupplierSignalStatsRequest {
    supplierSignalStatsRequest: SupplierSignalStatsRequest;
}

export interface GetSupplierStatsListRequest {
    category: string;
}

export interface GetTeamsForCompanyRequest {
    id: number;
}

export interface GetUsersRequest {
    companyGuid: string;
}

export interface ListPendingUploadsRequest {
    body: object;
}

export interface ListPinnedSavedViewsRequest {
    listPinnedSavedViewRequest: ListPinnedSavedViewRequest;
}

export interface MarkNotificationsAsReadOperationRequest {
    markNotificationsAsReadRequest: MarkNotificationsAsReadRequest;
}

export interface MarkNotificationsAsUnreadOperationRequest {
    markNotificationsAsUnreadRequest: MarkNotificationsAsUnreadRequest;
}

export interface MarkProductTourAsCompleteRequest {
    markAsCompleteRequest: MarkAsCompleteRequest;
}

export interface MarkRecordAsSeenRequest {
    recordGuid: string;
    userId: string;
}

export interface PqlActionedOperationRequest {
    pqlActionedRequest: PqlActionedRequest;
}

export interface PreviewCallOffsRequest {
    body: object;
}

export interface PreviewCallOffsDocumentSignalsRequest {
    body: object;
}

export interface PreviewCallOffsNoticeSignalsRequest {
    body: object;
}

export interface ProductFeedbackRequest {
    feedbackRequest: FeedbackRequest;
}

export interface RecordTimelineRequest {
    recordFamilyId: string;
}

export interface RemoveBuyersFromListRequest {
    id: string;
    removeBuyersRequest: RemoveBuyersRequest;
}

export interface RequestOutreachContactsOperationRequest {
    requestOutreachContactsRequest: RequestOutreachContactsRequest;
}

export interface RequestSpendRequest {
    guid: string;
}

export interface SearchFrameworkOrganisationsRequest {
    body: object;
}

export interface SearchRecordsRequest {
    recordSearchRequestDto: RecordSearchRequestDto;
}

export interface SearchReferenceOrgsOperationRequest {
    searchReferenceOrgsRequest: SearchReferenceOrgsRequest;
}

export interface SearchSuppliersRequest {
    supplierSearchRequest: SupplierSearchRequest;
}

export interface SubmitAndProcessOrgCleaningTaskOperationRequest {
    submitAndProcessOrgCleaningTaskRequest: SubmitAndProcessOrgCleaningTaskRequest;
}

export interface SubmitOrgCleaningTaskOperationRequest {
    submitOrgCleaningTaskRequest: SubmitOrgCleaningTaskRequest;
}

export interface SupplierStatsOperationRequest {
    supplierStatsRequest: SupplierStatsRequest;
}

export interface SwitchUserTeamOperationRequest {
    switchUserTeamRequest: SwitchUserTeamRequest;
}

export interface TeamManagementUsersOperationRequest {
    teamManagementUsersRequest: TeamManagementUsersRequest;
}

export interface UnsubscribeRequest {
    token: string;
    listUnsubscribe?: string;
}

export interface UpdateBuyerListOperationRequest {
    id: string;
    updateBuyerListRequest: UpdateBuyerListRequest;
}

export interface UpdateBuyerListPermissionsOperationRequest {
    id: string;
    updateBuyerListPermissionsRequest: UpdateBuyerListPermissionsRequest;
}

export interface UpdateCommentOperationRequest {
    id: string;
    updateCommentRequest: UpdateCommentRequest;
}

export interface UpdateDocumentRequest {
    body: object;
}

export interface UpdateIndustryOperationRequest {
    companyGuid: string;
    updateIndustryRequest: UpdateIndustryRequest;
}

export interface UpdateLeadAssigneeOperationRequest {
    companyGuid: string;
    procurementStageId: string;
    updateLeadAssigneeRequest: UpdateLeadAssigneeRequest;
}

export interface UpdateLeadSubscriptionOperationRequest {
    id: string;
    updateLeadSubscriptionRequest: UpdateLeadSubscriptionRequest;
}

export interface UpdateLeadSubscriptionContentOperationRequest {
    id: string;
    updateLeadSubscriptionContentRequest: UpdateLeadSubscriptionContentRequest;
}

export interface UpdateNotificationSettingsOperationRequest {
    updateNotificationSettingsRequest: UpdateNotificationSettingsRequest;
}

export interface UpdateOrgCleaningDecisionLogsRequest {
    submitOrgDecisionLogRequest: SubmitOrgDecisionLogRequest;
}

export interface UpdatePinnedSavedViewOperationRequest {
    updatePinnedSavedViewRequest: UpdatePinnedSavedViewRequest;
}

export interface UpdatePubSecExperienceOperationRequest {
    updatePubSecExperienceRequest: UpdatePubSecExperienceRequest;
}

export interface UpdateSavedViewOperationRequest {
    id: string;
    updateSavedViewRequest: UpdateSavedViewRequest;
}

export interface UploadFileDocumentRequest {
    file?: Blob;
}

export interface UploadUrlDocumentRequest {
    body: object;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async addBuyersToListRaw(requestParameters: AddBuyersToListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addBuyersToList.');
        }

        if (requestParameters.addBuyersRequest === null || requestParameters.addBuyersRequest === undefined) {
            throw new runtime.RequiredError('addBuyersRequest','Required parameter requestParameters.addBuyersRequest was null or undefined when calling addBuyersToList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/buyer_lists/{id}/add`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddBuyersRequestToJSON(requestParameters.addBuyersRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addBuyersToList(requestParameters: AddBuyersToListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addBuyersToListRaw(requestParameters, initOverrides);
    }

    /**
     */
    async allBuyersStatsRaw(requestParameters: AllBuyersStatsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AllBuyersStatsResponse>> {
        if (requestParameters.allBuyersStatsRequest === null || requestParameters.allBuyersStatsRequest === undefined) {
            throw new runtime.RequiredError('allBuyersStatsRequest','Required parameter requestParameters.allBuyersStatsRequest was null or undefined when calling allBuyersStats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/buyers/all_buyers_stats`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AllBuyersStatsRequestToJSON(requestParameters.allBuyersStatsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllBuyersStatsResponseFromJSON(jsonValue));
    }

    /**
     */
    async allBuyersStats(requestParameters: AllBuyersStatsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AllBuyersStatsResponse> {
        const response = await this.allBuyersStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async allBuyersStatsCsvRaw(requestParameters: AllBuyersStatsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.allBuyersStatsRequest === null || requestParameters.allBuyersStatsRequest === undefined) {
            throw new runtime.RequiredError('allBuyersStatsRequest','Required parameter requestParameters.allBuyersStatsRequest was null or undefined when calling allBuyersStatsCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/buyers/all_buyers_stats.csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AllBuyersStatsRequestToJSON(requestParameters.allBuyersStatsRequest),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async allBuyersStatsCsv(requestParameters: AllBuyersStatsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.allBuyersStatsCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async allBuyersStatsXlsxRaw(requestParameters: AllBuyersStatsXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.allBuyersStatsRequest === null || requestParameters.allBuyersStatsRequest === undefined) {
            throw new runtime.RequiredError('allBuyersStatsRequest','Required parameter requestParameters.allBuyersStatsRequest was null or undefined when calling allBuyersStatsXlsx.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/buyers/all_buyers_stats.xlsx`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AllBuyersStatsRequestToJSON(requestParameters.allBuyersStatsRequest),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async allBuyersStatsXlsx(requestParameters: AllBuyersStatsXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.allBuyersStatsXlsxRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async asyncRecordExportRaw(requestParameters: AsyncRecordExportOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AsyncRecordExportResponse>> {
        if (requestParameters.asyncRecordExportRequest === null || requestParameters.asyncRecordExportRequest === undefined) {
            throw new runtime.RequiredError('asyncRecordExportRequest','Required parameter requestParameters.asyncRecordExportRequest was null or undefined when calling asyncRecordExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/records/async_export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AsyncRecordExportRequestToJSON(requestParameters.asyncRecordExportRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AsyncRecordExportResponseFromJSON(jsonValue));
    }

    /**
     */
    async asyncRecordExport(requestParameters: AsyncRecordExportOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AsyncRecordExportResponse> {
        const response = await this.asyncRecordExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async buyersRelationshipStatsCsvRaw(requestParameters: BuyersRelationshipStatsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.buyersRelationshipStatsRequest === null || requestParameters.buyersRelationshipStatsRequest === undefined) {
            throw new runtime.RequiredError('buyersRelationshipStatsRequest','Required parameter requestParameters.buyersRelationshipStatsRequest was null or undefined when calling buyersRelationshipStatsCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/buyers/signal_stats.csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BuyersRelationshipStatsRequestToJSON(requestParameters.buyersRelationshipStatsRequest),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async buyersRelationshipStatsCsv(requestParameters: BuyersRelationshipStatsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.buyersRelationshipStatsCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async buyersRelationshipStatsXlsxRaw(requestParameters: BuyersRelationshipStatsXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.buyersRelationshipStatsRequest === null || requestParameters.buyersRelationshipStatsRequest === undefined) {
            throw new runtime.RequiredError('buyersRelationshipStatsRequest','Required parameter requestParameters.buyersRelationshipStatsRequest was null or undefined when calling buyersRelationshipStatsXlsx.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/buyers/signal_stats.xlsx`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BuyersRelationshipStatsRequestToJSON(requestParameters.buyersRelationshipStatsRequest),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async buyersRelationshipStatsXlsx(requestParameters: BuyersRelationshipStatsXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.buyersRelationshipStatsXlsxRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactsSearchRaw(requestParameters: ContactsSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactSearchResponseDto>> {
        if (requestParameters.contactSearchRequestDto === null || requestParameters.contactSearchRequestDto === undefined) {
            throw new runtime.RequiredError('contactSearchRequestDto','Required parameter requestParameters.contactSearchRequestDto was null or undefined when calling contactsSearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/contacts/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactSearchRequestDtoToJSON(requestParameters.contactSearchRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactSearchResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async contactsSearch(requestParameters: ContactsSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactSearchResponseDto> {
        const response = await this.contactsSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactsSearchCsvRaw(requestParameters: ContactsSearchCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.contactSearchRequestDto === null || requestParameters.contactSearchRequestDto === undefined) {
            throw new runtime.RequiredError('contactSearchRequestDto','Required parameter requestParameters.contactSearchRequestDto was null or undefined when calling contactsSearchCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/contacts/search.csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactSearchRequestDtoToJSON(requestParameters.contactSearchRequestDto),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async contactsSearchCsv(requestParameters: ContactsSearchCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.contactsSearchCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactsSearchXlsxRaw(requestParameters: ContactsSearchXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.contactSearchRequestDto === null || requestParameters.contactSearchRequestDto === undefined) {
            throw new runtime.RequiredError('contactSearchRequestDto','Required parameter requestParameters.contactSearchRequestDto was null or undefined when calling contactsSearchXlsx.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/contacts/search.xlsx`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactSearchRequestDtoToJSON(requestParameters.contactSearchRequestDto),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async contactsSearchXlsx(requestParameters: ContactsSearchXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.contactsSearchXlsxRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createAccountRaw(requestParameters: CreateAccountOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAccountResponse>> {
        if (requestParameters.createAccountRequest === null || requestParameters.createAccountRequest === undefined) {
            throw new runtime.RequiredError('createAccountRequest','Required parameter requestParameters.createAccountRequest was null or undefined when calling createAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/create-account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAccountRequestToJSON(requestParameters.createAccountRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAccountResponseFromJSON(jsonValue));
    }

    /**
     */
    async createAccount(requestParameters: CreateAccountOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAccountResponse> {
        const response = await this.createAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createBuyerListRaw(requestParameters: CreateBuyerListOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BuyerListDetails>> {
        if (requestParameters.createBuyerListRequest === null || requestParameters.createBuyerListRequest === undefined) {
            throw new runtime.RequiredError('createBuyerListRequest','Required parameter requestParameters.createBuyerListRequest was null or undefined when calling createBuyerList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/buyer_lists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBuyerListRequestToJSON(requestParameters.createBuyerListRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BuyerListDetailsFromJSON(jsonValue));
    }

    /**
     */
    async createBuyerList(requestParameters: CreateBuyerListOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BuyerListDetails> {
        const response = await this.createBuyerListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createLeadSubscriptionRaw(requestParameters: CreateLeadSubscriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createLeadSubscriptionRequest === null || requestParameters.createLeadSubscriptionRequest === undefined) {
            throw new runtime.RequiredError('createLeadSubscriptionRequest','Required parameter requestParameters.createLeadSubscriptionRequest was null or undefined when calling createLeadSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/lead_subscriptions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLeadSubscriptionRequestToJSON(requestParameters.createLeadSubscriptionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createLeadSubscription(requestParameters: CreateLeadSubscriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createLeadSubscriptionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createLeadSubscriptionContentRaw(requestParameters: CreateLeadSubscriptionContentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createLeadSubscriptionContentRequest === null || requestParameters.createLeadSubscriptionContentRequest === undefined) {
            throw new runtime.RequiredError('createLeadSubscriptionContentRequest','Required parameter requestParameters.createLeadSubscriptionContentRequest was null or undefined when calling createLeadSubscriptionContent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/lead_subscription_contents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLeadSubscriptionContentRequestToJSON(requestParameters.createLeadSubscriptionContentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createLeadSubscriptionContent(requestParameters: CreateLeadSubscriptionContentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createLeadSubscriptionContentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createPinnedSavedViewRaw(requestParameters: CreatePinnedSavedViewOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createPinnedSavedViewRequest === null || requestParameters.createPinnedSavedViewRequest === undefined) {
            throw new runtime.RequiredError('createPinnedSavedViewRequest','Required parameter requestParameters.createPinnedSavedViewRequest was null or undefined when calling createPinnedSavedView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pinned_saved_views/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePinnedSavedViewRequestToJSON(requestParameters.createPinnedSavedViewRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createPinnedSavedView(requestParameters: CreatePinnedSavedViewOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createPinnedSavedViewRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createProcurementStageCommentRaw(requestParameters: CreateProcurementStageCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommentDto>> {
        if (requestParameters.companyGuid === null || requestParameters.companyGuid === undefined) {
            throw new runtime.RequiredError('companyGuid','Required parameter requestParameters.companyGuid was null or undefined when calling createProcurementStageComment.');
        }

        if (requestParameters.procurementStageId === null || requestParameters.procurementStageId === undefined) {
            throw new runtime.RequiredError('procurementStageId','Required parameter requestParameters.procurementStageId was null or undefined when calling createProcurementStageComment.');
        }

        if (requestParameters.createCommentRequest === null || requestParameters.createCommentRequest === undefined) {
            throw new runtime.RequiredError('createCommentRequest','Required parameter requestParameters.createCommentRequest was null or undefined when calling createProcurementStageComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/companies/{company_guid}/procurement_stages/{procurement_stage_id}/comments`.replace(`{${"company_guid"}}`, encodeURIComponent(String(requestParameters.companyGuid))).replace(`{${"procurement_stage_id"}}`, encodeURIComponent(String(requestParameters.procurementStageId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCommentRequestToJSON(requestParameters.createCommentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentDtoFromJSON(jsonValue));
    }

    /**
     */
    async createProcurementStageComment(requestParameters: CreateProcurementStageCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommentDto> {
        const response = await this.createProcurementStageCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createProcurementStageQualificationRaw(requestParameters: CreateProcurementStageQualificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateQualificationResponse>> {
        if (requestParameters.createQualificationRequest === null || requestParameters.createQualificationRequest === undefined) {
            throw new runtime.RequiredError('createQualificationRequest','Required parameter requestParameters.createQualificationRequest was null or undefined when calling createProcurementStageQualification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/create_qualification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateQualificationRequestToJSON(requestParameters.createQualificationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateQualificationResponseFromJSON(jsonValue));
    }

    /**
     */
    async createProcurementStageQualification(requestParameters: CreateProcurementStageQualificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateQualificationResponse> {
        const response = await this.createProcurementStageQualificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createSavedViewRaw(requestParameters: CreateSavedViewOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateSavedViewResponse>> {
        if (requestParameters.createSavedViewRequest === null || requestParameters.createSavedViewRequest === undefined) {
            throw new runtime.RequiredError('createSavedViewRequest','Required parameter requestParameters.createSavedViewRequest was null or undefined when calling createSavedView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/saved_views/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSavedViewRequestToJSON(requestParameters.createSavedViewRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateSavedViewResponseFromJSON(jsonValue));
    }

    /**
     */
    async createSavedView(requestParameters: CreateSavedViewOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateSavedViewResponse> {
        const response = await this.createSavedViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteBuyerListRaw(requestParameters: DeleteBuyerListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBuyerList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/buyer_lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteBuyerList(requestParameters: DeleteBuyerListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteBuyerListRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteCommentRaw(requestParameters: DeleteCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/comments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteComment(requestParameters: DeleteCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommentDto> {
        const response = await this.deleteCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteDocumentRaw(requestParameters: DeleteDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling deleteDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/docs/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteDocument(requestParameters: DeleteDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDocumentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteLeadSubscriptionContentRaw(requestParameters: DeleteLeadSubscriptionContentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deleteLeadSubscriptionContentRequest === null || requestParameters.deleteLeadSubscriptionContentRequest === undefined) {
            throw new runtime.RequiredError('deleteLeadSubscriptionContentRequest','Required parameter requestParameters.deleteLeadSubscriptionContentRequest was null or undefined when calling deleteLeadSubscriptionContent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/lead_subscription_contents`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteLeadSubscriptionContentRequestToJSON(requestParameters.deleteLeadSubscriptionContentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteLeadSubscriptionContent(requestParameters: DeleteLeadSubscriptionContentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLeadSubscriptionContentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deletePinnedSavedViewRaw(requestParameters: DeletePinnedSavedViewOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deletePinnedSavedViewRequest === null || requestParameters.deletePinnedSavedViewRequest === undefined) {
            throw new runtime.RequiredError('deletePinnedSavedViewRequest','Required parameter requestParameters.deletePinnedSavedViewRequest was null or undefined when calling deletePinnedSavedView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pinned_saved_views/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeletePinnedSavedViewRequestToJSON(requestParameters.deletePinnedSavedViewRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePinnedSavedView(requestParameters: DeletePinnedSavedViewOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePinnedSavedViewRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteProcurementStageQualificationRaw(requestParameters: DeleteProcurementStageQualificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deleteQualificationRequest === null || requestParameters.deleteQualificationRequest === undefined) {
            throw new runtime.RequiredError('deleteQualificationRequest','Required parameter requestParameters.deleteQualificationRequest was null or undefined when calling deleteProcurementStageQualification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/delete_qualification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteQualificationRequestToJSON(requestParameters.deleteQualificationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProcurementStageQualification(requestParameters: DeleteProcurementStageQualificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProcurementStageQualificationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async describeRecordListRaw(requestParameters: DescribeRecordListOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DescribeRecordListResponse>> {
        if (requestParameters.describeRecordListRequest === null || requestParameters.describeRecordListRequest === undefined) {
            throw new runtime.RequiredError('describeRecordListRequest','Required parameter requestParameters.describeRecordListRequest was null or undefined when calling describeRecordList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/record_lists/describe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DescribeRecordListRequestToJSON(requestParameters.describeRecordListRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DescribeRecordListResponseFromJSON(jsonValue));
    }

    /**
     */
    async describeRecordList(requestParameters: DescribeRecordListOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DescribeRecordListResponse> {
        const response = await this.describeRecordListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async downgradeSubscriptionRaw(requestParameters: DowngradeSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.subscriptionDowngradeRequest === null || requestParameters.subscriptionDowngradeRequest === undefined) {
            throw new runtime.RequiredError('subscriptionDowngradeRequest','Required parameter requestParameters.subscriptionDowngradeRequest was null or undefined when calling downgradeSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/subscription/downgrade`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionDowngradeRequestToJSON(requestParameters.subscriptionDowngradeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async downgradeSubscription(requestParameters: DowngradeSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.downgradeSubscriptionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async editOrgCleaningTaskRaw(requestParameters: EditOrgCleaningTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling editOrgCleaningTask.');
        }

        if (requestParameters.submitEditOrgCleaningTaskRequest === null || requestParameters.submitEditOrgCleaningTaskRequest === undefined) {
            throw new runtime.RequiredError('submitEditOrgCleaningTaskRequest','Required parameter requestParameters.submitEditOrgCleaningTaskRequest was null or undefined when calling editOrgCleaningTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/organisation_cleaning_tasks/{id}/edit_task`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SubmitEditOrgCleaningTaskRequestToJSON(requestParameters.submitEditOrgCleaningTaskRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async editOrgCleaningTask(requestParameters: EditOrgCleaningTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.editOrgCleaningTaskRaw(requestParameters, initOverrides);
    }

    /**
     */
    async exportOutreachLeadsRaw(requestParameters: ExportOutreachLeadsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exportOutreachLeadsRequest === null || requestParameters.exportOutreachLeadsRequest === undefined) {
            throw new runtime.RequiredError('exportOutreachLeadsRequest','Required parameter requestParameters.exportOutreachLeadsRequest was null or undefined when calling exportOutreachLeads.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/outreach_lead/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportOutreachLeadsRequestToJSON(requestParameters.exportOutreachLeadsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async exportOutreachLeads(requestParameters: ExportOutreachLeadsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.exportOutreachLeadsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async exportSearchRecordsCsvRaw(requestParameters: ExportSearchRecordsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.recordSearchRequestDto === null || requestParameters.recordSearchRequestDto === undefined) {
            throw new runtime.RequiredError('recordSearchRequestDto','Required parameter requestParameters.recordSearchRequestDto was null or undefined when calling exportSearchRecordsCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/records/search.csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecordSearchRequestDtoToJSON(requestParameters.recordSearchRequestDto),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async exportSearchRecordsCsv(requestParameters: ExportSearchRecordsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportSearchRecordsCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportSearchRecordsXlsxRaw(requestParameters: ExportSearchRecordsXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.recordSearchRequestDto === null || requestParameters.recordSearchRequestDto === undefined) {
            throw new runtime.RequiredError('recordSearchRequestDto','Required parameter requestParameters.recordSearchRequestDto was null or undefined when calling exportSearchRecordsXlsx.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/records/search.xlsx`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecordSearchRequestDtoToJSON(requestParameters.recordSearchRequestDto),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async exportSearchRecordsXlsx(requestParameters: ExportSearchRecordsXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportSearchRecordsXlsxRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportSuppliersRaw(requestParameters: ExportSuppliersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.supplierExportRequest === null || requestParameters.supplierExportRequest === undefined) {
            throw new runtime.RequiredError('supplierExportRequest','Required parameter requestParameters.supplierExportRequest was null or undefined when calling exportSuppliers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/suppliers/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SupplierExportRequestToJSON(requestParameters.supplierExportRequest),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async exportSuppliers(requestParameters: ExportSuppliersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportSuppliersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchBuyerFiltersRaw(requestParameters: FetchBuyerFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AvailableBuyerFilters>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/buyers/facets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BuyerFilterRequestToJSON(requestParameters.buyerFilterRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AvailableBuyerFiltersFromJSON(jsonValue));
    }

    /**
     */
    async fetchBuyerFilters(requestParameters: FetchBuyerFiltersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AvailableBuyerFilters> {
        const response = await this.fetchBuyerFiltersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchBuyerSignalStatsRaw(requestParameters: FetchBuyerSignalStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BuyersRelationshipStatsResponse>> {
        if (requestParameters.buyersRelationshipStatsRequest === null || requestParameters.buyersRelationshipStatsRequest === undefined) {
            throw new runtime.RequiredError('buyersRelationshipStatsRequest','Required parameter requestParameters.buyersRelationshipStatsRequest was null or undefined when calling fetchBuyerSignalStats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/buyers/signal_stats`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BuyersRelationshipStatsRequestToJSON(requestParameters.buyersRelationshipStatsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BuyersRelationshipStatsResponseFromJSON(jsonValue));
    }

    /**
     */
    async fetchBuyerSignalStats(requestParameters: FetchBuyerSignalStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BuyersRelationshipStatsResponse> {
        const response = await this.fetchBuyerSignalStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchPartnersOrCompetitorsForBuyerRaw(requestParameters: FetchPartnersOrCompetitorsForBuyerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PartnersCompetitorsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchPartnersOrCompetitorsForBuyer.');
        }

        if (requestParameters.partnersCompetitorsRequest === null || requestParameters.partnersCompetitorsRequest === undefined) {
            throw new runtime.RequiredError('partnersCompetitorsRequest','Required parameter requestParameters.partnersCompetitorsRequest was null or undefined when calling fetchPartnersOrCompetitorsForBuyer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/buyers/{id}/partners_competitors`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PartnersCompetitorsRequestToJSON(requestParameters.partnersCompetitorsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PartnersCompetitorsResponseFromJSON(jsonValue));
    }

    /**
     */
    async fetchPartnersOrCompetitorsForBuyer(requestParameters: FetchPartnersOrCompetitorsForBuyerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PartnersCompetitorsResponse> {
        const response = await this.fetchPartnersOrCompetitorsForBuyerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchSuppliersForBuyerRaw(requestParameters: FetchSuppliersForBuyerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuppliersV3Response>> {
        if (requestParameters.buyerId === null || requestParameters.buyerId === undefined) {
            throw new runtime.RequiredError('buyerId','Required parameter requestParameters.buyerId was null or undefined when calling fetchSuppliersForBuyer.');
        }

        if (requestParameters.suppliersV3Request === null || requestParameters.suppliersV3Request === undefined) {
            throw new runtime.RequiredError('suppliersV3Request','Required parameter requestParameters.suppliersV3Request was null or undefined when calling fetchSuppliersForBuyer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/buyers/{buyer_id}/suppliers_v3`.replace(`{${"buyer_id"}}`, encodeURIComponent(String(requestParameters.buyerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SuppliersV3RequestToJSON(requestParameters.suppliersV3Request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuppliersV3ResponseFromJSON(jsonValue));
    }

    /**
     */
    async fetchSuppliersForBuyer(requestParameters: FetchSuppliersForBuyerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuppliersV3Response> {
        const response = await this.fetchSuppliersForBuyerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async generateOutreachRaw(requestParameters: GenerateOutreachOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenerateOutreachResponse>> {
        if (requestParameters.generateOutreachRequest === null || requestParameters.generateOutreachRequest === undefined) {
            throw new runtime.RequiredError('generateOutreachRequest','Required parameter requestParameters.generateOutreachRequest was null or undefined when calling generateOutreach.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/outreach_lead/generate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GenerateOutreachRequestToJSON(requestParameters.generateOutreachRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenerateOutreachResponseFromJSON(jsonValue));
    }

    /**
     */
    async generateOutreach(requestParameters: GenerateOutreachOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenerateOutreachResponse> {
        const response = await this.generateOutreachRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAppApiTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAppApiTokenResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/app_api_token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAppApiTokenResponseFromJSON(jsonValue));
    }

    /**
     */
    async getAppApiToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAppApiTokenResponse> {
        const response = await this.getAppApiTokenRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getAssignedNoticesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAssignedNoticesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notices/assigned`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAssignedNoticesResponseFromJSON(jsonValue));
    }

    /**
     */
    async getAssignedNotices(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAssignedNoticesResponse> {
        const response = await this.getAssignedNoticesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getBuyerListRaw(requestParameters: GetBuyerListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BuyerListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBuyerList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/buyer_lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BuyerListDtoFromJSON(jsonValue));
    }

    /**
     */
    async getBuyerList(requestParameters: GetBuyerListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BuyerListDto> {
        const response = await this.getBuyerListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBuyerListPermissionsRaw(requestParameters: GetBuyerListPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBuyerListPermissionsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBuyerListPermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/buyer_lists/{id}/permissions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBuyerListPermissionsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getBuyerListPermissions(requestParameters: GetBuyerListPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBuyerListPermissionsResponse> {
        const response = await this.getBuyerListPermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBuyerListsRaw(requestParameters: GetBuyerListsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBuyerListsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.permission !== undefined) {
            queryParameters['permission'] = requestParameters.permission;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/buyer_lists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBuyerListsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getBuyerLists(requestParameters: GetBuyerListsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBuyerListsResponse> {
        const response = await this.getBuyerListsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBuyerStatsSupplierSignalRaw(requestParameters: GetBuyerStatsSupplierSignalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplierSignalBuyerResponse>> {
        if (requestParameters.supplierSignalBuyersRequest === null || requestParameters.supplierSignalBuyersRequest === undefined) {
            throw new runtime.RequiredError('supplierSignalBuyersRequest','Required parameter requestParameters.supplierSignalBuyersRequest was null or undefined when calling getBuyerStatsSupplierSignal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company_signals/buyer_stats_supplier_signal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SupplierSignalBuyersRequestToJSON(requestParameters.supplierSignalBuyersRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplierSignalBuyerResponseFromJSON(jsonValue));
    }

    /**
     */
    async getBuyerStatsSupplierSignal(requestParameters: GetBuyerStatsSupplierSignalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplierSignalBuyerResponse> {
        const response = await this.getBuyerStatsSupplierSignalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBuyersBySupplierRaw(requestParameters: GetBuyersBySupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BuyersBySupplierResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBuyersBySupplier.');
        }

        if (requestParameters.buyersBySupplierRequest === null || requestParameters.buyersBySupplierRequest === undefined) {
            throw new runtime.RequiredError('buyersBySupplierRequest','Required parameter requestParameters.buyersBySupplierRequest was null or undefined when calling getBuyersBySupplier.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/suppliers/{id}/buyers`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BuyersBySupplierRequestToJSON(requestParameters.buyersBySupplierRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BuyersBySupplierResponseFromJSON(jsonValue));
    }

    /**
     */
    async getBuyersBySupplier(requestParameters: GetBuyersBySupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BuyersBySupplierResponse> {
        const response = await this.getBuyersBySupplierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCompanyBuyerListsRaw(requestParameters: GetCompanyBuyerListsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBuyerListsResponse>> {
        if (requestParameters.companyGuid === null || requestParameters.companyGuid === undefined) {
            throw new runtime.RequiredError('companyGuid','Required parameter requestParameters.companyGuid was null or undefined when calling getCompanyBuyerLists.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/companies/{company_guid}/buyer_lists`.replace(`{${"company_guid"}}`, encodeURIComponent(String(requestParameters.companyGuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBuyerListsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getCompanyBuyerLists(requestParameters: GetCompanyBuyerListsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBuyerListsResponse> {
        const response = await this.getCompanyBuyerListsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLeadSubscriptionContentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionContentsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lead_subscription_contents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionContentsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getLeadSubscriptionContents(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionContentsResponse> {
        const response = await this.getLeadSubscriptionContentsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getLeadSubscriptionStatusRaw(requestParameters: GetLeadSubscriptionStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLeadSubscriptionsStatusResponse>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling getLeadSubscriptionStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lead_subscriptions/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLeadSubscriptionsStatusResponseFromJSON(jsonValue));
    }

    /**
     */
    async getLeadSubscriptionStatus(requestParameters: GetLeadSubscriptionStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLeadSubscriptionsStatusResponse> {
        const response = await this.getLeadSubscriptionStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLeadSubscriptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLeadSubscriptionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lead_subscriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLeadSubscriptionsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getLeadSubscriptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLeadSubscriptionsResponse> {
        const response = await this.getLeadSubscriptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getNotificationSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetNotificationSettingsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notification_settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNotificationSettingsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getNotificationSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetNotificationSettingsResponse> {
        const response = await this.getNotificationSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getNotificationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationsIndexResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationsIndexResponseFromJSON(jsonValue));
    }

    /**
     */
    async getNotifications(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationsIndexResponse> {
        const response = await this.getNotificationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getOnboardingTemplateRaw(requestParameters: GetOnboardingTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OnboardingTemplateDto>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling getOnboardingTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/onboarding_templates/{template_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingTemplateDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOnboardingTemplate(requestParameters: GetOnboardingTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OnboardingTemplateDto> {
        const response = await this.getOnboardingTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOnboardingTemplatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetIndustryTemplatesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/onboarding_templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetIndustryTemplatesResponseFromJSON(jsonValue));
    }

    /**
     */
    async getOnboardingTemplates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetIndustryTemplatesResponse> {
        const response = await this.getOnboardingTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getProcurementStageCommentsRaw(requestParameters: GetProcurementStageCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProcurementStageCommentsResponse>> {
        if (requestParameters.companyGuid === null || requestParameters.companyGuid === undefined) {
            throw new runtime.RequiredError('companyGuid','Required parameter requestParameters.companyGuid was null or undefined when calling getProcurementStageComments.');
        }

        if (requestParameters.procurementStageId === null || requestParameters.procurementStageId === undefined) {
            throw new runtime.RequiredError('procurementStageId','Required parameter requestParameters.procurementStageId was null or undefined when calling getProcurementStageComments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/companies/{company_guid}/procurement_stages/{procurement_stage_id}/comments`.replace(`{${"company_guid"}}`, encodeURIComponent(String(requestParameters.companyGuid))).replace(`{${"procurement_stage_id"}}`, encodeURIComponent(String(requestParameters.procurementStageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProcurementStageCommentsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getProcurementStageComments(requestParameters: GetProcurementStageCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProcurementStageCommentsResponse> {
        const response = await this.getProcurementStageCommentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProductTourStatesRaw(requestParameters: GetProductTourStatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductTourCompletionStateResponse>> {
        if (requestParameters.indexRequest === null || requestParameters.indexRequest === undefined) {
            throw new runtime.RequiredError('indexRequest','Required parameter requestParameters.indexRequest was null or undefined when calling getProductTourStates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user_product_tours/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IndexRequestToJSON(requestParameters.indexRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductTourCompletionStateResponseFromJSON(jsonValue));
    }

    /**
     */
    async getProductTourStates(requestParameters: GetProductTourStatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductTourCompletionStateResponse> {
        const response = await this.getProductTourStatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPublicReportsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPublicReportsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/public_reports/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPublicReportsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getPublicReports(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPublicReportsResponse> {
        const response = await this.getPublicReportsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getQualificationStagesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetQualificationStageResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/qualification_stages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetQualificationStageResponseFromJSON(jsonValue));
    }

    /**
     */
    async getQualificationStages(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetQualificationStageResponse> {
        const response = await this.getQualificationStagesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getQualifiedNoticesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetQualifiedNoticesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notices/qualified`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetQualifiedNoticesResponseFromJSON(jsonValue));
    }

    /**
     */
    async getQualifiedNotices(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetQualifiedNoticesResponse> {
        const response = await this.getQualifiedNoticesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getRecordListsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetRecordListsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/record_lists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRecordListsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getRecordLists(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetRecordListsResponse> {
        const response = await this.getRecordListsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getRecordSummaryDetailsRaw(requestParameters: GetRecordSummaryDetailsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetRecordSummaryDetailsResponse>> {
        if (requestParameters.getRecordSummaryDetailsRequest === null || requestParameters.getRecordSummaryDetailsRequest === undefined) {
            throw new runtime.RequiredError('getRecordSummaryDetailsRequest','Required parameter requestParameters.getRecordSummaryDetailsRequest was null or undefined when calling getRecordSummaryDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/record_summary/details`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetRecordSummaryDetailsRequestToJSON(requestParameters.getRecordSummaryDetailsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRecordSummaryDetailsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getRecordSummaryDetails(requestParameters: GetRecordSummaryDetailsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetRecordSummaryDetailsResponse> {
        const response = await this.getRecordSummaryDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReportsRaw(requestParameters: GetReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetReportsResponse>> {
        if (requestParameters.companyGuid === null || requestParameters.companyGuid === undefined) {
            throw new runtime.RequiredError('companyGuid','Required parameter requestParameters.companyGuid was null or undefined when calling getReports.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/companies/{company_guid}/reports`.replace(`{${"company_guid"}}`, encodeURIComponent(String(requestParameters.companyGuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetReportsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getReports(requestParameters: GetReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetReportsResponse> {
        const response = await this.getReportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSelectedStandardViewIdsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSelectedStandardViewIdsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/saved_views/selected_standard_view_ids`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSelectedStandardViewIdsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getSelectedStandardViewIds(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSelectedStandardViewIdsResponse> {
        const response = await this.getSelectedStandardViewIdsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getStoredRecordSummaryRaw(requestParameters: GetStoredRecordSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetRecordSummaryDetailsResponse>> {
        if (requestParameters.getRecordSummaryDetailsRequest === null || requestParameters.getRecordSummaryDetailsRequest === undefined) {
            throw new runtime.RequiredError('getRecordSummaryDetailsRequest','Required parameter requestParameters.getRecordSummaryDetailsRequest was null or undefined when calling getStoredRecordSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/record_summary/stored`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetRecordSummaryDetailsRequestToJSON(requestParameters.getRecordSummaryDetailsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRecordSummaryDetailsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getStoredRecordSummary(requestParameters: GetStoredRecordSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetRecordSummaryDetailsResponse> {
        const response = await this.getStoredRecordSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSupplierRaw(requestParameters: GetSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplierSummaryDto>> {
        if (requestParameters.guid === null || requestParameters.guid === undefined) {
            throw new runtime.RequiredError('guid','Required parameter requestParameters.guid was null or undefined when calling getSupplier.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/suppliers/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(requestParameters.guid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplierSummaryDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSupplier(requestParameters: GetSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplierSummaryDto> {
        const response = await this.getSupplierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSupplierSignalStatsRaw(requestParameters: GetSupplierSignalStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplierSignalStatsResponse>> {
        if (requestParameters.supplierSignalStatsRequest === null || requestParameters.supplierSignalStatsRequest === undefined) {
            throw new runtime.RequiredError('supplierSignalStatsRequest','Required parameter requestParameters.supplierSignalStatsRequest was null or undefined when calling getSupplierSignalStats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company_signals/supplier_signal_stats`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SupplierSignalStatsRequestToJSON(requestParameters.supplierSignalStatsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplierSignalStatsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getSupplierSignalStats(requestParameters: GetSupplierSignalStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplierSignalStatsResponse> {
        const response = await this.getSupplierSignalStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSupplierStatsListRaw(requestParameters: GetSupplierStatsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplierListSummaryResponse>> {
        if (requestParameters.category === null || requestParameters.category === undefined) {
            throw new runtime.RequiredError('category','Required parameter requestParameters.category was null or undefined when calling getSupplierStatsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company_signals/supplier_stats_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplierListSummaryResponseFromJSON(jsonValue));
    }

    /**
     */
    async getSupplierStatsList(requestParameters: GetSupplierStatsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplierListSummaryResponse> {
        const response = await this.getSupplierStatsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTeamSignalSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSignalSettingsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/team/signal_settings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSignalSettingsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getTeamSignalSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSignalSettingsResponse> {
        const response = await this.getTeamSignalSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getTeamsForCompanyRaw(requestParameters: GetTeamsForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AvailableTeamResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTeamsForCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/companies/{id}/teams`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AvailableTeamResponseFromJSON(jsonValue));
    }

    /**
     */
    async getTeamsForCompany(requestParameters: GetTeamsForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AvailableTeamResponse> {
        const response = await this.getTeamsForCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUsersRaw(requestParameters: GetUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUsersResponse>> {
        if (requestParameters.companyGuid === null || requestParameters.companyGuid === undefined) {
            throw new runtime.RequiredError('companyGuid','Required parameter requestParameters.companyGuid was null or undefined when calling getUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/companies/{company_guid}/users`.replace(`{${"company_guid"}}`, encodeURIComponent(String(requestParameters.companyGuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsersResponseFromJSON(jsonValue));
    }

    /**
     */
    async getUsers(requestParameters: GetUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUsersResponse> {
        const response = await this.getUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listPendingUploadsRaw(requestParameters: ListPendingUploadsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling listPendingUploads.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/docs/list-pending-uploads`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async listPendingUploads(requestParameters: ListPendingUploadsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.listPendingUploadsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listPinnedSavedViewsRaw(requestParameters: ListPinnedSavedViewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListPinnedSavedViewsResponse>> {
        if (requestParameters.listPinnedSavedViewRequest === null || requestParameters.listPinnedSavedViewRequest === undefined) {
            throw new runtime.RequiredError('listPinnedSavedViewRequest','Required parameter requestParameters.listPinnedSavedViewRequest was null or undefined when calling listPinnedSavedViews.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pinned_saved_views/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListPinnedSavedViewRequestToJSON(requestParameters.listPinnedSavedViewRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListPinnedSavedViewsResponseFromJSON(jsonValue));
    }

    /**
     */
    async listPinnedSavedViews(requestParameters: ListPinnedSavedViewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListPinnedSavedViewsResponse> {
        const response = await this.listPinnedSavedViewsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listSavedViewsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListNewSavedViewsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/new_saved_views/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListNewSavedViewsResponseFromJSON(jsonValue));
    }

    /**
     */
    async listSavedViews(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListNewSavedViewsResponse> {
        const response = await this.listSavedViewsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async markNotificationsAsReadRaw(requestParameters: MarkNotificationsAsReadOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.markNotificationsAsReadRequest === null || requestParameters.markNotificationsAsReadRequest === undefined) {
            throw new runtime.RequiredError('markNotificationsAsReadRequest','Required parameter requestParameters.markNotificationsAsReadRequest was null or undefined when calling markNotificationsAsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/notifications/mark_as_read`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MarkNotificationsAsReadRequestToJSON(requestParameters.markNotificationsAsReadRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markNotificationsAsRead(requestParameters: MarkNotificationsAsReadOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markNotificationsAsReadRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markNotificationsAsUnreadRaw(requestParameters: MarkNotificationsAsUnreadOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.markNotificationsAsUnreadRequest === null || requestParameters.markNotificationsAsUnreadRequest === undefined) {
            throw new runtime.RequiredError('markNotificationsAsUnreadRequest','Required parameter requestParameters.markNotificationsAsUnreadRequest was null or undefined when calling markNotificationsAsUnread.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/notifications/mark_as_unread`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MarkNotificationsAsUnreadRequestToJSON(requestParameters.markNotificationsAsUnreadRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markNotificationsAsUnread(requestParameters: MarkNotificationsAsUnreadOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markNotificationsAsUnreadRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markProductTourAsCompleteRaw(requestParameters: MarkProductTourAsCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductTourCompletionStateResponse>> {
        if (requestParameters.markAsCompleteRequest === null || requestParameters.markAsCompleteRequest === undefined) {
            throw new runtime.RequiredError('markAsCompleteRequest','Required parameter requestParameters.markAsCompleteRequest was null or undefined when calling markProductTourAsComplete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user_product_tours/mark_as_complete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MarkAsCompleteRequestToJSON(requestParameters.markAsCompleteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductTourCompletionStateResponseFromJSON(jsonValue));
    }

    /**
     */
    async markProductTourAsComplete(requestParameters: MarkProductTourAsCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductTourCompletionStateResponse> {
        const response = await this.markProductTourAsCompleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async markRecordAsSeenRaw(requestParameters: MarkRecordAsSeenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.recordGuid === null || requestParameters.recordGuid === undefined) {
            throw new runtime.RequiredError('recordGuid','Required parameter requestParameters.recordGuid was null or undefined when calling markRecordAsSeen.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling markRecordAsSeen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/{user_id}/seen_records/{record_guid}`.replace(`{${"record_guid"}}`, encodeURIComponent(String(requestParameters.recordGuid))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markRecordAsSeen(requestParameters: MarkRecordAsSeenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markRecordAsSeenRaw(requestParameters, initOverrides);
    }

    /**
     */
    async pqlActionedRaw(requestParameters: PqlActionedOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.pqlActionedRequest === null || requestParameters.pqlActionedRequest === undefined) {
            throw new runtime.RequiredError('pqlActionedRequest','Required parameter requestParameters.pqlActionedRequest was null or undefined when calling pqlActioned.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users/pql_actioned`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PqlActionedRequestToJSON(requestParameters.pqlActionedRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pqlActioned(requestParameters: PqlActionedOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.pqlActionedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async previewCallOffsRaw(requestParameters: PreviewCallOffsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling previewCallOffs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/preview-call-offs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async previewCallOffs(requestParameters: PreviewCallOffsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.previewCallOffsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewCallOffsDocumentSignalsRaw(requestParameters: PreviewCallOffsDocumentSignalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling previewCallOffsDocumentSignals.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/preview-call-offs-document-signals`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async previewCallOffsDocumentSignals(requestParameters: PreviewCallOffsDocumentSignalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.previewCallOffsDocumentSignalsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewCallOffsNoticeSignalsRaw(requestParameters: PreviewCallOffsNoticeSignalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling previewCallOffsNoticeSignals.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/preview-call-offs-notice-signals`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async previewCallOffsNoticeSignals(requestParameters: PreviewCallOffsNoticeSignalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.previewCallOffsNoticeSignalsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productFeedbackRaw(requestParameters: ProductFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.feedbackRequest === null || requestParameters.feedbackRequest === undefined) {
            throw new runtime.RequiredError('feedbackRequest','Required parameter requestParameters.feedbackRequest was null or undefined when calling productFeedback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user_product_tours/feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeedbackRequestToJSON(requestParameters.feedbackRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productFeedback(requestParameters: ProductFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.productFeedbackRaw(requestParameters, initOverrides);
    }

    /**
     */
    async recordTimelineRaw(requestParameters: RecordTimelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecordTimelineDto>> {
        if (requestParameters.recordFamilyId === null || requestParameters.recordFamilyId === undefined) {
            throw new runtime.RequiredError('recordFamilyId','Required parameter requestParameters.recordFamilyId was null or undefined when calling recordTimeline.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/records/{record_family_id}/timeline`.replace(`{${"record_family_id"}}`, encodeURIComponent(String(requestParameters.recordFamilyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordTimelineDtoFromJSON(jsonValue));
    }

    /**
     */
    async recordTimeline(requestParameters: RecordTimelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecordTimelineDto> {
        const response = await this.recordTimelineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeBuyersFromListRaw(requestParameters: RemoveBuyersFromListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeBuyersFromList.');
        }

        if (requestParameters.removeBuyersRequest === null || requestParameters.removeBuyersRequest === undefined) {
            throw new runtime.RequiredError('removeBuyersRequest','Required parameter requestParameters.removeBuyersRequest was null or undefined when calling removeBuyersFromList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/buyer_lists/{id}/remove`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveBuyersRequestToJSON(requestParameters.removeBuyersRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeBuyersFromList(requestParameters: RemoveBuyersFromListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeBuyersFromListRaw(requestParameters, initOverrides);
    }

    /**
     */
    async requestOutreachContactsRaw(requestParameters: RequestOutreachContactsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestOutreachContactsRequest === null || requestParameters.requestOutreachContactsRequest === undefined) {
            throw new runtime.RequiredError('requestOutreachContactsRequest','Required parameter requestParameters.requestOutreachContactsRequest was null or undefined when calling requestOutreachContacts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/outreach_lead/request_contacts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestOutreachContactsRequestToJSON(requestParameters.requestOutreachContactsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async requestOutreachContacts(requestParameters: RequestOutreachContactsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestOutreachContactsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async requestSpendRaw(requestParameters: RequestSpendRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.guid === null || requestParameters.guid === undefined) {
            throw new runtime.RequiredError('guid','Required parameter requestParameters.guid was null or undefined when calling requestSpend.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/buyers/{guid}/request_spend`.replace(`{${"guid"}}`, encodeURIComponent(String(requestParameters.guid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async requestSpend(requestParameters: RequestSpendRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestSpendRaw(requestParameters, initOverrides);
    }

    /**
     */
    async searchFrameworkOrganisationsRaw(requestParameters: SearchFrameworkOrganisationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling searchFrameworkOrganisations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/framework-organisations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async searchFrameworkOrganisations(requestParameters: SearchFrameworkOrganisationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.searchFrameworkOrganisationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchRecordsRaw(requestParameters: SearchRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecordSearchResponseDto>> {
        if (requestParameters.recordSearchRequestDto === null || requestParameters.recordSearchRequestDto === undefined) {
            throw new runtime.RequiredError('recordSearchRequestDto','Required parameter requestParameters.recordSearchRequestDto was null or undefined when calling searchRecords.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/records/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecordSearchRequestDtoToJSON(requestParameters.recordSearchRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordSearchResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async searchRecords(requestParameters: SearchRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecordSearchResponseDto> {
        const response = await this.searchRecordsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchReferenceOrgsRaw(requestParameters: SearchReferenceOrgsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchReferenceOrgsResponse>> {
        if (requestParameters.searchReferenceOrgsRequest === null || requestParameters.searchReferenceOrgsRequest === undefined) {
            throw new runtime.RequiredError('searchReferenceOrgsRequest','Required parameter requestParameters.searchReferenceOrgsRequest was null or undefined when calling searchReferenceOrgs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/reference_organisations/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchReferenceOrgsRequestToJSON(requestParameters.searchReferenceOrgsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchReferenceOrgsResponseFromJSON(jsonValue));
    }

    /**
     */
    async searchReferenceOrgs(requestParameters: SearchReferenceOrgsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchReferenceOrgsResponse> {
        const response = await this.searchReferenceOrgsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchRegionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchRegionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/regions/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchRegionsResponseFromJSON(jsonValue));
    }

    /**
     */
    async searchRegions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchRegionsResponse> {
        const response = await this.searchRegionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async searchSuppliersRaw(requestParameters: SearchSuppliersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplierSearchResponse>> {
        if (requestParameters.supplierSearchRequest === null || requestParameters.supplierSearchRequest === undefined) {
            throw new runtime.RequiredError('supplierSearchRequest','Required parameter requestParameters.supplierSearchRequest was null or undefined when calling searchSuppliers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/suppliers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SupplierSearchRequestToJSON(requestParameters.supplierSearchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplierSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async searchSuppliers(requestParameters: SearchSuppliersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplierSearchResponse> {
        const response = await this.searchSuppliersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sendVerificationEmailRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SendVerificationEmailResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/auth/send-verification-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendVerificationEmailResponseFromJSON(jsonValue));
    }

    /**
     */
    async sendVerificationEmail(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SendVerificationEmailResponse> {
        const response = await this.sendVerificationEmailRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async submitAndProcessOrgCleaningTaskRaw(requestParameters: SubmitAndProcessOrgCleaningTaskOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.submitAndProcessOrgCleaningTaskRequest === null || requestParameters.submitAndProcessOrgCleaningTaskRequest === undefined) {
            throw new runtime.RequiredError('submitAndProcessOrgCleaningTaskRequest','Required parameter requestParameters.submitAndProcessOrgCleaningTaskRequest was null or undefined when calling submitAndProcessOrgCleaningTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/organisation_cleaning_tasks/submit_and_perform_task`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubmitAndProcessOrgCleaningTaskRequestToJSON(requestParameters.submitAndProcessOrgCleaningTaskRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async submitAndProcessOrgCleaningTask(requestParameters: SubmitAndProcessOrgCleaningTaskOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.submitAndProcessOrgCleaningTaskRaw(requestParameters, initOverrides);
    }

    /**
     */
    async submitOrgCleaningTaskRaw(requestParameters: SubmitOrgCleaningTaskOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.submitOrgCleaningTaskRequest === null || requestParameters.submitOrgCleaningTaskRequest === undefined) {
            throw new runtime.RequiredError('submitOrgCleaningTaskRequest','Required parameter requestParameters.submitOrgCleaningTaskRequest was null or undefined when calling submitOrgCleaningTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/organisation_cleaning_tasks/submit_task`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubmitOrgCleaningTaskRequestToJSON(requestParameters.submitOrgCleaningTaskRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async submitOrgCleaningTask(requestParameters: SubmitOrgCleaningTaskOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.submitOrgCleaningTaskRaw(requestParameters, initOverrides);
    }

    /**
     */
    async supplierStatsRaw(requestParameters: SupplierStatsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplierStatsResponse>> {
        if (requestParameters.supplierStatsRequest === null || requestParameters.supplierStatsRequest === undefined) {
            throw new runtime.RequiredError('supplierStatsRequest','Required parameter requestParameters.supplierStatsRequest was null or undefined when calling supplierStats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/suppliers/supplier_stats`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SupplierStatsRequestToJSON(requestParameters.supplierStatsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplierStatsResponseFromJSON(jsonValue));
    }

    /**
     */
    async supplierStats(requestParameters: SupplierStatsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplierStatsResponse> {
        const response = await this.supplierStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async switchUserTeamRaw(requestParameters: SwitchUserTeamOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.switchUserTeamRequest === null || requestParameters.switchUserTeamRequest === undefined) {
            throw new runtime.RequiredError('switchUserTeamRequest','Required parameter requestParameters.switchUserTeamRequest was null or undefined when calling switchUserTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users/switch_team`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwitchUserTeamRequestToJSON(requestParameters.switchUserTeamRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async switchUserTeam(requestParameters: SwitchUserTeamOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.switchUserTeamRaw(requestParameters, initOverrides);
    }

    /**
     */
    async teamManagementUsersRaw(requestParameters: TeamManagementUsersOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamManagementUsersResponse>> {
        if (requestParameters.teamManagementUsersRequest === null || requestParameters.teamManagementUsersRequest === undefined) {
            throw new runtime.RequiredError('teamManagementUsersRequest','Required parameter requestParameters.teamManagementUsersRequest was null or undefined when calling teamManagementUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/team_management_users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamManagementUsersRequestToJSON(requestParameters.teamManagementUsersRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamManagementUsersResponseFromJSON(jsonValue));
    }

    /**
     */
    async teamManagementUsers(requestParameters: TeamManagementUsersOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamManagementUsersResponse> {
        const response = await this.teamManagementUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async unsubscribeRaw(requestParameters: UnsubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling unsubscribe.');
        }

        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.listUnsubscribe !== undefined) {
            formParams.append('List-Unsubscribe', requestParameters.listUnsubscribe as any);
        }

        const response = await this.request({
            path: `/api/unsubscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async unsubscribe(requestParameters: UnsubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unsubscribeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateBuyerListRaw(requestParameters: UpdateBuyerListOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BuyerListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBuyerList.');
        }

        if (requestParameters.updateBuyerListRequest === null || requestParameters.updateBuyerListRequest === undefined) {
            throw new runtime.RequiredError('updateBuyerListRequest','Required parameter requestParameters.updateBuyerListRequest was null or undefined when calling updateBuyerList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/buyer_lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBuyerListRequestToJSON(requestParameters.updateBuyerListRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BuyerListDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateBuyerList(requestParameters: UpdateBuyerListOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BuyerListDto> {
        const response = await this.updateBuyerListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateBuyerListPermissionsRaw(requestParameters: UpdateBuyerListPermissionsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBuyerListPermissions.');
        }

        if (requestParameters.updateBuyerListPermissionsRequest === null || requestParameters.updateBuyerListPermissionsRequest === undefined) {
            throw new runtime.RequiredError('updateBuyerListPermissionsRequest','Required parameter requestParameters.updateBuyerListPermissionsRequest was null or undefined when calling updateBuyerListPermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/buyer_lists/{id}/permissions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBuyerListPermissionsRequestToJSON(requestParameters.updateBuyerListPermissionsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateBuyerListPermissions(requestParameters: UpdateBuyerListPermissionsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateBuyerListPermissionsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateCommentRaw(requestParameters: UpdateCommentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateComment.');
        }

        if (requestParameters.updateCommentRequest === null || requestParameters.updateCommentRequest === undefined) {
            throw new runtime.RequiredError('updateCommentRequest','Required parameter requestParameters.updateCommentRequest was null or undefined when calling updateComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/comments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCommentRequestToJSON(requestParameters.updateCommentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateComment(requestParameters: UpdateCommentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommentDto> {
        const response = await this.updateCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateDocumentRaw(requestParameters: UpdateDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/docs/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateDocument(requestParameters: UpdateDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDocumentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateIndustryRaw(requestParameters: UpdateIndustryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyGuid === null || requestParameters.companyGuid === undefined) {
            throw new runtime.RequiredError('companyGuid','Required parameter requestParameters.companyGuid was null or undefined when calling updateIndustry.');
        }

        if (requestParameters.updateIndustryRequest === null || requestParameters.updateIndustryRequest === undefined) {
            throw new runtime.RequiredError('updateIndustryRequest','Required parameter requestParameters.updateIndustryRequest was null or undefined when calling updateIndustry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/companies/{company_guid}/industry`.replace(`{${"company_guid"}}`, encodeURIComponent(String(requestParameters.companyGuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateIndustryRequestToJSON(requestParameters.updateIndustryRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateIndustry(requestParameters: UpdateIndustryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateIndustryRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateLeadAssigneeRaw(requestParameters: UpdateLeadAssigneeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyGuid === null || requestParameters.companyGuid === undefined) {
            throw new runtime.RequiredError('companyGuid','Required parameter requestParameters.companyGuid was null or undefined when calling updateLeadAssignee.');
        }

        if (requestParameters.procurementStageId === null || requestParameters.procurementStageId === undefined) {
            throw new runtime.RequiredError('procurementStageId','Required parameter requestParameters.procurementStageId was null or undefined when calling updateLeadAssignee.');
        }

        if (requestParameters.updateLeadAssigneeRequest === null || requestParameters.updateLeadAssigneeRequest === undefined) {
            throw new runtime.RequiredError('updateLeadAssigneeRequest','Required parameter requestParameters.updateLeadAssigneeRequest was null or undefined when calling updateLeadAssignee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/companies/{company_guid}/procurement_stages/{procurement_stage_id}/assignee`.replace(`{${"company_guid"}}`, encodeURIComponent(String(requestParameters.companyGuid))).replace(`{${"procurement_stage_id"}}`, encodeURIComponent(String(requestParameters.procurementStageId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLeadAssigneeRequestToJSON(requestParameters.updateLeadAssigneeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateLeadAssignee(requestParameters: UpdateLeadAssigneeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateLeadAssigneeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateLeadSubscriptionRaw(requestParameters: UpdateLeadSubscriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateLeadSubscription.');
        }

        if (requestParameters.updateLeadSubscriptionRequest === null || requestParameters.updateLeadSubscriptionRequest === undefined) {
            throw new runtime.RequiredError('updateLeadSubscriptionRequest','Required parameter requestParameters.updateLeadSubscriptionRequest was null or undefined when calling updateLeadSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/lead_subscriptions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLeadSubscriptionRequestToJSON(requestParameters.updateLeadSubscriptionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateLeadSubscription(requestParameters: UpdateLeadSubscriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateLeadSubscriptionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateLeadSubscriptionContentRaw(requestParameters: UpdateLeadSubscriptionContentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateLeadSubscriptionContent.');
        }

        if (requestParameters.updateLeadSubscriptionContentRequest === null || requestParameters.updateLeadSubscriptionContentRequest === undefined) {
            throw new runtime.RequiredError('updateLeadSubscriptionContentRequest','Required parameter requestParameters.updateLeadSubscriptionContentRequest was null or undefined when calling updateLeadSubscriptionContent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/lead_subscription_contents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLeadSubscriptionContentRequestToJSON(requestParameters.updateLeadSubscriptionContentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateLeadSubscriptionContent(requestParameters: UpdateLeadSubscriptionContentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateLeadSubscriptionContentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateNotificationSettingsRaw(requestParameters: UpdateNotificationSettingsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetNotificationSettingsResponse>> {
        if (requestParameters.updateNotificationSettingsRequest === null || requestParameters.updateNotificationSettingsRequest === undefined) {
            throw new runtime.RequiredError('updateNotificationSettingsRequest','Required parameter requestParameters.updateNotificationSettingsRequest was null or undefined when calling updateNotificationSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/notification_settings`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNotificationSettingsRequestToJSON(requestParameters.updateNotificationSettingsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNotificationSettingsResponseFromJSON(jsonValue));
    }

    /**
     */
    async updateNotificationSettings(requestParameters: UpdateNotificationSettingsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetNotificationSettingsResponse> {
        const response = await this.updateNotificationSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrgCleaningDecisionLogsRaw(requestParameters: UpdateOrgCleaningDecisionLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.submitOrgDecisionLogRequest === null || requestParameters.submitOrgDecisionLogRequest === undefined) {
            throw new runtime.RequiredError('submitOrgDecisionLogRequest','Required parameter requestParameters.submitOrgDecisionLogRequest was null or undefined when calling updateOrgCleaningDecisionLogs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/organisation_cleaning_decision_logs`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SubmitOrgDecisionLogRequestToJSON(requestParameters.submitOrgDecisionLogRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateOrgCleaningDecisionLogs(requestParameters: UpdateOrgCleaningDecisionLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateOrgCleaningDecisionLogsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updatePinnedSavedViewRaw(requestParameters: UpdatePinnedSavedViewOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updatePinnedSavedViewRequest === null || requestParameters.updatePinnedSavedViewRequest === undefined) {
            throw new runtime.RequiredError('updatePinnedSavedViewRequest','Required parameter requestParameters.updatePinnedSavedViewRequest was null or undefined when calling updatePinnedSavedView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pinned_saved_views/update_rank`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePinnedSavedViewRequestToJSON(requestParameters.updatePinnedSavedViewRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updatePinnedSavedView(requestParameters: UpdatePinnedSavedViewOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePinnedSavedViewRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updatePubSecExperienceRaw(requestParameters: UpdatePubSecExperienceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updatePubSecExperienceRequest === null || requestParameters.updatePubSecExperienceRequest === undefined) {
            throw new runtime.RequiredError('updatePubSecExperienceRequest','Required parameter requestParameters.updatePubSecExperienceRequest was null or undefined when calling updatePubSecExperience.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users/update_pub_sec_experience`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePubSecExperienceRequestToJSON(requestParameters.updatePubSecExperienceRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updatePubSecExperience(requestParameters: UpdatePubSecExperienceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePubSecExperienceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateSavedViewRaw(requestParameters: UpdateSavedViewOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSavedView.');
        }

        if (requestParameters.updateSavedViewRequest === null || requestParameters.updateSavedViewRequest === undefined) {
            throw new runtime.RequiredError('updateSavedViewRequest','Required parameter requestParameters.updateSavedViewRequest was null or undefined when calling updateSavedView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/saved_views/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSavedViewRequestToJSON(requestParameters.updateSavedViewRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateSavedView(requestParameters: UpdateSavedViewOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateSavedViewRaw(requestParameters, initOverrides);
    }

    /**
     */
    async uploadFileDocumentRaw(requestParameters: UploadFileDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/docs/file-upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async uploadFileDocument(requestParameters: UploadFileDocumentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.uploadFileDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uploadUrlDocumentRaw(requestParameters: UploadUrlDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling uploadUrlDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/docs/url-upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async uploadUrlDocument(requestParameters: UploadUrlDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.uploadUrlDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetBuyerListsPermissionEnum = {
    CanRead: 'can_read',
    CreateBuyerListEntries: 'create_buyer_list_entries',
    DeleteBuyerListEntries: 'delete_buyer_list_entries',
    CanDelete: 'can_delete',
    CanCreate: 'can_create'
} as const;
export type GetBuyerListsPermissionEnum = typeof GetBuyerListsPermissionEnum[keyof typeof GetBuyerListsPermissionEnum];
