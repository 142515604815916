import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "antd5";

import { Input } from "components/form_components/Inputs";
import { FeatureType } from "components/paywall/paywallUtils";
import { usePqlActioned } from "lib/hooks/api/users/usePqlActioned";
import { routes } from "lib/routes";
import { EventData, EventNames, useTracking } from "lib/tracking";
import { formatDataType } from "../../lib/utils";

import css from "./BookADemoForm.module.scss";

import GEOMETRICS from "../../../assets/images/vertical_geos.svg";

type BookADemoFormFields = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
};

type Props = {
  trackingData?: EventData;
};

export default function BookADemoForm({ trackingData }: Props) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submittedData, setSubmittedData] = useState<BookADemoFormFields | null>(null);

  const { mutate } = usePqlActioned(undefined, trackingData, false);
  const { logEvent } = useTracking();

  const initialFormValues: BookADemoFormFields = {
    firstName: window.currentUser?.first_name || "",
    lastName: window.currentUser?.last_name || "",
    email: window.currentUser?.email || "",
    phoneNumber: "",
  };

  const { control, handleSubmit } = useForm<BookADemoFormFields>({
    defaultValues: {
      ...initialFormValues,
    },
    mode: "onSubmit",
  });

  const dataType =
    (trackingData?.["Data type"] as FeatureType) ?? formatDataType(window.location.href) ?? "N/A";

  return (
    <div className={css.modalContainer}>
      <div className={css.image}>
        <img src={GEOMETRICS} />
      </div>
      <div className={css.formContainer}>
        {!isSubmitted && (
          <div>
            <h1>Book a demo</h1>
            <p>Tell us a bit about yourself and we'll connect you with the right person.</p>

            <form
              className={css.formFields}
              onSubmit={handleSubmit((data) => {
                mutate({
                  actionType: "Book a demo in-app form submission",
                  pageUrl: window.location.href,
                  dataType: trackingData?.["Data type"] as FeatureType,
                  contextSource: trackingData?.["Context source"],
                });

                logEvent(EventNames.bookADemoRequested, {
                  "Context source": trackingData?.["Context source"] ?? "Book a demo form",
                  "Data type": dataType,
                  "First name": data.firstName,
                  "Last name": data.lastName,
                  Email: data.email,
                  "Phone number": data.phoneNumber,
                });

                setSubmittedData(data);
                setIsSubmitted(true);
              })}
            >
              <Input
                control={control}
                name={"firstName"}
                label={"First name"}
                rules={{ required: { value: true, message: "First name is required" } }}
              />
              <Input
                control={control}
                name={"lastName"}
                label={"Last name"}
                rules={{ required: { value: true, message: "Last name is required" } }}
              />
              <Input
                control={control}
                name={"email"}
                label={"Email"}
                rules={{
                  required: { value: true, message: "Email is required" },
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid email format",
                  },
                }}
              />
              <Input
                control={control}
                name={"phoneNumber"}
                label={"Phone number(optional)"}
                rules={{
                  pattern: {
                    value: /^(?=(?:.*\d){7})[0-9/+\-.()\s]*$/,
                    message: "Invalid phone number format",
                  },
                }}
              />
              <div className={css.footer}>
                <p>
                  By requesting a demo you agree to{" "}
                  <a
                    className={css.link}
                    href={
                      "https://www.notion.so/stotles/Stotles-Terms-Conditions-c06c71f5ca19484c8a18d14fd6eadae5"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Stotle's Terms and Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    className={css.link}
                    href={
                      "https://www.notion.so/stotles/Stotles-Privacy-Policy-4a597d71636d443c9be325fef2382701"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy.
                  </a>
                </p>
              </div>
              <Button type={"primary"} htmlType="submit" className={css.submitButton}>
                Submit
              </Button>
            </form>
          </div>
        )}

        {isSubmitted && (
          <div className={css.submittedFormContent}>
            <h1>Thanks for requesting a demo</h1>{" "}
            <p>
              We’re connecting you with the right sales person. They will be in touch to organise
              next steps for your demo.{" "}
            </p>
            <Button
              type="primary"
              onClick={() => {
                logEvent(EventNames.confirmationMessageClosed, {
                  "Context source": trackingData?.["Context source"] ?? "Book a demo form",
                  "Data type": dataType,
                  "First name": submittedData?.firstName,
                  "Last name": submittedData?.lastName,
                  Email: submittedData?.email,
                  "Phone number": submittedData?.phoneNumber,
                });
              }}
              href={routes.notices}
              className={css.noticeFeedButton}
            >
              Go to notice feed
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
