/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ListPendingUploadsRequestSort } from './ListPendingUploadsRequestSort';
import {
    ListPendingUploadsRequestSortFromJSON,
    ListPendingUploadsRequestSortFromJSONTyped,
    ListPendingUploadsRequestSortToJSON,
} from './ListPendingUploadsRequestSort';

/**
 * 
 * @export
 * @interface ListPendingUploadsRequest
 */
export interface ListPendingUploadsRequest {
    /**
     * 
     * @type {string}
     * @memberof ListPendingUploadsRequest
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof ListPendingUploadsRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof ListPendingUploadsRequest
     */
    offset?: number;
    /**
     * 
     * @type {ListPendingUploadsRequestSort}
     * @memberof ListPendingUploadsRequest
     */
    sort?: ListPendingUploadsRequestSort;
}

/**
 * Check if a given object implements the ListPendingUploadsRequest interface.
 */
export function instanceOfListPendingUploadsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListPendingUploadsRequestFromJSON(json: any): ListPendingUploadsRequest {
    return ListPendingUploadsRequestFromJSONTyped(json, false);
}

export function ListPendingUploadsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListPendingUploadsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'sort': !exists(json, 'sort') ? undefined : ListPendingUploadsRequestSortFromJSON(json['sort']),
    };
}

export function ListPendingUploadsRequestToJSON(value?: ListPendingUploadsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'limit': value.limit,
        'offset': value.offset,
        'sort': ListPendingUploadsRequestSortToJSON(value.sort),
    };
}

