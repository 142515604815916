import React from "react";
import { Tag } from "styles/utility-components";

import { assertCurrentUser } from "lib/currentUser";
import { PLAN_SEPARATION, useVariableValue } from "lib/featureFlags";
import { BasicPlan, ExpertPlan, FreePlan, GrowthPlan } from "lib/icons/PlanIcon";
import { checkOutlined, plusCircleOutlined, xClose } from "lib/icons/untitled_ui/SVGs";
import { blue50, purple50, red50, yellow50 } from "lib/themes/colors";
import PlanComparisonTableNew, { PlanComparisonTable } from "./PlanComparisonTable";
import { PlanActionButton } from "./SubscriptionButtons";

import css from "./SubscriptionPlansDetails.module.scss";

export type DataSource = {
  feature: string;
  featureTooltip?: string;
  forFree: JSX.Element;
  forBasic?: JSX.Element;
  forGrowth: JSX.Element;
  forExpert: JSX.Element;
};
const FEATURE_TOOLTIPS = {
  buyerDocuments:
    "Quickly find reliable early buying signals and inform your account planning with our database of strategic buyer documents.",
  signalSettings: "Build your custom feed with industry and supplier keywords and CPV codes.",
  savedViews: "Create and save custom views for particular searches using the data filters below.",
  calendarReminders: "Set a custom reminder to come back and work on a notice in your calendar.",
  signalScore:
    "Built on our AI-driven proprietary algorithm from your signal settings to show you the most relevant opportunities at a glance.",
  buyerInformation: "Filter by name, type (e.g. central, local) and location.",
  supplierInformation: "Filter by name and size (see/hide SMEs).",
  keywords: "Include and exclude notices that match your chosen sector-specific keywords.",
  procurementStage:
    "Filter by procurement stage, including pre-tender, open, closed, awarded and stale.",
  workflowStage:
    "Filter by notices you have qualified (see team collaboration) or saved to particular lists.",
  date: "Filter by publish, close and expiry dates.",
  noticeQualification:
    "Assign work stages among your team to a notice, such as pre-engage, review, bid prep and more.",
  tenderSummaries: "Get an at-a-glance AI summary of every tender.",
  awardSummaries: "Get an at-a-glance AI summary of every award.",
  buyerQA:
    "Get instant answers to your questions about buyers without searching the data yourself.",
  outreachBuilder:
    "Let our AI outreach builder build personalised messages to decision makers for you in seconds.",
};

export function SubscriptionPlansDetails({
  triggerBookADemoForm,
  triggerDowngradeModal,
}: {
  triggerBookADemoForm: (value: boolean) => void;
  triggerDowngradeModal: (value: boolean) => void;
}) {
  const isPlanSeparationEnabled = useVariableValue(PLAN_SEPARATION, false);

  function textWithIcon(text: string, icon: JSX.Element) {
    return (
      <div className={css.textWithIcon}>
        {icon}
        <span className={css.text}>{text}</span>
      </div>
    );
  }

  const included = <span className={css.includedIcon}>{checkOutlined}</span>;

  const addonTextWithIcon = textWithIcon(
    "Add on",
    <span className={css.addonIcon}> {plusCircleOutlined} </span>,
  );
  const notIncluded = <span className={css.excludedIcon}>{xClose}</span>;

  const includedForAllPlans = () => {
    return {
      forFree: included,
      forBasic: included,
      forGrowth: included,
      forExpert: included,
    };
  };

  const includedForAllPlansExceptFree = () => {
    return {
      forFree: notIncluded,
      forBasic: included,
      forGrowth: included,
      forExpert: included,
    };
  };

  const includedForGrowthExpert = () => {
    return {
      forFree: notIncluded,
      forBasic: notIncluded,
      forGrowth: included,
      forExpert: included,
    };
  };

  const includedForExpertAddonGrowth = () => {
    return {
      forFree: notIncluded,
      forBasic: notIncluded,
      forGrowth: addonTextWithIcon,
      forExpert: included,
    };
  };

  const basicForGrowthAdvancedForExpert = () => {
    return {
      forFree: notIncluded,
      forBasic: notIncluded,
      forGrowth: <span>Basic</span>,
      forExpert: <span>Advanced</span>,
    };
  };

  const includedForExpert = () => {
    return {
      forFree: notIncluded,
      forBasic: notIncluded,
      forGrowth: notIncluded,
      forExpert: included,
    };
  };

  const selfServeFreeSupportedGrowthCustomisedExpert = () => {
    return {
      forFree: <span>Self-serve</span>,
      forGrowth: <span>Supported</span>,
      forExpert: <span>Customised</span>,
    };
  };

  const mainIntegrationsPerPlan = () => {
    return {
      forFree: notIncluded,
      forBasic: <span>Self-serve</span>,
      forGrowth: <span>Supported</span>,
      forExpert: <span>Supported</span>,
    };
  };

  const unlimitedGrowthExpert = () => {
    return {
      forFree: notIncluded,
      forBasic: notIncluded,
      forGrowth: <span>Unlimited</span>,
      forExpert: <span>Unlimited</span>,
    };
  };

  const creditsExpert = () => {
    return {
      forFree: notIncluded,
      forBasic: notIncluded,
      forGrowth: notIncluded,
      forExpert: <span>Monthly credits</span>,
    };
  };

  const essentialsContent: DataSource[] = [
    {
      feature: "Users",
      forFree: <span>Users</span>,
      forGrowth: <span>5 users maximum</span>,
      forExpert: <span>Custom</span>,
    },
  ];

  const dataContent: DataSource[] = [
    {
      feature: "Tenders",
      ...includedForAllPlans(),
    },
    {
      feature: "Pre-Tenders & PINS data",
      ...includedForAllPlans(),
    },
    {
      feature: "Procurement contact information",
      ...includedForAllPlans(),
    },
    {
      feature: "Historical contract awards & framework data",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Buyer intelligence",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Partner intelligence",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Competitor intelligence",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Key decision maker contact information",
      ...includedForExpertAddonGrowth(),
    },
    {
      feature: "Strategic documents & minutes data",
      ...includedForExpertAddonGrowth(),
    },
    {
      feature: "Invoiced spend data",
      ...includedForExpertAddonGrowth(),
    },
    {
      feature: "Framework data",
      ...includedForExpertAddonGrowth(),
    },
  ];

  const toolsContent: DataSource[] = [
    {
      feature: "Saved views & lists",
      ...includedForAllPlans(),
    },
    {
      feature: "Team tagging & commenting",
      ...includedForAllPlans(),
    },
    {
      feature: "Customisable notifications",
      ...includedForAllPlans(),
    },
    { feature: "Target buyer mapping and monitoring", ...includedForAllPlans() },
    {
      feature: "Geographic filters",
      ...includedForAllPlans(),
    },
    {
      feature: "Signal scores",
      ...includedForAllPlans(),
    },
    {
      feature: "CRM integrations (Salesforce & Hubspot)",
      ...selfServeFreeSupportedGrowthCustomisedExpert(),
    },
    {
      feature: "Collaboration tool integrations (MS Teams & Slack)",
      ...selfServeFreeSupportedGrowthCustomisedExpert(),
    },
    {
      feature: "Relevant buyer-intent rankings",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Uncapped procurement data exports",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Partner relationship mapping & monitoring",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Enterprise SAML/SSO",
      ...includedForExpert(),
    },
    {
      feature:
        "Advanced teaming tools & configurations for multiple teams, territories & product lines.",
      ...includedForExpert(),
    },
  ];

  const customerSuccessContent: DataSource[] = [
    {
      feature: "Self-serve access to Stotles help centre support",
      ...includedForAllPlans(),
    },
    {
      feature: "On-boarding support",
      forFree: <span>Self-serve</span>,
      forGrowth: <span>Basic</span>,
      forExpert: <span>Full configuration</span>,
    },
    {
      feature: "Live chat support (9am-6pm M - F)",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Dedicated Customer Success Manager",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Configuration & technical support pack",
      ...basicForGrowthAdvancedForExpert(),
    },
    {
      feature: "Training & enablement sessions pack",
      ...basicForGrowthAdvancedForExpert(),
    },
    {
      feature: "Strategy and COR sessions pack",
      ...basicForGrowthAdvancedForExpert(),
    },
  ];

  const managedServicesContent: DataSource[] = [
    {
      feature: "Custom briefings & reports (credits)",
      ...includedForExpertAddonGrowth(),
    },
    {
      feature: "FOI requests (credits)",
      ...includedForExpertAddonGrowth(),
    },
  ];

  const aiFeaturesContent: DataSource[] = [
    {
      feature: "Tender Summaries",
      ...includedForAllPlans(),
    },
    {
      feature: "Award Summaries",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "AI Outreach Builder",
      ...includedForAllPlansExceptFree(),
    },
  ];

  const dataContents: Record<string, DataSource[]> = {
    tenders: [
      {
        feature: "Prior information notices (PINs)",
        ...includedForAllPlans(),
      },
      {
        feature: "Open tenders",
        ...includedForAllPlans(),
      },
      {
        feature: "Closed historical tenders",
        ...includedForAllPlans(),
      },
    ],
    awards: [
      {
        feature: "Upcoming expiring contracts",
        ...includedForAllPlansExceptFree(),
      },
      {
        feature: "Historically awarded contracts",
        ...includedForAllPlansExceptFree(),
      },
    ],
    buyerIntelligence: [
      {
        feature: "Buyer profiles",
        ...includedForGrowthExpert(),
      },
      {
        feature: "Target buyer lists",
        ...includedForGrowthExpert(),
      },
    ],
    supplierIntelligence: [
      {
        feature: "Supplier profiles",
        ...includedForGrowthExpert(),
      },
      {
        feature: "Competitor lists",
        ...includedForGrowthExpert(),
      },
      {
        feature: "Partner lists",
        ...includedForGrowthExpert(),
      },
    ],
    contactInformation: [
      {
        feature: "Procurement contacts",
        ...includedForAllPlans(),
      },
      {
        feature: "Decision-maker contacts",
        ...includedForExpertAddonGrowth(),
      },
    ],
    frameworkInsights: [
      {
        feature: "Framework agreements and dynamic purchasing systems (DPSs)",
        ...includedForGrowthExpert(),
      },
      {
        feature: "Call-off data",
        ...includedForGrowthExpert(),
      },
    ],
    spendAnalysis: [
      {
        feature: "Invoiced spend data",
        ...includedForGrowthExpert(),
      },
      {
        feature: "Buyer and competitor relationships",
        ...includedForGrowthExpert(),
      },
      {
        feature: "Buyer and partner relationships",
        ...includedForGrowthExpert(),
      },
      {
        feature: "Competitor/partner rankings",
        ...includedForGrowthExpert(),
      },
    ],
    buyerDocuments: [
      {
        feature: "Strategy and annual reports",
        ...includedForGrowthExpert(),
      },
      {
        feature: "Budgets",
        ...includedForGrowthExpert(),
      },
      {
        feature: "FOI responses",
        ...includedForGrowthExpert(),
      },
      {
        feature: "Meeting minutes",
        ...includedForGrowthExpert(),
      },
      {
        feature: "Other types (e.g. guidance, case studies)",
        ...includedForGrowthExpert(),
      },
    ],
  };

  const managedServicesContents: DataSource[] = [
    {
      feature: "Custom analyst briefings",
      ...creditsExpert(),
    },
    {
      feature: "Custom analyst reports",
      ...creditsExpert(),
    },
    {
      feature: "FOI requests",
      ...creditsExpert(),
    },
  ];

  const toolsWorkflowContents: Record<string, DataSource[]> = {
    personalisedConfiguration: [
      {
        feature: "Signal settings",
        featureTooltip: FEATURE_TOOLTIPS.signalSettings,
        ...includedForAllPlans(),
      },
      {
        feature: "Custom notice feed",
        ...includedForAllPlans(),
      },
      {
        feature: "Saved views (saved searches)",
        featureTooltip: FEATURE_TOOLTIPS.savedViews,
        ...includedForAllPlans(),
      },
      {
        feature: "Custom notifications (inc. email alerts)",
        ...includedForAllPlans(),
      },
      {
        feature: "Calendar reminders",
        featureTooltip: FEATURE_TOOLTIPS.calendarReminders,
        ...includedForAllPlans(),
      },
    ],
    dataFilters: [
      {
        feature: "Signal score",
        featureTooltip: FEATURE_TOOLTIPS.signalScore,
        ...includedForAllPlans(),
      },
      {
        feature: "Buyer information",
        featureTooltip: FEATURE_TOOLTIPS.buyerInformation,
        ...includedForAllPlans(),
      },
      {
        feature: "Supplier information",
        featureTooltip: FEATURE_TOOLTIPS.supplierInformation,
        ...includedForAllPlans(),
      },
      {
        feature: "Geography",
        ...includedForAllPlans(),
      },
      {
        feature: "Contract values",
        ...includedForAllPlans(),
      },
      {
        feature: "Frameworks",
        ...includedForAllPlans(),
      },
      {
        feature: "Keywords",
        featureTooltip: FEATURE_TOOLTIPS.keywords,
        ...includedForAllPlans(),
      },
      {
        feature: "Common Procurement Vocabulary (CPV) codes",
        ...includedForAllPlans(),
      },
      {
        feature: "Procurement stage",
        featureTooltip: FEATURE_TOOLTIPS.procurementStage,
        ...includedForAllPlans(),
      },
      {
        feature: "Workflow stage",
        featureTooltip: FEATURE_TOOLTIPS.workflowStage,
        ...includedForAllPlans(),
      },
      {
        feature: "Date",
        featureTooltip: FEATURE_TOOLTIPS.date,
        ...includedForAllPlans(),
      },
    ],
    teamCollaboration: [
      {
        feature: "Notice qualification",
        featureTooltip: FEATURE_TOOLTIPS.noticeQualification,
        ...includedForAllPlans(),
      },
      {
        feature: "Comment on notices",
        ...includedForAllPlans(),
      },
      {
        feature: "Share notices with team",
        ...includedForAllPlans(),
      },
      {
        feature: "Assign notices to team members",
        ...includedForAllPlans(),
      },
      {
        feature: "Multi-team instances",
        ...includedForExpert(),
      },
    ],
    aiEfficiencyDrivers: [
      {
        feature: "Stotles signal score (see Data filters)",
        ...includedForAllPlans(),
      },
      {
        feature: "Tender summaries",
        featureTooltip: FEATURE_TOOLTIPS.tenderSummaries,
        ...includedForAllPlans(),
      },
      {
        feature: "Award summaries",
        featureTooltip: FEATURE_TOOLTIPS.awardSummaries,
        ...includedForAllPlans(),
      },
      {
        feature: "Buyer Q&AI",
        featureTooltip: FEATURE_TOOLTIPS.buyerQA,
        ...includedForGrowthExpert(),
      },
      {
        feature: "AI outreach builder",
        featureTooltip: FEATURE_TOOLTIPS.outreachBuilder,
        ...includedForExpertAddonGrowth(),
      },
    ],
    dataExport: [
      {
        feature: "Tenders and contracts",
        ...unlimitedGrowthExpert(),
      },
      {
        feature: "Frameworks",
        ...unlimitedGrowthExpert(),
      },
      {
        feature: "Documents",
        ...unlimitedGrowthExpert(),
      },
      {
        feature: "Decision-maker contacts",
        ...unlimitedGrowthExpert(),
      },
      {
        feature: "Buyer and supplier lists",
        ...unlimitedGrowthExpert(),
      },
      {
        feature: "Invoiced spend transactions",
        ...unlimitedGrowthExpert(),
      },
    ],
    integrations: [
      {
        feature: "Salesforce CRM",
        ...mainIntegrationsPerPlan(),
      },
      {
        feature: "HubSpot CRM",
        ...mainIntegrationsPerPlan(),
      },
      {
        feature: "MSTeams",
        ...mainIntegrationsPerPlan(),
      },
      {
        feature: "Slack",
        ...mainIntegrationsPerPlan(),
      },
      {
        feature: "Custom integration",
        forFree: <span className={css.excludedIcon}>{xClose}</span>,
        forBasic: <span className={css.excludedIcon}>{xClose}</span>,
        forGrowth: <span>Custom pricing</span>,
        forExpert: <span>Custom pricing</span>,
      },
    ],
    adminAndSecurity: [
      {
        feature: "Single sign-on",
        ...includedForAllPlans(),
      },
      {
        feature: "Team utilisation view",
        ...includedForGrowthExpert(),
      },
      {
        feature: "Enterprise SAML/SSO",
        ...includedForExpert(),
      },
    ],
  };

  const customerSuccessContents: Record<string, DataSource[]> = {
    tieredCustomerSuccessPlans: [
      {
        feature: "Self-serve platform on-boarding tours",
        ...includedForAllPlans(),
      },
      {
        feature: "Stotles Help Centre articles & video library",
        ...includedForAllPlans(),
      },
      {
        feature: "Access to Public Sector selling best practices playbooks & webinars",
        ...includedForAllPlans(),
      },
      {
        feature: "Live chat support (9am-6pm Mon-Fri)",
        ...includedForAllPlansExceptFree(),
      },
      {
        feature: "Personalised on-boarding guidance & configuration",
        forFree: notIncluded,
        forBasic: <span>0.5 hour session</span>,
        forGrowth: <span>1 hr session</span>,
        forExpert: <span>Unlimited</span>,
      },
      {
        feature: "Drop-in “office hours” group support sessions",
        forFree: notIncluded,
        forBasic: notIncluded,
        forGrowth: <span>Monthly</span>,
        forExpert: <span>Weekly</span>,
      },
      {
        feature: "On-going white-glove user support sessions",
        forFree: notIncluded,
        forBasic: notIncluded,
        forGrowth: <span>1 hr / month</span>,
        forExpert: <span>Unlimited</span>,
      },
      {
        feature: "Customer Success Manager",
        forFree: notIncluded,
        forBasic: notIncluded,
        forGrowth: <span>CSM</span>,
        forExpert: <span>Named CSM</span>,
      },
      {
        feature: "Pipeline generation progress checks & consultations",
        ...includedForExpert(),
      },
      {
        feature: "On-site pipeline generation workshops",
        ...includedForExpert(),
      },
      {
        feature: "Early access to product roadmap & beta features",
        ...includedForExpert(),
      },
      {
        feature: "Access to private market events",
        ...includedForExpert(),
      },
      {
        feature: "Co-marketing opportunities",
        ...includedForExpert(),
      },
      {
        feature: "Access to customer advisory council",
        ...includedForExpert(),
      },
    ],
  };

  const planComparisonTable = isPlanSeparationEnabled ? (
    <>
      <PlanComparisonTableNew
        header="Data"
        data={[
          { subHeader: "Tender data", features: dataContents["tenders"] },
          { subHeader: "Award data", features: dataContents["awards"] },
          { subHeader: "Buyer intelligence", features: dataContents["buyerIntelligence"] },
          { subHeader: "Supplier intelligence", features: dataContents["supplierIntelligence"] },
          { subHeader: "Contact information", features: dataContents["contactInformation"] },
          { subHeader: "Framework insights", features: dataContents["frameworkInsights"] },
          { subHeader: "Spend analysis", features: dataContents["spendAnalysis"] },
          {
            subHeader: "Buyer documents",
            subHeaderTooltip: FEATURE_TOOLTIPS.buyerDocuments,
            features: dataContents["buyerDocuments"],
          },
        ]}
      />
      <PlanComparisonTableNew
        header="Managed services"
        data={[{ subHeader: "Analyst services", features: managedServicesContents }]}
      />
      <PlanComparisonTableNew
        header="Tools & Workflow"
        data={[
          {
            subHeader: "Personalised configuration",
            features: toolsWorkflowContents["personalisedConfiguration"],
          },
          { subHeader: "Data filters", features: toolsWorkflowContents["dataFilters"] },
          { subHeader: "Team collaboration", features: toolsWorkflowContents["teamCollaboration"] },
          {
            subHeader: "AI efficiency drivers",
            features: toolsWorkflowContents["aiEfficiencyDrivers"],
          },
          { subHeader: "Data export", features: toolsWorkflowContents["dataExport"] },
          { subHeader: "Integrations", features: toolsWorkflowContents["integrations"] },
          { subHeader: "Admin and security", features: toolsWorkflowContents["adminAndSecurity"] },
        ]}
      />
      <PlanComparisonTableNew
        header="Customer success plans"
        headerColumns={{
          free: "Bronze",
          basic: "Silver",
          growth: "Gold",
          expert: "Platinum",
        }}
        data={[
          {
            features: customerSuccessContents["tieredCustomerSuccessPlans"],
          },
        ]}
      />
    </>
  ) : (
    <div className={css.planComparisons}>
      <PlanComparisonTable header="Essentials" data={essentialsContent} />
      <PlanComparisonTable header="Data" data={dataContent} />
      <PlanComparisonTable header="Tools" data={toolsContent} />
      <PlanComparisonTable header="Customer Success" data={customerSuccessContent} />
      <PlanComparisonTable header="Managed services" data={managedServicesContent} />
      <PlanComparisonTable header="AI Features" data={aiFeaturesContent} />
    </div>
  );

  const currentPlan = assertCurrentUser().subscription;

  return (
    <div className={css.planDetailsSection} id={"planComparisonSection"}>
      <div className={css.description}>
        <h1>
          Compare our plans in detail<span className={css.dot}>.</span>
        </h1>
        <p>Find which plan works best for you to grow public sector pipeline.</p>
      </div>

      {isPlanSeparationEnabled ? (
        <table className={css.planTable}>
          <thead className={css.planHeader}>
            <tr>
              <th scope="col" className={css.featureNameHeaderCell} />
              <th scope="col" className={css.planHeaderCell}>
                <div className={css.planNames}>
                  <Tag title="Free" color={red50} icon={<FreePlan className={css.planIcon} />} />
                  <span className={css.billingInfo}>Free for unlimited users</span>
                  <PlanActionButton
                    plan="freemium"
                    currentPlan={currentPlan}
                    tableHeader
                    triggerBookADemoForm={triggerBookADemoForm}
                    triggerDowngradeModal={triggerDowngradeModal}
                  />
                </div>
              </th>
              <th scope="col" className={css.planHeaderCell}>
                <div className={css.planNames}>
                  <Tag
                    title="Basic"
                    color={yellow50}
                    icon={<BasicPlan className={css.planIcon} />}
                  />
                  <span className={css.billingInfo}>From £50 per user / month</span>
                  <PlanActionButton
                    plan="basic"
                    currentPlan={currentPlan}
                    tableHeader
                    triggerBookADemoForm={triggerBookADemoForm}
                    triggerDowngradeModal={triggerDowngradeModal}
                  />
                </div>
              </th>
              <th scope="col" className={css.planHeaderCell}>
                <div className={css.planNames}>
                  <Tag
                    title="Growth"
                    color={purple50}
                    icon={<GrowthPlan className={css.planIcon} />}
                  />
                  <span className={css.billingInfo}>From £450 per month</span>
                  <PlanActionButton
                    plan="growth"
                    currentPlan={currentPlan}
                    tableHeader
                    triggerBookADemoForm={triggerBookADemoForm}
                    triggerDowngradeModal={triggerDowngradeModal}
                  />
                </div>
              </th>
              <th scope="col" className={css.planHeaderCell}>
                <div className={css.planNames}>
                  <Tag
                    title="Expert"
                    color={blue50}
                    icon={<ExpertPlan className={css.planIcon} />}
                  />
                  <span className={css.billingInfo}>Let's talk custom pricing</span>
                  <PlanActionButton
                    plan="expert"
                    currentPlan={currentPlan}
                    tableHeader
                    triggerBookADemoForm={triggerBookADemoForm}
                    triggerDowngradeModal={triggerDowngradeModal}
                  />
                </div>
              </th>
            </tr>
          </thead>
          {planComparisonTable}
        </table>
      ) : (
        <div>
          <div className={css.oldPlanNames}>
            <div className={css.oldPlan}>
              <p>{""}</p>
            </div>
            <div className={css.oldPlan}>
              <Tag title="Free" color={red50} icon={<FreePlan className={css.planIcon} />} />
              <span>Free</span>
              <span className={css.oldBillingInfo}>for unlimited users</span>
            </div>
            <div className={css.oldPlan}>
              <Tag title="Growth" color={purple50} icon={<GrowthPlan className={css.planIcon} />} />
              <span>Custom pricing</span>
              <span className={css.oldBillingInfo}>per user / per month</span>
            </div>
            <div className={css.oldPlan}>
              <Tag title="Expert" color={blue50} icon={<ExpertPlan className={css.planIcon} />} />
              <span>Custom pricing</span>
              <span className={css.oldBillingInfo}>per user / per month</span>
            </div>
          </div>
          {planComparisonTable}
        </div>
      )}
    </div>
  );
}
