import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { graphql } from "../../../generated/app-service-gql";
import {
  EntityOpportunitiesQuery,
  RemoveOpportunityEntityMutation,
  RemoveOpportunityEntityMutationVariables,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const REMOVE_OPPORTUNITY_ENTITY_MUTATION = graphql(`
  mutation removeOpportunityEntity($input: OpportunityEntities_2!) {
    removeOpportunityEntity(OpportunityEntity: $input) {
      id
    }
  }
`);

export function useRemoveOpportunityEntity(
  options?: UseMutationOptions<
    RemoveOpportunityEntityMutation,
    unknown,
    RemoveOpportunityEntityMutationVariables,
    unknown
  >,
) {
  const queryClient = useQueryClient();
  return useGraphQlMutation(REMOVE_OPPORTUNITY_ENTITY_MUTATION, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData<EntityOpportunitiesQuery>(
        ["entityOpportunities", variables.input.entityId],
        (oldData) => {
          if (!oldData) {
            return { entityOpportunites: [] };
          }
          return {
            entityOpportunites: oldData.entityOpportunites.filter(
              (id) => id !== variables.input.opportunityId,
            ),
          };
        },
      );

      options?.onSuccess?.(data, variables, context);
    },
  });
}
