/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ListPendingUploads200ResponseResultsInner } from './ListPendingUploads200ResponseResultsInner';
import {
    ListPendingUploads200ResponseResultsInnerFromJSON,
    ListPendingUploads200ResponseResultsInnerFromJSONTyped,
    ListPendingUploads200ResponseResultsInnerToJSON,
} from './ListPendingUploads200ResponseResultsInner';

/**
 * 
 * @export
 * @interface ListPendingUploads200Response
 */
export interface ListPendingUploads200Response {
    /**
     * 
     * @type {Array<ListPendingUploads200ResponseResultsInner>}
     * @memberof ListPendingUploads200Response
     */
    results: Array<ListPendingUploads200ResponseResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof ListPendingUploads200Response
     */
    totalResults: number;
}

/**
 * Check if a given object implements the ListPendingUploads200Response interface.
 */
export function instanceOfListPendingUploads200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;
    isInstance = isInstance && "totalResults" in value;

    return isInstance;
}

export function ListPendingUploads200ResponseFromJSON(json: any): ListPendingUploads200Response {
    return ListPendingUploads200ResponseFromJSONTyped(json, false);
}

export function ListPendingUploads200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListPendingUploads200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(ListPendingUploads200ResponseResultsInnerFromJSON)),
        'totalResults': json['totalResults'],
    };
}

export function ListPendingUploads200ResponseToJSON(value?: ListPendingUploads200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(ListPendingUploads200ResponseResultsInnerToJSON)),
        'totalResults': value.totalResults,
    };
}

