import React from "react";
import { Checkbox } from "antd5";

import { countryCodeToCountry } from "../../../../lib/data/optionItems";
import Signal from "../../../../lib/icons/Signal";
import { SupplierTooltip } from "./SupplierTooltip";

import css from "./SupplierCheckbox.module.scss";

type Props = {
  supplier: {
    label: string;
    value: string;
    countryCode: string | null | undefined;
    totalNotices: number | null | undefined;
    aliases: string[] | null | undefined;
  };
  selectedSupplierIds: string[];
  onChange: (isChecked: boolean) => void;
  showSignalIcon?: boolean;
};
export function SupplierCheckbox({
  supplier,
  selectedSupplierIds,
  onChange,
  showSignalIcon,
}: Props) {
  return (
    <SupplierTooltip
      supplierName={supplier.label}
      totalNotices={supplier.totalNotices}
      aliases={supplier.aliases}
    >
      <label className={css.labelAndIcon}>
        <Checkbox
          aria-label="Supplier option"
          value={supplier.value}
          name={supplier.label}
          checked={selectedSupplierIds.includes(supplier.value)}
          className={css.label}
          onChange={(e) => onChange(e.target.checked)}
        >
          <div className={css.labelBody}>
            <span className={css.labelText}>{supplier.label}</span>
            <span className={css.countryText}>
              {supplier.countryCode && countryCodeToCountry(supplier.countryCode)}
            </span>
          </div>
        </Checkbox>

        {showSignalIcon && <Signal className={css.signalIcon} size={16} label="signalIcon" />}
      </label>
    </SupplierTooltip>
  );
}
