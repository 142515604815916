import React from "react";

import { ProHelperProvider } from "lib/providers/ProHelper";
import { SharedSaveProps } from "../types";
import { SaveToBuyerList } from "./SaveToBuyerList";
import { SaveToCompetitorOrPartner } from "./SaveToCompetitorOrPartner";
import { SaveToNoticeList } from "./SaveToNoticeList";

export function SaveToLists({ entityType, entityId }: SharedSaveProps) {
  switch (entityType) {
    case "notice":
      return <SaveToNoticeList entityId={entityId} />;
    case "buyer":
      return <SaveToBuyerList buyerId={entityId} />;
    case "supplier":
      return (
        <ProHelperProvider>
          <SaveToCompetitorOrPartner supplierGuid={entityId} />
        </ProHelperProvider>
      );
    default:
      return null;
  }
}
