import React from "react";
import { CopyOutlined, MailOutlined } from "@ant-design/icons";
import { Button, message, Tooltip } from "antd5";

import { ButtonLink } from "components/actions/Links";
import TextButton from "components/actions/TextButton";
import { copyToClipboard } from "lib/clipboard";
import CtaPopover from "lib/core_components/CtaPopover";
import { useProductTour } from "lib/hooks/useProductTour";
import { OutreachTrackingEvent, useOutreachTracking } from "./tracking";

import css from "./OutreachResult.module.scss";

type OutreachEmail = {
  subject?: string;
  body: string;
  email: string;
};

function generateMailToLink({ email, subject, body }: OutreachEmail) {
  if (subject) {
    return `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
      body,
    )}`;
  }

  return `mailto:${email}?body=${encodeURIComponent(body)}`;
}

function extractEmailContent(content: string) {
  const lines = content.split("\n");
  const subjectLine = lines.findIndex((line) => line.includes("Subject:"));

  if (subjectLine > -1) {
    const subject = lines[subjectLine].replace("Subject:", "").trim();
    const body = lines
      .slice(subjectLine + 1)
      .join("\n")
      .trim();
    return { subject, body };
  }

  return { subject: undefined, body: content };
}

const EMAIL_SETUP_LINK = "https://help.stotles.com/setting-up-your-email-client";

export function OutreachResult({
  result,
  email,
  onAction,
}: {
  result: string;
  email: string;
  onAction: () => void;
}) {
  const { track } = useOutreachTracking();
  const { subject, body } = extractEmailContent(result);

  const { tourOpen: setupHelpVisible, permanentlyHide: permanentlyHideSetup } = useProductTour(
    "outreach_builder_email_setup",
    {
      delayMsOnFirstMount: 250,
    },
  );

  const [messageApi, contextHolder] = message.useMessage();

  /**
   * Simply copies content, automtaically updates status of contact to actioned (and record to pre-engage actioned)
   * and displays a success message to user
   * @param content
   */
  const copyContent = (content: string, trackingEvent: OutreachTrackingEvent) => {
    copyToClipboard({ plain: content });
    track(trackingEvent);
    onAction();
    void messageApi.success("Copied to clipboard!");
  };

  const openEmailBtn = (
    <Button type="primary" title="Create email" className={css.mailToBtn}>
      <a
        href={generateMailToLink({ email, body, subject })}
        target="_blank"
        onClick={() => {
          track("sendEmail");
          onAction();
        }}
        rel="noreferrer"
      >
        <MailOutlined />
      </a>
    </Button>
  );

  return (
    <div className={css.results}>
      <div>
        {email && (
          <div
            className={css.emailContent}
            aria-label="to"
            role="button"
            onClick={() => copyContent(email, "copyEmail")}
            title="Copy to clipboard"
          >
            <span>To: {email}</span>
            <CopyOutlined className={css.copyIcon} />
          </div>
        )}
        {subject && (
          <div
            className={css.emailContent}
            aria-label="subject"
            role="button"
            onClick={() => copyContent(subject, "copySubject")}
            title="Copy to clipboard"
          >
            <span>Subject: {subject}</span>
            <CopyOutlined className={css.copyIcon} />
          </div>
        )}

        <div
          className={css.emailContent}
          aria-label="body"
          role="button"
          onClick={() => copyContent(body, "copyBody")}
          title="Copy to clipboard"
        >
          <span>{body}</span>
          <CopyOutlined className={css.copyIcon} />
        </div>
      </div>
      <div className={css.resultsActions}>
        {setupHelpVisible ? (
          <CtaPopover
            title="Want some help getting set up?"
            content="Check out our helpful guides on setting up your default email client."
            placement="left"
            buttons={[
              <ButtonLink
                targetType="external"
                to={EMAIL_SETUP_LINK}
                key="help"
                onClick={() => {
                  track("visitEmailSetupHelp", {
                    destination: EMAIL_SETUP_LINK,
                  });
                }}
              >
                View guide
              </ButtonLink>,
              <TextButton onClick={permanentlyHideSetup} key="dismiss" className={css.blackBtn}>
                Don't show again
              </TextButton>,
            ]}
            overlayClassName={css.setupHelp}
          >
            {openEmailBtn}
          </CtaPopover>
        ) : (
          <Tooltip title="Open draft email">{openEmailBtn}</Tooltip>
        )}
      </div>
      {contextHolder}
    </div>
  );
}
