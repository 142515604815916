import React from "react";
import { Tooltip } from "antd5";
import classNames from "classnames";

import { infoCircle } from "lib/icons/untitled_ui/SVGs";
import { DataSource } from "./SubscriptionPlansDetails";

import css from "./PlanComparisonTable.module.scss";

export const PlanComparisonTable = ({ header, data }: { header: string; data: DataSource[] }) => {
  return (
    <div className={css.planTableContainer}>
      <div className={css.header}>{header}</div>
      {data.map((item) => (
        <div className={css.row} key={item.feature}>
          <div className={css.featureColumn}>{item.feature}</div>
          <div className={css.freeColumn}>{item.forFree}</div>
          <div className={css.growthColumn}>{item.forGrowth}</div>
          <div className={css.expertColumn}>{item.forExpert}</div>
        </div>
      ))}
    </div>
  );
};

const PlanComparisonTableNew = ({
  header,
  headerColumns,
  data,
}: {
  header: string;
  headerColumns?: { free: string; basic: string; growth: string; expert: string };
  data: { subHeader?: string; subHeaderTooltip?: string; features: DataSource[] }[];
}) => {
  return (
    <tbody>
      <tr className={css.headerRow}>
        <th
          className={classNames(
            css.headerText,
            header === "Customer success plans" && headerColumns ? css.lastHeaderText : undefined,
          )}
        >
          {header}
        </th>
        {headerColumns ? (
          <>
            <th className={css.headerTextPerFeature}>{headerColumns.free}</th>
            <th className={css.headerTextPerFeature}>{headerColumns.basic}</th>
            <th className={css.headerTextPerFeature}>{headerColumns.growth}</th>
            <th className={css.headerTextPerFeature}>{headerColumns.expert}</th>
          </>
        ) : (
          <>
            <th />
            <th />
            <th />
            <th />
          </>
        )}
      </tr>
      {data.map((item) => (
        <React.Fragment key={item.subHeader ?? ""}>
          {item.subHeader ? (
            <tr className={css.tableRow}>
              <td colSpan={5} className={css.subHeaderCell}>
                <span className={css.subHeaderText}>
                  {item.subHeader}
                  {item.subHeaderTooltip && (
                    <Tooltip title={item.subHeaderTooltip} placement="right">
                      <span className={css.infoIcon}>{infoCircle}</span>
                    </Tooltip>
                  )}
                </span>
              </td>
            </tr>
          ) : null}
          {item.features.map((feature) => (
            <tr className={css.tableRow} key={feature.feature}>
              <td className={css.featureTextCell} scope="row">
                <span className={css.featureText}>
                  {feature.feature}
                  {feature.featureTooltip && (
                    <Tooltip title={feature.featureTooltip} placement="right">
                      <span className={css.infoIcon}>{infoCircle}</span>
                    </Tooltip>
                  )}
                </span>
              </td>
              <td className={css.featureStatusCell}>{feature.forFree}</td>
              <td className={css.featureStatusCell}>{feature.forBasic}</td>
              <td className={css.featureStatusCell}>{feature.forGrowth}</td>
              <td className={css.featureStatusCell}>{feature.forExpert}</td>
            </tr>
          ))}
        </React.Fragment>
      ))}
    </tbody>
  );
};

export default PlanComparisonTableNew;
