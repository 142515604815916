import React, { useState } from "react";

import { InRowSaveToButton } from "components/opportunities/saving/InRowSaveToButton";
import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import { OPPORTUNTIES, useVariableValue } from "lib/featureFlags";
import SaveSupplier, { SupplierContextSource } from "./SaveSupplier";

import css from "./SupplierNameCell.module.scss";

export function SupplierNameCell({
  supplierGuid,
  supplierName,
  // Keeping contextSource as will be required on tracking when it's implemented for opportunity work
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  contextSource = "In-row",
}: {
  supplierGuid: string;
  supplierName: string;
  contextSource?: SupplierContextSource;
}) {
  const [mouseEntered, setMouseEntered] = useState(false);
  const enableOpportunities = useVariableValue(OPPORTUNTIES, false);

  const SaveButton = () => {
    if (enableOpportunities) {
      return (
        <InRowSaveToButton
          entityType={"supplier"}
          entityId={supplierGuid}
          displayType={"button"}
          // suppliers cannot be saved to "lists" and so will never show as "saved"
          // need to revisit "isSaved" and "listCount" as part of APP-2157
          isSaved={false}
          listCount={0}
        />
      );
    } else {
      return (
        <SaveSupplier
          supplierGuid={supplierGuid}
          supplierName={supplierName}
          triggerType={mouseEntered ? "button" : "bookmark"}
          contextSource={contextSource}
        />
      );
    }
  };

  return (
    <div
      className={css.nameColumn}
      onMouseEnter={() => setMouseEntered(true)}
      onMouseLeave={() => setMouseEntered(false)}
    >
      <EllipsisTooltipTextLink
        fullText={supplierName}
        linkText={supplierName}
        linkProps={{
          to: `/suppliers/${supplierGuid}`,
          authorised: !window.guestUser,
        }}
        containerClassname={css.titleLink}
      />

      <div
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        {!window.guestUser && <SaveButton />}
      </div>
    </div>
  );
}
