import React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { Flex, Text } from "../../../styles/utility-components";
import { emptyStateIcons } from "./EmptyStateIcons";

type EmptyStateType =
  | "notifications"
  | "noResults"
  | "noDocuments"
  | "startingState"
  | "loadError"
  | "userRequiredContent"
  | "success"
  | "download";

interface EmptyStateProps {
  size?: "small" | "large";
  type?: EmptyStateType;
  heading?: string;
  description?: string;
  action?: React.ReactNode;
  width?: string;
}

function EmptyState({
  size = "small",
  type = "noResults",
  heading,
  description,
  action,
  width = "300px",
}: EmptyStateProps) {
  const theme = useTheme();

  const isLarge = size === "large";

  return (
    <Wrapper width={width}>
      <Flex column justifyContent="center" alignItems="center">
        <UIcon
          svg={emptyStateIcons[type as keyof typeof emptyStateIcons]}
          size={isLarge ? 150 : 110}
        />
        <Flex column gap={4} justifyContent="center" alignItems="center">
          <Text h3={isLarge} fontWeight={isLarge ? "700" : "500"}>
            {heading}
          </Text>
          <Text description={!isLarge} center color={theme.colors.sysTextSecondary}>
            {description}
          </Text>
          {action}
        </Flex>
      </Flex>
    </Wrapper>
  );
}

export default EmptyState;

const Wrapper = styled.div<{ width: string }>(({ width }) => ({
  width: width,
}));
