import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider } from "antd5";
import { CheckboxProps } from "antd5/lib";

import SignalEmptyState from "components/form_components/SignalEmptyState";
import { SignalSettings } from "../../../../lib/generated/app-service-gql/graphql";
import { SupplierCheckbox } from "./SupplierCheckbox";
import { SupplierOption } from "./types";

import css from "./SignalSuppliersChecklist.module.scss";

type Props = {
  category: "Competitor" | "Partner";
  options: SignalSettings["competitors"] | SignalSettings["partners"];
  onChange: (newSelectedSuppliers: SupplierOption[]) => void;
  selectedSuppliers: SupplierOption[];
  backBtnFn: () => void;
};

function SignalSuppliersChecklist({
  category,
  options,
  onChange,
  selectedSuppliers,
  backBtnFn,
}: Props) {
  const optionIds = options.map((o) => o.id);
  const selectedSupplierIds = selectedSuppliers.map((s) => s.value);

  // Checkbox options
  const checkedSupplierList = optionIds.filter((s) => selectedSupplierIds.includes(s));

  const isAllSuppliersChecked = options.length === checkedSupplierList.length;
  const isSomeSuppliersChecked =
    checkedSupplierList.length > 0 && checkedSupplierList.length < options.length;

  const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked) {
      onChange([...options.map((s) => ({ label: s.name, value: s.id })), ...selectedSuppliers]);
    } else {
      onChange(selectedSuppliers.filter((s) => !optionIds.includes(s.value)));
    }
  };

  const emptyStateCategory = category.toLowerCase();

  const backBtn = (
    <Button
      onClick={() => backBtnFn()}
      className={css.backBtn}
      icon={<LeftOutlined />}
      iconPosition="start"
    >
      Back
    </Button>
  );

  return options.length === 0 ? (
    <>
      <div className={css.optionsHeader}>{backBtn}</div>
      <SignalEmptyState
        entity={emptyStateCategory}
        message={`Save suppliers as ${emptyStateCategory}s through your signal settings to uncover more renewal opportunities and achieve relevant results.`}
      />
    </>
  ) : (
    <div id="scrollableDiv" className={css.scrollableDiv} aria-label="scrollableDiv">
      <div className={css.optionsHeader}>
        {backBtn}
        <label className={css.labelAndIcon}>
          <Checkbox
            aria-label="Select all"
            indeterminate={isSomeSuppliersChecked}
            onChange={onCheckAllChange}
            checked={isAllSuppliersChecked}
            className={css.label}
          >
            Select all {category.toLowerCase()}s
          </Checkbox>
        </label>
        <Divider className={css.divider} />
      </div>

      <div className={css.checkboxGroup}>
        {options.map((supplier) => (
          <SupplierCheckbox
            key={supplier.id}
            supplier={{
              label: supplier.name,
              value: supplier.id,
              aliases: supplier.aliases,
              countryCode: supplier.countryCode,
              totalNotices: supplier.noticeCount,
            }}
            selectedSupplierIds={selectedSupplierIds}
            onChange={(isChecked) => {
              if (isChecked) {
                onChange([...selectedSuppliers, { label: supplier.name, value: supplier.id }]);
              } else {
                onChange(selectedSuppliers.filter((s) => s.value !== supplier.id));
              }
            }}
            showSignalIcon={true}
          />
        ))}
      </div>
    </div>
  );
}

export default SignalSuppliersChecklist;
