/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyUserDto } from './CompanyUserDto';
import {
    CompanyUserDtoFromJSON,
    CompanyUserDtoFromJSONTyped,
    CompanyUserDtoToJSON,
} from './CompanyUserDto';
import type { TeamDto } from './TeamDto';
import {
    TeamDtoFromJSON,
    TeamDtoFromJSONTyped,
    TeamDtoToJSON,
} from './TeamDto';

/**
 * 
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
    /**
     * 
     * @type {number}
     * @memberof CompanyDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDto
     */
    isDemo: boolean | null;
    /**
     * 
     * @type {Array<TeamDto>}
     * @memberof CompanyDto
     */
    teams: Array<TeamDto>;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    subscription: CompanyDtoSubscriptionEnum;
    /**
     * 
     * @type {Array<CompanyUserDto>}
     * @memberof CompanyDto
     */
    users?: Array<CompanyUserDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDto
     */
    completedOnboarding: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyDto
     */
    defaultDataSources?: Array<string> | null;
}


/**
 * @export
 */
export const CompanyDtoSubscriptionEnum = {
    Freemium: 'freemium',
    Growth: 'growth',
    Basic: 'basic',
    Expert: 'expert'
} as const;
export type CompanyDtoSubscriptionEnum = typeof CompanyDtoSubscriptionEnum[keyof typeof CompanyDtoSubscriptionEnum];


/**
 * Check if a given object implements the CompanyDto interface.
 */
export function instanceOfCompanyDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "guid" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isDemo" in value;
    isInstance = isInstance && "teams" in value;
    isInstance = isInstance && "subscription" in value;
    isInstance = isInstance && "completedOnboarding" in value;

    return isInstance;
}

export function CompanyDtoFromJSON(json: any): CompanyDto {
    return CompanyDtoFromJSONTyped(json, false);
}

export function CompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'guid': json['guid'],
        'name': json['name'],
        'isDemo': json['is_demo'],
        'teams': ((json['teams'] as Array<any>).map(TeamDtoFromJSON)),
        'subscription': json['subscription'],
        'users': !exists(json, 'users') ? undefined : (json['users'] === null ? null : (json['users'] as Array<any>).map(CompanyUserDtoFromJSON)),
        'completedOnboarding': json['completed_onboarding'],
        'defaultDataSources': !exists(json, 'default_data_sources') ? undefined : json['default_data_sources'],
    };
}

export function CompanyDtoToJSON(value?: CompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'guid': value.guid,
        'name': value.name,
        'is_demo': value.isDemo,
        'teams': ((value.teams as Array<any>).map(TeamDtoToJSON)),
        'subscription': value.subscription,
        'users': value.users === undefined ? undefined : (value.users === null ? null : (value.users as Array<any>).map(CompanyUserDtoToJSON)),
        'completed_onboarding': value.completedOnboarding,
        'default_data_sources': value.defaultDataSources,
    };
}

