import React from "react";
import styled from "@emotion/styled";
import * as Tooltip from "@radix-ui/react-tooltip";

interface TooltipComponentProps {
  children: React.ReactNode;
  content: React.ReactNode;
  delayDuration?: number;
  side?: "top" | "right" | "bottom" | "left";
}

function TooltipComponent({
  children,
  content,
  delayDuration = 700,
  side = "top",
}: TooltipComponentProps) {
  return (
    <Tooltip.Provider delayDuration={delayDuration}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <StyledContent sideOffset={5} side={side}>
            {content}
            <StyledArrow />
          </StyledContent>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

export default TooltipComponent;

const StyledContent = styled(Tooltip.Content)(({ theme }) => ({
  // added high value zIndex here as otherwise the tooltip hides behind modals and slideouts
  zIndex: 9999,
  backgroundColor: theme.colors.sysBackgroundDefault,
  padding: "16px",
  borderRadius: "8px",
  boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.12)",
  maxWidth: "290px",
}));

const StyledArrow = styled(Tooltip.Arrow)(({ theme }) => ({
  fill: theme.colors.sysBackgroundDefault,
  width: "28px",
  height: "14px",
}));
