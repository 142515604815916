import { OpportunityEntities_2_EntityTypeEnum_1 } from "../../../lib/generated/app-service-gql/graphql";
import { OpportunityEntityType } from "../../../lib/types/graphQLEnums";
import { CreateOpportunityFormValues } from "../create_opportunity/CreateOpportunityForm";

export type EntityType = "notice" | "buyer" | "supplier" | "document" | "framework" | "contact";

export type SharedSaveProps = {
  entityType: EntityType;
  entityId: string;
  defaultOpportunityValues?: Partial<CreateOpportunityFormValues>;
};

type CombinedOpportunityEntityType = OpportunityEntityType | OpportunityEntities_2_EntityTypeEnum_1;

export function convertEntityTypeToOpportunityEntityType<T extends CombinedOpportunityEntityType>(
  entityType: EntityType,
  TargetEnum: { [key: string]: T },
): T {
  switch (entityType) {
    case "notice":
      return TargetEnum.Notice;
    case "buyer":
    case "supplier":
      return TargetEnum.Organisation;
    case "framework":
      return TargetEnum.Framework;
    case "contact":
      return TargetEnum.Contact;
    case "document":
      return TargetEnum.Document;
    default:
      throw new Error(`Unknown entity type: ${entityType}`);
  }
}
