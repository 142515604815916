import React, { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Empty, Skeleton } from "antd5";
import { CheckboxProps } from "antd5/lib";
import _ from "lodash";

import CentredSpinner from "lib/core_components/CentredSpinner";
import { useInfiniteSearchOrganisations } from "lib/hooks/api/organisations/useSearchOrganisations";
import { OrgSortBy, OrgSortOrder, SearchOrgPrimaryRole } from "lib/types/graphQLEnums";
import { SupplierCheckbox } from "./SupplierCheckbox";
import { SupplierOption } from "./types";

import css from "./InfiniteSuppliersChecklist.module.scss";

type Props = {
  selectedSuppliers: SupplierOption[];
  excludeSignals: boolean;
  signalSuppliers: SupplierOption[];
  textSearch: string;
  onChange: (suppliers: SupplierOption[]) => void;
  backBtnFn?: () => void;
};

function InfiniteSuppliersChecklist({
  selectedSuppliers,
  excludeSignals,
  signalSuppliers,
  textSearch,
  onChange,
  backBtnFn,
}: Props) {
  const { data, fetchNextPage, hasNextPage, isInitialLoading } = useInfiniteSearchOrganisations(
    {
      textSearch: textSearch.length ? textSearch : "A",
      sortBy: OrgSortBy.Relevance,
      sortOrder: OrgSortOrder.Desc,
      primaryRole: SearchOrgPrimaryRole.Supplier,
      limit: 100,
      page: 1,
    },
    true,
    true,
  );

  const selectedSupplierIds = selectedSuppliers.map((s) => s.value);
  const signalSupplierIds = signalSuppliers.map((s) => s.value);

  const loadedSuppliers = useMemo(() => {
    const suppliers =
      data?.pages
        .flatMap((p) => p.searchOrganisations.orgs)
        .map((o) => ({
          label: o.name,
          value: o.id,
          countryCode: o.countryCode,
          totalNotices: o.noticeCount,
          aliases: o.aliases,
        })) || [];
    if (excludeSignals) {
      return _.differenceBy(suppliers, signalSuppliers, "value");
    }

    // if we have signal suppliers, place them at the top
    if (signalSupplierIds.length > 0) {
      return suppliers.sort((a, b) => {
        const aIsSignal = signalSupplierIds.includes(a.value);
        const bIsSignal = signalSupplierIds.includes(b.value);
        if (aIsSignal && !bIsSignal) {
          return -1;
        } else if (!aIsSignal && bIsSignal) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return suppliers;
  }, [data, excludeSignals, signalSuppliers, signalSupplierIds]);

  const checkedList = useMemo(
    () => loadedSuppliers.filter((s) => selectedSupplierIds.includes(s.value)),
    [selectedSupplierIds, loadedSuppliers],
  );

  const isAllSuppliersChecked = loadedSuppliers.length === checkedList.length;
  const isSomeSuppliersChecked =
    checkedList.length > 0 && checkedList.length < loadedSuppliers.length;

  const handleCheckboxChange = (supplier: SupplierOption, isChecked: boolean) => {
    if (isChecked) {
      onChange([...selectedSuppliers, supplier]);
    } else {
      onChange(selectedSuppliers.filter((s) => s.value !== supplier.value));
    }
  };

  const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked) {
      onChange([...loadedSuppliers, ...selectedSuppliers]);
    } else {
      const loadedSupplierIds = loadedSuppliers.map((s) => s.value);
      onChange(selectedSuppliers.filter((s) => !loadedSupplierIds.includes(s.value)));
    }
  };

  const menu =
    !isInitialLoading && loadedSuppliers.length === 0 ? (
      <Empty className={css.emptyState} />
    ) : (
      <InfiniteScroll
        dataLength={loadedSuppliers.length}
        next={fetchNextPage}
        hasMore={hasNextPage || false}
        loader={<CentredSpinner />}
        scrollableTarget="scrollableDiv"
        style={{ height: "inherit", overflow: "inherit" }}
      >
        <div className={css.optionsHeader}>
          {backBtnFn && textSearch.length === 0 && (
            <Button
              onClick={() => backBtnFn()}
              className={css.backBtn}
              icon={<LeftOutlined />}
              iconPosition="start"
            >
              Back
            </Button>
          )}
          {
            // show select all only when searching
            textSearch.length > 0 && loadedSuppliers.length < 100 && (
              <>
                <label className={css.labelAndIcon}>
                  <Checkbox
                    aria-label="Select all"
                    indeterminate={isSomeSuppliersChecked}
                    onChange={onCheckAllChange}
                    checked={isAllSuppliersChecked}
                    className={css.label}
                  >
                    Select all containing "{textSearch}"
                  </Checkbox>
                </label>
              </>
            )
          }
          <Divider className={css.divider} />
        </div>

        <div className={css.checkboxGroup}>
          {loadedSuppliers.map((supplier) => (
            <SupplierCheckbox
              key={supplier.value}
              onChange={(isChecked) => handleCheckboxChange(supplier, isChecked)}
              supplier={supplier}
              selectedSupplierIds={selectedSupplierIds}
              showSignalIcon={textSearch.length > 0 && signalSupplierIds.includes(supplier.value)}
            />
          ))}
        </div>
      </InfiniteScroll>
    );

  return (
    <div id="scrollableDiv" className={css.scrollableDiv} aria-label="scrollableDiv">
      {isInitialLoading ? <Skeleton active /> : menu}
    </div>
  );
}

export default InfiniteSuppliersChecklist;
