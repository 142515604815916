import React from "react";

import BookmarkIcon from "components/actions/Bookmark";
import { SHOW_ON_HOVER } from "lib/core_components/ShowOnHover";
import { SaveSupplierButton } from "./lists/SaveSupplierButton";
import { SaveToButton } from "./SaveToButton";
import { SharedSaveProps } from "./types";

type DisplayType = "bookmark" | "button";

type InRowSaveToButtonProps = SharedSaveProps & {
  displayType: DisplayType;
  isSaved: boolean;
  listCount: number;
};

export function InRowSaveToButton({ displayType, isSaved, ...rest }: InRowSaveToButtonProps) {
  const button =
    rest.entityType === "supplier" ? (
      <SaveSupplierButton supplierGuid={rest.entityId} />
    ) : (
      <SaveToButton {...rest} />
    );
  return (
    <div>
      {isSaved && displayType === "bookmark" && <BookmarkIcon colour="blue" filled />}
      {isSaved && displayType === "button" && button}
      {!isSaved && <span className={SHOW_ON_HOVER}>{button}</span>}
    </div>
  );
}
