import React from "react";
import { Alert, Button, Collapse, Divider, message, Popover, Tag } from "antd5";
import classnames from "classnames";

import { copyToClipboard } from "lib/clipboard";
import { useSearchOrganisations } from "lib/hooks/api/organisations/useSearchOrganisations";
import { useReferenceOrgs } from "lib/hooks/api/reference_orgs/useReferenceOrgs";
import { OrgSortBy, OrgSortOrder, SearchOrgPrimaryRole } from "lib/types/graphQLEnums";
import { isDefined } from "lib/utils";
import CandidateAliasSelection from "./alias_selection/CandidateAliasSelection";
import {
  AnchorLabel,
  ChildLabel,
  NewLabel,
  ParentLabel,
  RenamedLabel,
  ReplacedLabel,
} from "./OrgLabels";
import { CandidateItem, CandidateListPanel } from "./OrgListPanel";
import OrgUpdatePreview from "./OrgUpdatePreview";
import {
  CandidateOperation,
  MultipleEntitiesTypes,
  OrgToBeCreated,
  OrgWithStats,
  ProcessStages,
  QualifiedCandidate,
  ValidOrgsCount,
} from "./types";

import css from "./ReviewOrgCleanPage.module.scss";

type Props = {
  anchorOrg: OrgWithStats;
  qualifiedCandidates: Record<string, QualifiedCandidate>;
  formStateStringified: string;
  pageTitle: string;
  multipleEntitiesEditButtons?: (b: OrgWithStats) => JSX.Element;
  candidatesEditButton?: (b: OrgWithStats) => JSX.Element;
  aliases: string[];
  setAliases: (aliases: string[]) => void;
  orgPrimaryRole: "Supplier" | "Buyer";
  adminReview?: boolean;
};

type SplitOrgType = {
  original: OrgWithStats;
  splitInto: OrgWithStats[];
  splitIntoNew: OrgToBeCreated[];
};

type HierarchyOrgType = {
  original: OrgWithStats;
  relationToAnchor: "IS_PARENT" | "IS_CHILD";
  candidateAction:
    | { type: "RENAME"; newName: string }
    | { type: "MERGE"; replacedBy: OrgWithStats };
};

function ReviewOrgCleanPage({
  anchorOrg,
  qualifiedCandidates,
  formStateStringified,
  pageTitle,
  multipleEntitiesEditButtons,
  candidatesEditButton,
  aliases,
  setAliases,
  orgPrimaryRole,
  adminReview,
}: Props): JSX.Element {
  const {
    duplicates,
    unrelated,
    orgsToSplit,
    hierarchies,
    cancelledOrgs,
    multiEntDupe,
    incompleteMultiEnt,
    buyerUpdates,
  } = React.useMemo(() => {
    const duplicates = [];
    const unrelated = [];
    const orgsToSplit = [];
    const cancelledOrgs = [];
    const multiEntDupe = [];
    const hierarchies = [];
    const incompleteMultiEnt = [];
    const buyerUpdates = [];

    for (const qs of Object.values(qualifiedCandidates)) {
      // When a reviewer changes a buyer from another category to multiple entities, it temporarily moves into incomplete
      // because they need to go make further choices in the edit screen
      if (
        qs.processStage === ProcessStages.REQUIRES_CLEAN &&
        qs.qualification === CandidateOperation.MULTIPLE_ENTITIES
      ) {
        incompleteMultiEnt.push(qs.buyer);
      }
      switch (qs.qualification) {
        case CandidateOperation.DUPLICATE:
          duplicates.push(qs.buyer);
          break;
        case CandidateOperation.NOT_RELATED:
          unrelated.push(qs.buyer);
          break;
        case CandidateOperation.MULTIPLE_ENTITIES:
          if (qs.processStage === ProcessStages.CANCELLED) {
            cancelledOrgs.push({
              buyer: qs.buyer,
              note: qs.note,
              label: "MULTIPLE ENTITY - CANCELLED",
            });
          } else if (qs.cleaningData?.type === MultipleEntitiesTypes.DISTINCT) {
            const splitOrgObj: SplitOrgType = {
              original: qs.buyer,
              splitInto: qs.cleaningData.selectedDistinctBuyers || [],
              splitIntoNew: qs.cleaningData.newDistinctBuyers || [],
            };
            orgsToSplit.push(splitOrgObj);
          } else if (qs.cleaningData?.type === MultipleEntitiesTypes.HIERARCHICAL) {
            if (qs.cleaningData.validBuyersCount === ValidOrgsCount.UP_TO_ONE) {
              multiEntDupe.push(qs.buyer);
            } else if (qs.cleaningData.validBuyersCount === ValidOrgsCount.THREE_OR_MORE) {
              cancelledOrgs.push({
                buyer: qs.buyer,
                note: "Candidate buyer contains 3 or more valid buyers",
                label: "MULTIPLE ENTITY - THREE OR MORE VALID BUYERS",
              });
            } else {
              const hierarchyOrg: HierarchyOrgType = {
                original: qs.buyer,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                relationToAnchor: qs.cleaningData.relationToAnchor!,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                candidateAction: qs.cleaningData.candidateAction!,
              };
              hierarchies.push(hierarchyOrg);
            }
          }
          break;
        case CandidateOperation.UNSURE:
          cancelledOrgs.push({
            buyer: qs.buyer,
            note: qs.note,
            label: "UNSURE DUPLICATE",
          });
          break;
        case CandidateOperation.UPDATE_ATTRIBUTES:
          buyerUpdates.push(qs);
      }
    }

    return {
      duplicates,
      unrelated,
      orgsToSplit,
      hierarchies,
      cancelledOrgs,
      multiEntDupe,
      incompleteMultiEnt,
      buyerUpdates,
    };
  }, [qualifiedCandidates]);

  const { data: refOrgsToLink } = useReferenceOrgs({
    sourceIdentifier: buyerUpdates
      .flatMap((update) => [update.newValues.oscar_id, update.newValues.companies_house_id])
      .filter(isDefined)
      .map((id) => id.toString()),
  });

  const validationErrors = React.useMemo<React.ReactNode[]>(() => {
    if (!buyerUpdates.length || !refOrgsToLink) return [];

    const errors = [];

    const refOrgsById = Object.fromEntries(refOrgsToLink.map((r) => [r.sourceIdentifier, r]));

    for (const update of buyerUpdates) {
      const newOscarId = update.newValues.oscar_id;

      if (newOscarId) {
        const refOrg = refOrgsById[newOscarId];

        if (refOrg) {
          if (refOrg.organisation) {
            const o = refOrg.organisation;
            errors.push(
              <li key="oscar_id">
                Oscar ID <strong>{newOscarId}</strong> is already linked to{" "}
                <a href={`/${o.primaryRole.toLowerCase()}/${o.id}`}>{o.name}</a>
              </li>,
            );
          }
        } else {
          errors.push(
            <li key="oscar_id">
              No Oscar organisation exists in the DB with ID <strong>{newOscarId}</strong>
            </li>,
          );
        }
      }

      const newChId = update.newValues.companies_house_id;

      if (newChId) {
        const refOrg = refOrgsById[newChId];

        if (refOrg) {
          if (refOrg.organisation) {
            const o = refOrg.organisation;
            errors.push(
              <li key="companies_house_id">
                Companies House ID <strong>{newChId}</strong> is already linked to{" "}
                <a href={`/${o.primaryRole.toLowerCase()}/${o.id}`}>{o.name}</a>
              </li>,
            );
          }
        } else {
          errors.push(
            <li key="companies_house_id">
              No Companies House organisation exists in the DB with ID <strong>{newChId}</strong>
            </li>,
          );
        }
      }
    }

    return errors;
  }, [buyerUpdates, refOrgsToLink]);

  const { data: initialOrganisations } = useSearchOrganisations(
    {
      textSearch: "",
      sortBy: OrgSortBy.Relevance,
      sortOrder: OrgSortOrder.Desc,
      primaryRole: SearchOrgPrimaryRole[orgPrimaryRole],
      ids: [anchorOrg.guid],
      limit: 1,
      page: 1,
    },
    true,
    true,
  );

  React.useEffect(() => {
    if (initialOrganisations?.searchOrganisations.orgs[0]?.aliases) {
      const orgAliases = initialOrganisations.searchOrganisations.orgs[0].aliases;
      const newAliases = [...new Set([...aliases, ...orgAliases])];

      if (newAliases.length !== aliases.length) {
        setAliases(newAliases);
      }
    }
  }, [initialOrganisations, aliases, setAliases]);

  return (
    <div className={css.reviewPage}>
      <div className={css.scrollablePanel}>
        <h1>{pageTitle}</h1>
        <div className={css.anchorCard}>
          <h2>Anchor buyer</h2>
          <CandidateItem org={anchorOrg} orgPrimaryRole={orgPrimaryRole} />
        </div>
        <Alert
          className={css.validations}
          type={validationErrors.length ? "error" : "success"}
          showIcon
          message="Validations"
          description={
            validationErrors.length ? <ul>{validationErrors}</ul> : "Everything looks valid"
          }
        />
        <Collapse
          items={[
            {
              key: "formState",
              label: "Form state",
              extra: (
                <Button
                  size="small"
                  type="link"
                  onClick={(e) => {
                    e.stopPropagation();
                    copyToClipboard({ plain: formStateStringified });
                    void message.success("Copied formstate to clipbaord");
                  }}
                >
                  Copy to clipboard
                </Button>
              ),
              children: formStateStringified,
            },
          ]}
        />
        <CandidateAliasSelection
          aliases={aliases}
          setAliases={setAliases}
          adminReview={adminReview}
        />

        {buyerUpdates.map((b, i) => (
          <OrgUpdatePreview
            index={i}
            anchorOrg={anchorOrg}
            orgName={b.buyer.name}
            orgId={b.buyer.guid}
            newValues={b.newValues}
            key={b.buyer.guid}
          />
        ))}
      </div>
      <div className={css.scrollablePanel}>
        {incompleteMultiEnt.length > 0 && (
          <>
            <h2>Incomplete multiple entities</h2>
            <CandidateListPanel
              hideCount
              orgs={incompleteMultiEnt}
              orgPrimaryRole={orgPrimaryRole}
              className={css.reviewList}
              renderCTA={multipleEntitiesEditButtons}
            />
          </>
        )}
        {duplicates.length > 0 && (
          <>
            <h2>Direct duplicates</h2>
            <CandidateListPanel
              hideCount
              orgs={duplicates}
              orgPrimaryRole={orgPrimaryRole}
              className={css.reviewList}
              renderCTA={candidatesEditButton}
            />
          </>
        )}
        <h2>Multiple entities</h2>
        <div className={css.sectionContainer}>
          {orgsToSplit.length > 0 && (
            <>
              <ul className={css.sectionList}>
                {orgsToSplit.map((splitB) => (
                  <li key={splitB.original.guid} className={css.splitSummary}>
                    <h3>Previous buyer</h3>
                    <div className={css.buyerItem}>
                      <CandidateItem
                        org={splitB.original}
                        orgPrimaryRole={orgPrimaryRole}
                        renderCTA={multipleEntitiesEditButtons}
                      />
                    </div>
                    <h3>Split into</h3>
                    <div className={css.splitBuyersWrapper}>
                      {splitB.splitInto.map((sp) => (
                        <div key={sp.guid} className={css.buyerItem}>
                          <CandidateItem
                            org={sp}
                            orgPrimaryRole={orgPrimaryRole}
                            renderAdditionalContent={
                              sp.guid === anchorOrg.guid ? () => <AnchorLabel /> : undefined
                            }
                          />
                        </div>
                      ))}
                      {splitB.splitIntoNew.map((sn) => (
                        <div key={sn.guid} className={classnames(css.buyerItem, css.newBuyer)}>
                          <p>
                            {sn.name} <NewLabel />
                          </p>
                        </div>
                      ))}
                    </div>
                  </li>
                ))}
              </ul>
              <Divider />
            </>
          )}
          {hierarchies.length > 0 && (
            <>
              <ul className={css.sectionList}>
                {hierarchies.map((hb) => (
                  <li key={hb.original.guid} className={css.splitSummary}>
                    <h3>Previous buyer</h3>
                    <div className={css.buyerItem}>
                      <CandidateItem
                        org={hb.original}
                        renderCTA={multipleEntitiesEditButtons}
                        orgPrimaryRole={orgPrimaryRole}
                      />
                    </div>
                    <h3>Relationship</h3>
                    <div className={css.splitBuyersWrapper}>
                      <div key={anchorOrg.guid} className={css.buyerItem}>
                        <CandidateItem
                          renderAdditionalContent={() => (
                            <div>
                              {hb.relationToAnchor === "IS_CHILD" ? (
                                <ParentLabel />
                              ) : (
                                <ChildLabel />
                              )}
                              <AnchorLabel />
                            </div>
                          )}
                          org={anchorOrg}
                          orgPrimaryRole={orgPrimaryRole}
                        />
                      </div>
                      <div key={"secondOrg"} className={css.buyerItem}>
                        {hb.candidateAction.type === "RENAME" ? (
                          <CandidateItem
                            org={{ ...hb.original, name: hb.candidateAction.newName }}
                            orgPrimaryRole={orgPrimaryRole}
                            renderAdditionalContent={() => (
                              <div>
                                {hb.relationToAnchor === "IS_CHILD" ? (
                                  <ChildLabel />
                                ) : (
                                  <ParentLabel />
                                )}
                                <RenamedLabel />
                              </div>
                            )}
                          />
                        ) : (
                          <CandidateItem
                            org={hb.candidateAction.replacedBy}
                            orgPrimaryRole={orgPrimaryRole}
                            renderAdditionalContent={() => (
                              <div>
                                {hb.relationToAnchor === "IS_CHILD" ? (
                                  <ChildLabel />
                                ) : (
                                  <ParentLabel />
                                )}
                                <ReplacedLabel />
                              </div>
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <Divider />
            </>
          )}
          {multiEntDupe.length > 0 && (
            <ul className={classnames(css.sectionList, css.paddedWrapper)}>
              {multiEntDupe.map((mb) => (
                <div key={mb.guid}>
                  <CandidateItem
                    renderCTA={multipleEntitiesEditButtons}
                    key={mb.guid}
                    className={css.buyerItem}
                    org={mb}
                    orgPrimaryRole={orgPrimaryRole}
                    renderAdditionalContent={() => <Tag>MERGE INTO ANCHOR</Tag>}
                  />
                </div>
              ))}
            </ul>
          )}
        </div>

        {cancelledOrgs.length > 0 && (
          <>
            <h2>Unsure, cancelled & reported candidates</h2>

            <div className={css.sectionContainer}>
              <ul className={classnames(css.sectionList, css.paddedWrapper)}>
                {cancelledOrgs.map((cb) => (
                  <CandidateItem
                    className={css.buyerItem}
                    key={cb.buyer.guid}
                    org={cb.buyer}
                    orgPrimaryRole={orgPrimaryRole}
                    renderCTA={candidatesEditButton}
                    renderAdditionalContent={() => (
                      <div>
                        <Popover content={cb.note}>
                          <span className={css.reviewNoteTrigger}>Review notes</span>
                        </Popover>
                        <Tag>{cb.label}</Tag>
                      </div>
                    )}
                  />
                ))}
              </ul>
            </div>
          </>
        )}
        {unrelated.length > 0 && (
          <>
            <h2>Not mentioned</h2>
            <CandidateListPanel
              renderCTA={candidatesEditButton}
              orgs={unrelated}
              orgPrimaryRole={orgPrimaryRole}
              className={css.reviewList}
              hideCount
            />
          </>
        )}
      </div>
    </div>
  );
}

export default ReviewOrgCleanPage;
