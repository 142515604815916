import React, { useEffect, useRef, useState } from "react";

import { useNotifications } from "../../../lib/hooks/api/notifications/useNotifications";
import { useLocalStorage } from "../../../lib/hooks/useLocalStorage";
import {
  bell01,
  box,
  building07,
  currencyPoundCircle,
  file02,
  grid01,
  helpCircle,
  home05,
  layoutLeft,
  list,
  presentationChart02,
  settings01,
  target04,
  userCircle,
  wallet02,
} from "../../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { useSubscription } from "../../../lib/providers/Subscription";
import { isOnListsPath, isOnReportsPath, isOnSettingsPath, routes } from "../../../lib/routes";
import { Flex } from "../../../styles/utility-components";
import NavItem from "./NavItem";
import NotificationPanel from "./NotificationPanel";
import SubNavItem from "./SubNavItem";
import TeamDropdown from "./TeamDropdown";
import * as S from "./VerticalNav.styles";

import LogoURL from "../../../../assets/images/stotles_logo.svg";

function VerticalNav() {
  const [storedExpanded, setStoredExpanded] = useLocalStorage("verticalNavExpanded", false);
  const [tempExpanded, setTempExpanded] = useState(storedExpanded);
  const [delayedExpanded, setDelayedExpanded] = useState(storedExpanded);
  const [mouseOverNav, setMouseOverNav] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const { data: notifications } = useNotifications({ enabled: !!window.currentUser });
  const unreadNotificationCount =
    notifications?.notifications.filter((n) => !n.readAt).length || undefined;

  const stayExpanded = storedExpanded || isOnSettingsPath;
  const expanded = stayExpanded || tempExpanded;

  const [isListsExpanded, setIsListsExpanded] = useState(isOnListsPath);
  const [isReportsExpanded, setIsReportsExpanded] = useState(isOnReportsPath);

  const mainNavRef = useRef<HTMLDivElement>(null);
  const listsSubNavRef = useRef<HTMLDivElement>(null);
  const reportsSubNavRef = useRef<HTMLDivElement>(null);

  // paywalls
  const subscription = useSubscription();
  const buyerSupplierPaywall = !subscription.hasDataTypes("BUYERS|SUPPLIERS");
  const frameworksPaywall = !subscription.hasDataTypes("FRAMEWORKS");
  const documentsPaywall = !subscription.hasDataTypes("DOCUMENTS");
  const contactsPaywall = !subscription.hasDataTypes("CONTACTS");

  // store last page visited for going back from settings
  useEffect(() => {
    if (!isOnSettingsPath) {
      sessionStorage.setItem("lastMainNavPageVisited", window.location.pathname);
    }
  }, []);

  // expand on hover logic
  useEffect(() => {
    if (expanded) {
      const timeout = setTimeout(() => {
        setDelayedExpanded(true);
      }, 250);

      return () => clearTimeout(timeout);
    }

    // needed to stop flicker on dropdown close
    if (!expanded) {
      const timeout = setTimeout(() => {
        setDelayedExpanded(false);
      }, 1);

      return () => clearTimeout(timeout);
    }
  }, [expanded]);

  useEffect(() => {
    if (!mouseOverNav && !dropdownOpen && !notificationsOpen) {
      setTempExpanded(false);
    }
  }, [mouseOverNav, dropdownOpen, notificationsOpen]);

  function handleMouseEnter() {
    setMouseOverNav(true);
    setTempExpanded(true);
  }

  function handleMouseLeave() {
    setMouseOverNav(false);
    if (!dropdownOpen && !notificationsOpen) {
      setTempExpanded(false);
    }
  }

  return (
    <S.StaticWrapper stayExpanded={stayExpanded || isOnSettingsPath}>
      <NotificationPanel
        open={notificationsOpen}
        onClose={() => setNotificationsOpen(false)}
        mainNavRef={mainNavRef}
      />
      <S.ExpandableWrapper
        expanded={expanded}
        showShadow={!stayExpanded && !notificationsOpen && expanded}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <S.TopRow>
          <S.Logo src={LogoURL} />
          {delayedExpanded && (
            <S.FadeInWrapper>
              <TeamDropdown onOpenChange={(open) => setDropdownOpen(open)} />
              <S.ExpandButton onClick={() => setStoredExpanded(!storedExpanded)}>
                <UIcon svg={layoutLeft} size={16} color={"inherit"} />
              </S.ExpandButton>
            </S.FadeInWrapper>
          )}
        </S.TopRow>
        <Flex column justifyContent="space-between" height="100%" width="100%">
          <S.MainNav ref={mainNavRef}>
            <NavItem
              expanded={delayedExpanded}
              title="Home"
              icon={home05}
              path={routes.home}
              shouldAnimate={!stayExpanded}
            />
            <NavItem
              expanded={delayedExpanded}
              title="Notifications"
              icon={bell01}
              onClick={() => setNotificationsOpen(!notificationsOpen)}
              badge={unreadNotificationCount}
              shouldAnimate={!stayExpanded}
            />
            <S.Divider />
            <NavItem
              expanded={delayedExpanded}
              title="Notices"
              icon={file02}
              path={routes.notices}
              shouldAnimate={!stayExpanded}
            />
            <NavItem
              expanded={delayedExpanded}
              title="Frameworks"
              icon={grid01}
              path={routes.frameworks}
              paywall={frameworksPaywall ? "FRAMEWORKS" : undefined}
              shouldAnimate={!stayExpanded}
            />
            <NavItem
              expanded={delayedExpanded}
              title="Invoiced spend"
              icon={currencyPoundCircle}
              paywall={buyerSupplierPaywall ? "SPEND" : undefined}
              path={routes.spend}
              shouldAnimate={!stayExpanded}
            />
            <NavItem
              expanded={delayedExpanded}
              title="Documents"
              icon={box}
              path={routes.documents}
              paywall={documentsPaywall ? "DOCUMENTS" : undefined}
              shouldAnimate={!stayExpanded}
            />
            <NavItem
              expanded={delayedExpanded}
              title="Contacts"
              icon={userCircle}
              path={routes.contacts}
              paywall={contactsPaywall ? "CONTACTS" : undefined}
              shouldAnimate={!stayExpanded}
            />
            <NavItem
              expanded={delayedExpanded}
              title="Buyers"
              icon={wallet02}
              path={routes.buyers}
              shouldAnimate={!stayExpanded}
            />
            <NavItem
              expanded={delayedExpanded}
              title="Suppliers"
              icon={building07}
              path={routes.suppliers}
              paywall={buyerSupplierPaywall ? "SUPPLIERS" : undefined}
              shouldAnimate={!stayExpanded}
            />
            <S.Divider />
            <NavItem
              expanded={delayedExpanded}
              title="Opportunities"
              icon={target04}
              path={routes.opportunities}
              shouldAnimate={!stayExpanded}
            />
            <NavItem
              expanded={delayedExpanded}
              title="Lists"
              icon={list}
              onClick={() => setIsListsExpanded(!isListsExpanded)}
              isSubNavExpanded={isListsExpanded}
              shouldAnimate={!stayExpanded}
            >
              <S.SubNavWrapper
                subNavExpanded={isListsExpanded && delayedExpanded}
                height={listsSubNavRef.current?.scrollHeight || "100%"}
                ref={listsSubNavRef}
              >
                <SubNavItem title="Buyer lists" path={routes.buyerLists} />
                <SubNavItem title="Notice lists" path={routes.noticeLists} />
              </S.SubNavWrapper>
            </NavItem>
            <NavItem
              expanded={delayedExpanded}
              title="Reports"
              icon={presentationChart02}
              onClick={() => setIsReportsExpanded(!isReportsExpanded)}
              isSubNavExpanded={isReportsExpanded}
              shouldAnimate={!stayExpanded}
            >
              <S.SubNavWrapper
                subNavExpanded={isReportsExpanded && delayedExpanded}
                ref={reportsSubNavRef}
                height={reportsSubNavRef.current?.scrollHeight || 0}
              >
                <SubNavItem title="Custom briefings" path={routes.customBriefings} />
                <SubNavItem title="Industry reports" path={routes.industryReports} />
              </S.SubNavWrapper>
            </NavItem>
          </S.MainNav>
          <S.FooterNav>
            <NavItem
              expanded={delayedExpanded}
              title="Settings"
              icon={settings01}
              path={routes.settings}
              shouldAnimate={!stayExpanded}
            />
            <NavItem
              expanded={delayedExpanded}
              title="Help"
              icon={helpCircle}
              path="https://help.stotles.com/"
              shouldAnimate={!stayExpanded}
            />
          </S.FooterNav>
        </Flex>
      </S.ExpandableWrapper>
    </S.StaticWrapper>
  );
}

export default VerticalNav;
