import React, { useEffect } from "react";
import { DateTime } from "luxon";

import { FeedPreviewOutputs } from "components/onboarding/FeedPreviewOutputs";
import { FeedPreviewPageSources, MatchCountMap } from "components/onboarding/SharedOnboarding";
import { SelfServeQuery, Signal, SignalCategory } from "lib/StotlesApi";
import { useFeedPreview } from "../../../lib/hooks/api/useFeedPreview";

export const DEFAULT_PREVIEW_LOOKBACK = DateTime.now().startOf("day").minus({ months: 6 });

type Props = {
  feedCriteria: SelfServeQuery;
  pageSource: FeedPreviewPageSources;
  onResultCountsChange: (newCounts: Partial<Record<SignalCategory, MatchCountMap>>) => void;
  setFeedPreviewLoading?: (loading: boolean) => void;
  feedPreviewLoading?: boolean;
  companyId?: number;
};

export function FeedSettingsPreview({
  feedCriteria,
  pageSource,
  onResultCountsChange,
  companyId,
  setFeedPreviewLoading,
  feedPreviewLoading = false,
}: Props): JSX.Element {
  const { isLoading, data } = useFeedPreview(feedCriteria, companyId);

  // TODO: This is only requied while the old feed preview is still in use
  useEffect(() => {
    if (isLoading) {
      setFeedPreviewLoading?.(true);
    } else {
      setFeedPreviewLoading?.(false);
    }
  }, [isLoading, setFeedPreviewLoading]);

  // TODO: This could be refactored so that the parent component can load the counts
  useEffect(() => {
    if (data?.recordCounts) {
      onResultCountsChange(data?.recordCounts);
    }
  }, [data?.recordCounts, onResultCountsChange]);

  return (
    <FeedPreviewOutputs
      signalMap={data?.signalMap ?? new Map<string, Signal>()}
      totalResults={data?.totalResults ?? 0}
      recordPreviews={data?.recordPreviews ?? []}
      isLoading={isLoading || feedPreviewLoading}
      guidMatchMap={data?.guidMatchMap ?? {}}
      pageSource={pageSource}
      feedCriteria={feedCriteria}
      companyId={companyId}
    />
  );
}
