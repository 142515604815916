/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubscriptionDowngradeRequest
 */
export interface SubscriptionDowngradeRequest {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDowngradeRequest
     */
    newPlan: SubscriptionDowngradeRequestNewPlanEnum;
}


/**
 * @export
 */
export const SubscriptionDowngradeRequestNewPlanEnum = {
    Freemium: 'freemium',
    Basic: 'basic',
    Growth: 'growth',
    Expert: 'expert'
} as const;
export type SubscriptionDowngradeRequestNewPlanEnum = typeof SubscriptionDowngradeRequestNewPlanEnum[keyof typeof SubscriptionDowngradeRequestNewPlanEnum];


/**
 * Check if a given object implements the SubscriptionDowngradeRequest interface.
 */
export function instanceOfSubscriptionDowngradeRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "newPlan" in value;

    return isInstance;
}

export function SubscriptionDowngradeRequestFromJSON(json: any): SubscriptionDowngradeRequest {
    return SubscriptionDowngradeRequestFromJSONTyped(json, false);
}

export function SubscriptionDowngradeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionDowngradeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newPlan': json['new_plan'],
    };
}

export function SubscriptionDowngradeRequestToJSON(value?: SubscriptionDowngradeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'new_plan': value.newPlan,
    };
}

