import React from "react";
import { Button, Modal, Select } from "antd5";

import BuyerMultiselect from "components/form_components/BuyerMultiselect";
import { useStotlesApi } from "lib/stotlesApiContext";
import { fullDocumentTypeMap, getDocumentTypeLabel } from "../../components/documents/utils";

import css from "./DocumentComponents.module.scss";

const { Option } = Select;

type DocumentBuyerSelectProps = {
  initialBuyerGuid?: string;
  onBuyerChange: (buyerGuid: string | undefined) => void;
};

export function DocumentBuyerSelect({
  initialBuyerGuid,
  onBuyerChange,
}: DocumentBuyerSelectProps): JSX.Element {
  const api = useStotlesApi();

  const [buyerIds, setBuyerIds] = React.useState<number[]>();

  React.useEffect(() => {
    async function fetchData() {
      if (initialBuyerGuid) {
        const buyers = await api.searchBuyers({ guids: [initialBuyerGuid] });
        setBuyerIds([buyers.results[0].id]);
      }
    }
    void fetchData();
  }, [api, initialBuyerGuid]);

  const handleBuyerListChange = async (updatedBuyerIds: number[]) => {
    //for now we only support single buyers
    if (updatedBuyerIds?.length > 0) {
      const buyerId = updatedBuyerIds.slice(-1);
      setBuyerIds(buyerId);
      const buyers = await api.searchBuyers({ id: buyerId });
      if (buyers.results?.length > 0) {
        const buyer = buyers.results[0];
        onBuyerChange(buyer.guid);
      }
    } else {
      setBuyerIds(undefined);
      onBuyerChange(undefined);
    }
  };

  return (
    <BuyerMultiselect
      buyerIds={buyerIds}
      onBuyerIdsChange={handleBuyerListChange}
      showVerified
      identifier="id"
      noMaxTagCount
      customPlaceholder="e.g. Department for Work & Pensions, London Borough of Camden, etc. ..."
    />
  );
}

export function DocumentTypeSelect({
  defaultValue,
  onChange,
}: {
  defaultValue?: string;
  onChange: (value: string) => void;
}): JSX.Element {
  return (
    <Select
      placeholder="Select a document type"
      onChange={onChange}
      className={css.documentType}
      defaultValue={defaultValue}
    >
      <Option value="general">Reports and accounts</Option>
      <Option value="meeting">Strategy</Option>
      <Option value="delivery-plans">Budgets</Option>
    </Select>
  );
}

export function DocumentTypeSelectNew({
  defaultValue,
  onChange,
}: {
  defaultValue?: string;
  onChange: (value: string) => void;
}): JSX.Element {
  return (
    <Select
      placeholder="Select a document type"
      onChange={onChange}
      className={css.documentType}
      defaultValue={defaultValue}
    >
      {Object.keys(fullDocumentTypeMap).map((key) => (
        <Option key={key} value={key}>
          {getDocumentTypeLabel(key)}
        </Option>
      ))}
    </Select>
  );
}

type DeleteDocumentProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  fileName: string;
  isLoading?: boolean;
};

export function DeleteDocumentModal({
  isOpen,
  onClose,
  onDelete,
  fileName,
  isLoading,
}: DeleteDocumentProps): JSX.Element {
  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={
        <>
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button
            key="delete"
            type="primary"
            danger
            onClick={() => {
              onDelete();
              onClose();
            }}
            loading={isLoading}
          >
            Delete
          </Button>
        </>
      }
      title={`Delete "${fileName}"?`}
    >
      <p>Are you sure you want to delete this document? This action cannot be undone.</p>
    </Modal>
  );
}
