import React from "react";

import { blue500, brandRed, purple, yellow400 } from "../../lib/themes/colors";

type Props = {
  fill?: string;
  className?: string;
};

export function FreePlan({
  fill = brandRed, // Red colour
  className,
}: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className={className} fill="none">
      <g id="Geometric">
        <path
          id="Rectangle 301"
          opacity="0.3"
          d="M0 16C-1.93129e-07 11.5817 3.58172 8 8 8L8 16L0 16Z"
          fill={fill}
        />
        <path
          id="Rectangle 302"
          opacity="0.5"
          d="M16 16C11.5817 16 8 12.4183 8 8L16 8L16 16Z"
          fill={fill}
        />
        <path
          id="Rectangle 303"
          opacity="0.2"
          d="M8 0C12.4183 0 16 3.58172 16 8L8 8L8 0Z"
          fill={fill}
        />
        <path
          id="Rectangle 304"
          opacity="0.7"
          d="M0 0C4.41828 0 8 3.58172 8 8L0 8L0 0Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export function BasicPlan({ fill = yellow400, className }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className={className} fill="none">
      <g id="Geometric">
        <path
          id="Rectangle 301"
          opacity="0.8"
          d="M0 16C-1.93129e-07 11.5817 3.58172 8 8 8L8 16L0 16Z"
          fill={fill}
        />
        <path
          id="Rectangle 302"
          opacity="0.4"
          d="M16 8C16 12.4183 12.4183 16 8 16L8 8L16 8Z"
          fill={fill}
        />
        <path
          id="Rectangle 303"
          opacity="0.9"
          d="M8 0C12.4183 0 16 3.58172 16 8L8 8V0Z"
          fill={fill}
        />
        <path
          id="Rectangle 304"
          opacity="0.6"
          d="M0 0C4.41828 0 8 3.58172 8 8L0 8L0 0Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export function GrowthPlan({
  fill = purple, // Purple colour
  className,
}: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 20 20" fill="none">
      <g id="Growth Plan Icon">
        <path
          opacity="0.3"
          d="M0 10C-2.41411e-07 15.5228 4.47715 20 10 20L10 10L0 10Z"
          fill={fill}
        />
        <path opacity="0.5" d="M20 10C20 15.5228 15.5228 20 10 20L10 10L20 10Z" fill={fill} />
        <path opacity="0.2" d="M10 0C15.5228 0 20 4.47715 20 10L10 10L10 0Z" fill={fill} />
        <path opacity="0.7" d="M0 0C5.52285 0 10 4.47715 10 10L0 10L0 0Z" fill={fill} />
      </g>
    </svg>
  );
}

export function ExpertPlan({
  fill = blue500, // Blue colour
  className,
}: Props) {
  return (
    <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Expert Plan Icon">
        <path
          id="Rectangle 296"
          opacity="0.3"
          d="M0 10C-2.41411e-07 15.5228 4.47715 20 10 20L10 10L0 10Z"
          fill={fill}
        />
        <path
          id="Rectangle 297"
          opacity="0.5"
          d="M20 10C20 15.5228 15.5228 20 10 20L10 10L20 10Z"
          fill={fill}
        />
        <path
          id="Rectangle 298"
          opacity="0.2"
          d="M10 0C15.5228 0 20 4.47715 20 10L10 10L10 0Z"
          fill={fill}
        />
        <path
          id="Rectangle 299"
          opacity="0.7"
          d="M0 10C-2.41411e-07 4.47715 4.47715 -1.95703e-07 10 -4.37114e-07L10 10L0 10Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export const planIcons = {
  free: <FreePlan />,
  basic: <BasicPlan />,
  growth: <GrowthPlan />,
  expert: <ExpertPlan />,
};
