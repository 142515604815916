import React from "react";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Spin, Tooltip } from "antd5";

import { pluralise } from "lib/utils";

import css from "./RecordPreviewHeading.module.scss";

type Props = {
  totalResults: number;
  displayedResults: number;
  isLoading?: boolean;
  monthlyAverageResults: number;
  recordListCountLabel?: string;
};

function CautionMessage({
  monthlyAverageResults,
  isLoading,
}: {
  monthlyAverageResults: number;
  isLoading: boolean;
}) {
  if (isLoading) {
    return null;
  }

  if (monthlyAverageResults > 9 && monthlyAverageResults < 210) {
    return null;
  }

  const message =
    monthlyAverageResults >= 210
      ? "These are more results than the majority of our users tend to see in their Feed per month. Consider using more specific keywords to narrow down your search and build a more relevant Feed."
      : "These are fewer results than the majority of our users tend to see in their Feed per month. Consider adding or broadening some keywords relevant to your business.";

  return (
    <Tooltip title={message} trigger="hover" overlayClassName={css.cautionPopup}>
      <div className={css.cautionMessage}>
        <ExclamationCircleOutlined /> Caution
      </div>
    </Tooltip>
  );
}

export function RecordPreviewHeading({
  totalResults,
  displayedResults,
  isLoading,
  monthlyAverageResults,
  recordListCountLabel,
}: Props): JSX.Element {
  return (
    <div className={css.recordListHeading}>
      <div className={css.recordListCount}>
        {pluralise(totalResults, "notice")} matched {recordListCountLabel}
      </div>
      <CautionMessage
        isLoading={isLoading ?? false}
        monthlyAverageResults={monthlyAverageResults}
      />
      {isLoading && (
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 22 }} spin />}
          className={css.loadingSpinner}
        />
      )}
      {!isLoading && (
        <div className={css.filterRow}>
          <p className={css.currentResults}>
            Showing <b>{displayedResults}</b> out of <b>{totalResults}</b> notices
          </p>
        </div>
      )}
    </div>
  );
}
