import React from "react";
import styled from "@emotion/styled";
import { Button, Modal } from "antd5";
import { Flex, Text } from "styles/utility-components";

import HotMug from "lib/icons/HotMug";

export default function SubscriptionDowngradeModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      closeIcon={null}
      footer={null}
      styles={{
        content: { padding: 0 },
      }}
      width={450}
    >
      <Flex column>
        <IconBackground>
          <HotMug />
        </IconBackground>
        <BodyWrapper>
          <Text h2>Account manager notified.</Text>
          <Text body>
            We have let your account manager know you are looking to change plan. They will be in
            touch to discuss next steps.
          </Text>
          <Flex alignSelf="end">
            <Button type="primary" onClick={onClose}>
              Got it
            </Button>
          </Flex>
        </BodyWrapper>
      </Flex>
    </Modal>
  );
}

const IconBackground = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.sysPrimarySubtle,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "16px",
  height: "340px",
  border: 0,
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
}));

const BodyWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column" as const,
  padding: "32px",
  gap: "16px",
}));
