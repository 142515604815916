import React from "react";
import styled from "@emotion/styled";
import { App } from "antd5";
import { Flex } from "styles/utility-components";

import PaywallPopover from "components/paywall/PaywallPopover";
import { useSavedSuppliers } from "lib/hooks/api/signals/useSavedSuppliers";
import { useUpdateSignalSettings } from "lib/hooks/api/signals/useUpdateSignalSettings";
import PaywallStar from "lib/icons/PaywallStar";
import UIcon from "lib/icons/untitled_ui/UIcon";
import { useCheckSubscription } from "lib/providers/ProHelper";

export function SaveToCompetitorOrPartner({ supplierGuid }: { supplierGuid: string }) {
  const { message } = App.useApp();

  const { data: savedSuppliers } = useSavedSuppliers();

  const { authorised: hasSuppliers } = useCheckSubscription("SUPPLIERS", {
    "Context source": "Save Supplier",
  });

  const { partnerGuids, competitorGuids } = savedSuppliers || {
    partnerGuids: [],
    competitorGuids: [],
  };
  const isPartner = partnerGuids.some((guid) => guid === supplierGuid);
  const isCompetitor = competitorGuids.some((guid) => guid === supplierGuid);

  const { mutate: updateSignalSettings } = useUpdateSignalSettings({
    onSuccess: (_data, variables, _context) => {
      const { competitorIds, partnerIds } = variables.input;
      if (competitorIds !== undefined && competitorIds !== null) {
        message.success("Competitor signals saved successfully");
      }
      if (partnerIds !== undefined && partnerIds !== null) {
        message.success("Partner signals saved successfully");
      }
    },
    onError: () => {
      message.error("An error occurred saving competitor and partner signals");
    },
  });

  const onClick = (signalType: "competitor" | "partner") => {
    if (!hasSuppliers) {
      return;
    }
    if (signalType === "competitor") {
      if (isCompetitor) {
        updateSignalSettings({
          input: {
            competitorIds: competitorGuids.filter(
              (competitorGuid) => competitorGuid !== supplierGuid,
            ),
          },
        });
      } else {
        updateSignalSettings({
          input: { competitorIds: [...competitorGuids, supplierGuid].flat() },
        });
      }
    }
    if (signalType === "partner") {
      if (isPartner) {
        updateSignalSettings({
          input: {
            partnerIds: partnerGuids.filter((partnerId) => partnerId !== supplierGuid),
          },
        });
      } else {
        updateSignalSettings({
          input: { partnerIds: [...partnerGuids, supplierGuid].flat() },
        });
      }
    }
  };

  return (
    <Flex flexDirection="column" gap="16px">
      <h3>Save to a list</h3>
      <PaywallPopover featureType="COMPETITORS" contextSource={"Supplier search"}>
        <SaveToOption onClick={() => onClick("competitor")}>
          <LeftAlign>My competitors {!hasSuppliers && <PaywallStar />}</LeftAlign>
          <RightAlign>
            {isCompetitor ? (
              <UIcon icon="bookmark" size={16} ariaLabel="bookmark" />
            ) : (
              <UIcon icon="signal01" size={16} ariaLabel="signal" />
            )}
          </RightAlign>
        </SaveToOption>
      </PaywallPopover>
      <PaywallPopover featureType="PARTNERS" contextSource={"Supplier search"}>
        <SaveToOption onClick={() => onClick("partner")}>
          <LeftAlign>My partners {!hasSuppliers && <PaywallStar />}</LeftAlign>
          <RightAlign>
            {isPartner ? (
              <UIcon icon="bookmark" size={16} ariaLabel="bookmark" />
            ) : (
              <UIcon icon="signal01" size={16} ariaLabel="signal" />
            )}
          </RightAlign>
        </SaveToOption>
      </PaywallPopover>
    </Flex>
  );
}

const SaveToOption = styled.div(({ theme }) => ({
  padding: "16px 24px",
  border: "1px solid",
  borderRadius: 4,
  borderColor: theme.colors.sysBorderPrimary,
  cursor: "pointer",
  transition: "background-color 300ms ease-out, border-color 300ms ease-out",
  "&:hover": {
    backgroundColor: theme.colors.sysPrimarySubtle,
    borderColor: theme.colors.sysPrimaryDefault,
  },
}));

const LeftAlign = styled.div({
  display: "inline-block",
});

const RightAlign = styled.div({
  float: "right",
});
