import React, { forwardRef } from "react";
import styled from "@emotion/styled";

import { chevronDown } from "../../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { Text } from "../../../styles/utility-components";

type DropdownButtonProps = {
  onClick?: () => void;
  children: string;
};

// In the future this should just be a wrapper of a standard button component with the dropdown icon
const DropdownButton = forwardRef<HTMLButtonElement, DropdownButtonProps>(
  ({ children, ...rest }, ref) => {
    return (
      <Wrapper ref={ref} {...rest}>
        <Text fontWeight="500">{children}</Text>
        <UIcon color={"inherit"} svg={chevronDown} size={12} />
      </Wrapper>
    );
  },
);

DropdownButton.displayName = "DropdownButton";

export default DropdownButton;

const Wrapper = styled.button(({ theme }) => ({
  // button reset
  border: "none",
  background: "none",
  outline: "none",

  display: "flex",
  alignItems: "center",
  width: "fit-content",
  padding: "2px 10px 0 12px", // optical alignment
  height: 32,
  gap: "8px",
  cursor: "pointer",
  color: theme.colors.sysTextDefault,
  borderRadius: 4,
  transition: "background-color 0.3s",
  userSelect: "none",
  whiteSpace: "nowrap",

  "&:hover": {
    backgroundColor: theme.colors.sysPrimarySubtle,
  },

  ['&[data-state="open"]']: {
    backgroundColor: theme.colors.sysPrimarySubtle,
  },
}));
