import * as React from "react";

export default function HotMug(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.1364 157.322L64.3438 157.246V188.356C64.3438 189.059 64.9171 189.63 65.6238 189.63H142.424C143.13 189.63 143.704 189.059 143.704 188.356V81.7112C143.704 79.1298 141.603 77.0371 139.01 77.0371H69.0371C66.4445 77.0371 64.3438 79.1298 64.3438 81.7112V103.886L62.1364 103.809C61.8771 103.8 61.6174 103.796 61.3571 103.796C47.9718 103.796 37.0371 115.739 37.0371 130.566C37.0371 145.391 47.9718 157.336 61.3571 157.336C61.6174 157.336 61.8771 157.332 62.1364 157.322ZM62.3808 147.096C62.0413 147.125 61.6998 147.138 61.3571 147.138C53.2779 147.138 46.8789 139.649 46.8789 130.566C46.8789 121.483 53.2779 113.994 61.3571 113.994C61.6998 113.994 62.0413 114.007 62.3808 114.034L64.3438 114.191V146.939L62.3808 147.096Z"
        fill="white"
        stroke="#2569F9"
        strokeWidth="3.7037"
        strokeLinejoin="round"
      />
      <path
        d="M131.852 86.1953V113.334M131.852 121.751V128.778"
        stroke="#4F86FA"
        strokeWidth="3.7037"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5234 177.777H23.7043M182.412 177.777H186.667M152.593 177.777H175.449M32.5932 177.777H58.4511"
        stroke="#2569F9"
        strokeWidth="3.7037"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.703 11.8516C99.7522 18.0938 97.7773 23.5259 97.7773 28.1479C97.7773 36.3793 104.672 40.0655 104.672 48.9819C104.672 53.5074 102.374 58.0008 97.7773 62.4621"
        stroke="#4F86FA"
        strokeWidth="3.7037"
        strokeLinecap="round"
      />
      <path
        d="M85.9258 28.1484C84.634 36.3154 90.3703 37.8258 90.3703 45.1478C90.3703 48.8641 88.8888 52.5803 85.9258 56.2966"
        stroke="#4F86FA"
        strokeWidth="3.7037"
        strokeLinecap="round"
      />
      <path
        d="M113.77 24.4082C111.813 29.4737 112.912 32.5612 113.77 34.3786C115.604 38.2632 118.518 41.6164 118.518 45.5692C118.518 50.1604 116.936 54.5787 113.77 58.8245"
        stroke="#4F86FA"
        strokeWidth="3.7037"
        strokeLinecap="round"
      />
      <path
        d="M69.6289 84.4444C69.6289 83.6261 70.2926 82.9629 71.1104 82.9629H99.2585V185.185H71.1104C70.2926 185.185 69.6289 184.521 69.6289 183.704V84.4444Z"
        fill="#CADBFE"
      />
    </svg>
  );
}
