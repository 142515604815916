import React from "react";
import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { App } from "antd5";
import { sidebarWidthExpanded } from "styles/constants";
import { Text } from "styles/utility-components";

import { useDowngrade } from "lib/hooks/api/subscription/useDowngrade";
import { usePqlActioned } from "lib/hooks/api/users/usePqlActioned";
import { EventNames, logEvent } from "lib/tracking";
import { SubscriptionEnum } from "lib/types/models";
import { useSupportChat } from "lib/utils/useSupportChat";

export function PlanActionButton({
  plan,
  currentPlan,
  triggerBookADemoForm,
  triggerDowngradeModal,
  scrollToPlanComparison,
  tableHeader = false,
}: {
  plan: SubscriptionEnum;
  currentPlan: SubscriptionEnum;
  triggerBookADemoForm: (value: boolean) => void;
  triggerDowngradeModal: (value: boolean) => void;
  scrollToPlanComparison?: () => void;
  tableHeader?: boolean;
}): JSX.Element {
  const { message } = App.useApp();
  const { openSupportChat } = useSupportChat();

  const { mutate: mutateDowngrade } = useDowngrade();
  const { mutate: mutatePql } = usePqlActioned();

  const upgradeToBasic = () => {
    // This in on a <a href="..."> so we don't need to navigate to the page here
    mutatePql({
      actionType: "Upgrade to basic",
      pageUrl: window.location.href,
    });
  };

  const upgradeToGrowthOrExpert = () => {
    triggerBookADemoForm(true);
    mutatePql({
      actionType: "Book a demo in-app",
      pageUrl: window.location.href,
    });
  };

  const upgrade = (newPlan: SubscriptionEnum) => {
    logEvent(EventNames.planUpgradeClicked, {
      "Context source": tableHeader ? "Plan comparison table" : "Plan card",
      "Current plan": currentPlan,
      "Requested plan": newPlan,
    });

    if (newPlan === "growth" || newPlan === "expert") {
      upgradeToGrowthOrExpert();
    }
    if (newPlan === "basic") {
      upgradeToBasic();
    }
  };

  const downgradeBasicToFree = () => {
    openSupportChat("Downgrade to Free from Basic");
  };

  const downgradeFromGrowthOrExpert = (newPlan: SubscriptionEnum) => {
    mutateDowngrade(
      {
        newPlan,
      },
      {
        onSuccess: () => {
          triggerDowngradeModal(true);
        },
        onError: () => {
          message.error(
            "Failed to request subscription downgrade. Please contact your CSM instead, or try again later.",
          );
        },
      },
    );
  };

  const downgrade = (newPlan: SubscriptionEnum) => {
    logEvent(EventNames.planDowngradeClicked, {
      "Context source": tableHeader ? "Plan comparison table" : "Plan card",
      "Current plan": currentPlan,
      "Requested plan": newPlan,
    });

    if (currentPlan === "basic") {
      downgradeBasicToFree();
    } else {
      downgradeFromGrowthOrExpert(newPlan);
    }
  };

  if (currentPlan === plan) {
    if (scrollToPlanComparison) {
      return (
        <Button variant="outline" tableHeader={tableHeader} onClick={scrollToPlanComparison}>
          See features
        </Button>
      );
    }
    return <ButtonText body>Current plan</ButtonText>;
  }
  if (plan === "freemium") {
    return (
      <Button variant="outline" tableHeader={tableHeader} onClick={() => downgrade("freemium")}>
        Change plan
      </Button>
    );
  }
  if (plan === "basic") {
    if (currentPlan === "freemium") {
      return (
        <ButtonLink
          variant="default"
          tableHeader={tableHeader}
          href="https://meetings.hubspot.com/dominic-bennett/pricing-page"
          target="_blank"
          onClick={() => upgrade("basic")}
        >
          Upgrade
        </ButtonLink>
      );
    }
    return (
      <Button variant="outline" tableHeader={tableHeader} onClick={() => downgrade("basic")}>
        Change plan
      </Button>
    );
  }
  if (plan === "growth") {
    if (currentPlan === "freemium" || currentPlan === "basic") {
      return (
        <Button variant="default" tableHeader={tableHeader} onClick={() => upgrade("growth")}>
          Get in touch
        </Button>
      );
    }
    return (
      <Button variant="outline" tableHeader={tableHeader} onClick={() => downgrade("growth")}>
        Change plan
      </Button>
    );
  }
  if (plan === "expert") {
    return (
      <Button variant="default" tableHeader={tableHeader} onClick={() => upgrade("expert")}>
        Get in touch
      </Button>
    );
  }
  return <></>;
}

interface ButtonProps {
  variant: "default" | "outline";
  tableHeader?: boolean;
}
interface ButtonStyle extends ButtonProps {
  theme: Theme;
}
const buttonStyle = ({ theme, variant, tableHeader }: ButtonStyle) => ({
  minHeight: "32px",
  paddingLeft: "12px",
  paddingRight: "12px",
  borderRadius: "4px",
  borderStyle: "solid",
  cursor: "pointer",
  alignContent: "center",
  transition: "background-color 0.2s",
  fontWeight: "400",

  ...(tableHeader
    ? {
        width: "100%",
        fontSize: "14px",

        // Keep in sync with PlanComparisonTable's .headerRow
        [`@media (max-width: calc(${sidebarWidthExpanded} + 680px))`]: {
          minHeight: "58px",
        },
      }
    : {
        width: "fit-content",
      }),

  ...(variant === "default"
    ? {
        backgroundColor: theme.colors.sysPrimaryDefault,
        color: theme.colors.sysTextWhite,
        borderWidth: "0px",
        borderColor: theme.colors.sysPrimaryDefault,

        "&:hover": {
          backgroundColor: theme.colors.sysPrimaryHover,
          color: theme.colors.sysTextWhite,
        },
      }
    : variant === "outline"
      ? {
          backgroundColor: theme.colors.sysBackgroundDefault,
          borderWidth: "1px",
          borderColor: theme.colors.sysBorderPrimary,

          "&:hover": {
            backgroundColor: theme.colors.sysBackgroundAlternative,
            color: theme.colors.sysTextDefault,
          },
        }
      : {}),

  "&:focus": {
    outlineColor: theme.colors.sysPrimaryDefault,
    outlineOffset: "2px",
    outlineWidth: "2px",
    outlineStyle: "solid",
  },
});

const Button = styled.button<ButtonProps>(({ theme, variant, tableHeader }) =>
  buttonStyle({ theme, variant, tableHeader }),
);
const ButtonLink = styled.a<ButtonProps>(({ theme, variant, tableHeader }) => ({
  ...buttonStyle({ theme, variant, tableHeader }),
  textDecoration: "none",
}));

const ButtonText = styled(Text)({
  alignContent: "center",
  minHeight: "32px",

  // Keep in sync with PlanComparisonTable's .headerRow
  [`@media (max-width: calc(${sidebarWidthExpanded} + 680px))`]: {
    minHeight: "58px",
  },
});
