/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UrlUploadRequest
 */
export interface UrlUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof UrlUploadRequest
     */
    sourceUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UrlUploadRequest
     */
    alternativeSource?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UrlUploadRequest
     */
    organisations: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UrlUploadRequest
     */
    category: UrlUploadRequestCategoryEnum;
    /**
     * 
     * @type {Date}
     * @memberof UrlUploadRequest
     */
    publishedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof UrlUploadRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UrlUploadRequest
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof UrlUploadRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UrlUploadRequest
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof UrlUploadRequest
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof UrlUploadRequest
     */
    storagePath: string;
    /**
     * 
     * @type {string}
     * @memberof UrlUploadRequest
     */
    contentHash: string;
    /**
     * 
     * @type {string}
     * @memberof UrlUploadRequest
     */
    contentType: string;
}


/**
 * @export
 */
export const UrlUploadRequestCategoryEnum = {
    Foi: 'FOI',
    MeetingMinutes: 'MEETING_MINUTES',
    ReportAndAccounts: 'REPORT_AND_ACCOUNTS',
    Strategy: 'STRATEGY',
    TribunalDecisions: 'TRIBUNAL_DECISIONS',
    GuidanceAndRegulation: 'GUIDANCE_AND_REGULATION',
    NewsAndCommunication: 'NEWS_AND_COMMUNICATION',
    ConsultationAndResearch: 'CONSULTATION_AND_RESEARCH',
    Statistics: 'STATISTICS',
    InternalOperations: 'INTERNAL_OPERATIONS',
    SpecificReport: 'SPECIFIC_REPORT',
    CaseStudy: 'CASE_STUDY',
    SpecificStrategy: 'SPECIFIC_STRATEGY',
    Budget: 'BUDGET',
    Other: 'OTHER'
} as const;
export type UrlUploadRequestCategoryEnum = typeof UrlUploadRequestCategoryEnum[keyof typeof UrlUploadRequestCategoryEnum];


/**
 * Check if a given object implements the UrlUploadRequest interface.
 */
export function instanceOfUrlUploadRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "organisations" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "publishedAt" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "storagePath" in value;
    isInstance = isInstance && "contentHash" in value;
    isInstance = isInstance && "contentType" in value;

    return isInstance;
}

export function UrlUploadRequestFromJSON(json: any): UrlUploadRequest {
    return UrlUploadRequestFromJSONTyped(json, false);
}

export function UrlUploadRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UrlUploadRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceUrl': !exists(json, 'sourceUrl') ? undefined : json['sourceUrl'],
        'alternativeSource': !exists(json, 'alternativeSource') ? undefined : json['alternativeSource'],
        'organisations': json['organisations'],
        'category': json['category'],
        'publishedAt': (new Date(json['publishedAt'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdBy': json['createdBy'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'url': json['url'],
        'storagePath': json['storagePath'],
        'contentHash': json['contentHash'],
        'contentType': json['contentType'],
    };
}

export function UrlUploadRequestToJSON(value?: UrlUploadRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourceUrl': value.sourceUrl,
        'alternativeSource': value.alternativeSource,
        'organisations': value.organisations,
        'category': value.category,
        'publishedAt': (value.publishedAt.toISOString()),
        'description': value.description,
        'createdBy': value.createdBy,
        'title': value.title,
        'fileName': value.fileName,
        'url': value.url,
        'storagePath': value.storagePath,
        'contentHash': value.contentHash,
        'contentType': value.contentType,
    };
}

