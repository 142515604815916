import React from "react";
import { Button } from "antd5";
import classNames from "classnames";
import { Tag } from "styles/utility-components";

import { assertCurrentUser } from "lib/currentUser";
import { PLAN_SEPARATION, useVariableValue } from "lib/featureFlags";
import { BasicPlan, ExpertPlan, FreePlan, GrowthPlan } from "lib/icons/PlanIcon";
import { arrowDown, checkOutlined } from "lib/icons/untitled_ui/SVGs";
import { blue50, purple50, red50, yellow50 } from "lib/themes/colors";
import { PlanActionButton } from "./SubscriptionButtons";

import css from "./SubscriptionPlans.module.scss";

export function SubscriptionPlans({
  triggerBookADemoForm,
  triggerDowngradeModal,
}: {
  triggerBookADemoForm: (value: boolean) => void;
  triggerDowngradeModal: (value: boolean) => void;
}) {
  const isPlanSeparationEnabled = useVariableValue(PLAN_SEPARATION, false);

  const currentUser = assertCurrentUser();
  const currentPlan = currentUser.subscription;

  const isFreePlan = currentPlan === "freemium";
  const isBasicPlan = currentPlan === "basic";
  const isGrowthPlan = currentPlan === "growth";
  const isExpertPlan = currentPlan === "expert";

  return (
    <div className={css.subscriptionPlans}>
      <Plan
        graphic={<Tag title="Free" color={red50} icon={<FreePlan className={css.planIcon} />} />}
        title="Free"
        billingInfo="For unlimited users"
        description={
          isPlanSeparationEnabled
            ? "Automatically find tender opportunities from every public sector portal in one place."
            : "Great for those who need a single source-of-truth for finding & organising public sector opportunities."
        }
        featurePromo="Stotles Free includes:"
        features={
          isPlanSeparationEnabled
            ? ["Pre tenders", "Open tenders", "Closed tenders", "Core workflow tools"]
            : [
                "Unlimited basic UKI procurement data",
                "Core workflow & organisation tools",
                "Self-serve access to Stotles Help-Center",
                "Unlimited users",
                "Self-serve CRM & collaboration integrations",
              ]
        }
        currentPlan={isFreePlan}
        cta={
          isPlanSeparationEnabled ? (
            <PlanActionButton
              plan="freemium"
              currentPlan={currentPlan}
              triggerBookADemoForm={triggerBookADemoForm}
              triggerDowngradeModal={triggerDowngradeModal}
              scrollToPlanComparison={scrollToPlanComparison}
            />
          ) : undefined
        }
      />
      {isPlanSeparationEnabled && (
        <Plan
          graphic={
            <Tag title="Basic" color={yellow50} icon={<BasicPlan className={css.planIcon} />} />
          }
          title="From £50"
          billingInfo="Per user / month"
          description="Create qualified pipeline earlier. Forecast upcoming expiring contracts & pre-empt renewals."
          featurePromo="Everything in Free, plus:"
          features={[
            "Upcoming expiring contracts",
            "Historically awarded contracts",
            "Live chat support",
          ]}
          currentPlan={isBasicPlan}
          cta={
            isPlanSeparationEnabled ? (
              <PlanActionButton
                plan="basic"
                currentPlan={currentPlan}
                triggerBookADemoForm={triggerBookADemoForm}
                triggerDowngradeModal={triggerDowngradeModal}
                scrollToPlanComparison={scrollToPlanComparison}
              />
            ) : undefined
          }
        />
      )}
      <Plan
        graphic={
          <Tag title="Growth" color={purple50} icon={<GrowthPlan className={css.planIcon} />} />
        }
        title={isPlanSeparationEnabled ? "From £450" : "Custom pricing"}
        billingInfo={isPlanSeparationEnabled ? "Per month" : "per user / per month"}
        description={
          isPlanSeparationEnabled
            ? "Boost next-level pipeline & routes to market with earlier buyer intent, framework intel and channel partner activity."
            : "Perfect to get ahead of tenders. Data & tools to approach the right buyers, with the right message, at the right time."
        }
        featurePromo={`Everything in ${isPlanSeparationEnabled ? "Basic" : "Free"}, plus:`}
        features={
          isPlanSeparationEnabled
            ? [
                "Buyer and competitor/partner intelligence",
                "Core customer success support",
                "Deep-dive data like frameworks, documents and spend",
                "Integration support",
              ]
            : [
                "Unlimited advanced UKI procurement data",
                "Key buyer, competitor, and partner monitoring",
                "Supported CRM & collaboration integrations",
                "Live chat support & Customer Success on-boarding support",
                "Advanced data, tools, and services available for add-on",
              ]
        }
        currentPlan={isGrowthPlan}
        cta={
          isPlanSeparationEnabled ? (
            <PlanActionButton
              plan="growth"
              currentPlan={currentPlan}
              triggerBookADemoForm={triggerBookADemoForm}
              triggerDowngradeModal={triggerDowngradeModal}
              scrollToPlanComparison={scrollToPlanComparison}
            />
          ) : undefined
        }
      />
      <Plan
        graphic={
          <Tag title="Expert" color={blue50} icon={<ExpertPlan className={css.planIcon} />} />
        }
        title={isPlanSeparationEnabled ? "Let's talk" : "Custom pricing"}
        billingInfo={isPlanSeparationEnabled ? "Custom pricing" : "per user / per month"}
        description={
          isPlanSeparationEnabled
            ? "Scale your team's sales productivity with white-glove support, customised configuration, and access to custom analyst reports."
            : "Ideal for complex needs and big growth ambitions using deeper integrations, better insights, & more support."
        }
        featurePromo="Everything in Growth, plus:"
        features={
          isPlanSeparationEnabled
            ? [
                "Decision maker contacts",
                "Advanced Customer support, advisory and enablement",
                "Multi-team configuration",
                "Custom analyst report credits",
              ]
            : [
                "Advanced buyer intent data sources",
                "Advanced teaming tools for multiple teams, territories & verticals",
                "Custom CRM and API integrations",
                "Custom reporting & analyst research",
                "Full-service Customer Success QBRs, enablement, training, & support",
              ]
        }
        currentPlan={isExpertPlan}
        cta={
          isPlanSeparationEnabled ? (
            <PlanActionButton
              plan="expert"
              currentPlan={currentPlan}
              triggerBookADemoForm={triggerBookADemoForm}
              triggerDowngradeModal={triggerDowngradeModal}
              scrollToPlanComparison={scrollToPlanComparison}
            />
          ) : undefined
        }
      />
    </div>
  );
}

function Plan({
  graphic,
  title,
  description,
  featurePromo,
  features,
  currentPlan,
  billingInfo,
  cta,
}: {
  graphic: React.ReactNode;
  title: string;
  description: string;
  featurePromo: string;
  features: string[];
  currentPlan?: boolean;
  billingInfo: string;
  cta?: JSX.Element;
}) {
  return (
    <div className={classNames(css.plan, { [css.activePlan]: currentPlan })}>
      {currentPlan && <div className={css.activePlanHeader}>Current plan</div>}
      <div className={css.planHeader}>
        <span className={css.graphic}>{graphic}</span>
        <div className={css.title}>
          <h3>{title}</h3>
          <p className={css.billingInfo}>{billingInfo}</p>
        </div>
        {cta && cta}
      </div>
      <p className={css.description}>{description}</p>
      <div>
        <p className={css.featuresHeading}>{featurePromo}</p>
        <ul>
          {features.map((feature) => (
            <li className={css.listItem} key={feature}>
              <span className={css.checkIcon}>{checkOutlined}</span>
              <span>{feature}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className={css.buttonWrapper}>
        <Button className={css.scrollToPlansButton} onClick={scrollToPlanComparison}>
          Compare full plan features
          <span className={css.arrowDownIcon}>{arrowDown}</span>
        </Button>
      </div>
    </div>
  );
}

const scrollToPlanComparison = () => {
  const element = document.getElementById("planComparisonSection");
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};
