import { useMemo } from "react";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { DateTime } from "luxon";

import {
  getSignalFiltersData,
  SignalFiltersData,
} from "../../../components/onboarding/onboardingUtils";
import {
  FeedPreviewProvider,
  MatchCountMap,
} from "../../../components/onboarding/SharedOnboarding";
import { useSubscription } from "../../providers/Subscription";
import { PreviewGuidMatchMap, SelfServeQuery, Signal, SignalCategory } from "../../StotlesApi";
import { useStotlesApi } from "../../stotlesApiContext";
import { RecordPreview, TenderStage } from "../../types/models";

export const DEFAULT_PREVIEW_LOOKBACK = DateTime.now().startOf("day").minus({ months: 6 });

type FeedPreviewResult = {
  recordPreviews: RecordPreview[];
  totalResults: number;
  guidMatchMap: PreviewGuidMatchMap;
  filterDataSets: SignalFiltersData;
  signals: Signal[];
  signalMap: Map<string, Signal>;
  recordCounts: Partial<Record<keyof SignalFiltersData, MatchCountMap | undefined>>;
};
export function useFeedPreview(
  feedCriteria: SelfServeQuery,
  companyId?: number,
  options?: UseQueryOptions<
    unknown,
    unknown,
    FeedPreviewResult,
    [string, SelfServeQuery, number | undefined]
  >,
) {
  const api = useStotlesApi();
  const feedProvider = useMemo(() => new FeedPreviewProvider(api, companyId), [api, companyId]);
  const subscription = useSubscription();
  const hasAwards = subscription.hasDataTypes("AWARDS");

  return useQuery(
    ["feedPreview", feedCriteria, companyId],
    async () => {
      const { preview_results, guid_match_map, signals } = await feedProvider.getForQuery(
        feedCriteria,
        DEFAULT_PREVIEW_LOOKBACK.toJSDate(),
      );

      const recordPreviews = hasAwards
        ? preview_results
        : preview_results.filter((r) => r.stage !== TenderStage.AWARDED);

      const filterDataSets = getSignalFiltersData(signals, guid_match_map);

      const recordCounts: Partial<Record<keyof SignalFiltersData, MatchCountMap | undefined>> = {};
      for (const [signalCategory, filterSet] of Object.entries(filterDataSets)) {
        recordCounts[signalCategory as SignalCategory] = filterSet?.counts;
      }

      const signalMap = new Map(signals.map((s) => [s.id, s]));

      return {
        recordPreviews,
        guidMatchMap: guid_match_map,
        totalResults: Object.keys(guid_match_map).length,
        filterDataSets,
        signals,
        signalMap,
        recordCounts,
      };
    },
    options,
  );
}
