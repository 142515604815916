import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { SubscriptionDowngradeRequest } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

export function useDowngrade(
  options?: UseMutationOptions<void, unknown, SubscriptionDowngradeRequest, unknown>,
) {
  const api = useOpenApi();

  return useMutation(
    (req: SubscriptionDowngradeRequest) =>
      api.downgradeSubscription({
        subscriptionDowngradeRequest: req,
      }),
    {
      ...options,
    },
  );
}
