import React, { ReactNode } from "react";
import styled from "@emotion/styled";

import { sysPrimaryDefault } from "../../themes/colors";
import * as icons from "./SVGs";

export type IconKey = keyof typeof icons;

type StyleProps = {
  color?: string;
  size?: number;
  fill?: string;
  ariaLabel?: string;
};
type SVGProps = {
  svg: ReactNode;
  className?: string;
} & StyleProps;

type IconProps = {
  icon: IconKey;
  className?: string;
} & StyleProps;

type Props = SVGProps | IconProps;
export function UIcon(props: Props) {
  let svg: ReactNode = null;

  if ("svg" in props) {
    svg = props.svg;
  } else {
    svg = icons[props.icon];
  }

  if (!svg) {
    // Handle the case where the icon doesn't exist
    return null;
  }

  // TODO: move this out of the render for performance
  const StyledIcon = styled.svg({
    width: props.size || 16,
    height: props.size || 16,
    fill: props.fill,
    color: props.color || sysPrimaryDefault,
  });

  if (React.isValidElement(svg)) {
    return (
      <StyledIcon
        as={svg.type}
        {...svg.props}
        className={props.className}
        aria-label={props.ariaLabel}
      />
    );
  }
  return null;
}

export default UIcon;
