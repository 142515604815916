import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  BidsQuery,
  BidsSearchRequest,
  BidsSearchResponse,
  FindBidByIdQuery,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const BIDS_QUERY = graphql(`
  query bids($request: BidsSearchRequest!) {
    bids(BidsSearchRequest: $request) {
      results {
        id
        createdAt
        updatedAt
        procurementStageId
      }
      totalResults
    }
  }
`);

const findBidByIdQuery = graphql(`
  query FindBidById($id: String!) {
    findBidById(id: $id) {
      id
      createdAt
      updatedAt
      procurementStageId
    }
  }
`);

export type BidsDto = BidsSearchResponse["results"][0];

export function useBids(
  searchBidsRequest: BidsSearchRequest,
  options?: UseQueryOptions<BidsQuery, unknown, BidsQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["bids", searchBidsRequest],
    BIDS_QUERY,
    [{ request: searchBidsRequest }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return { data: data?.bids, ...rest };
}

export function useFindBidById(
  bidId: string,
  options?: UseQueryOptions<FindBidByIdQuery, unknown, FindBidByIdQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(["bid", bidId], findBidByIdQuery, [{ id: bidId }], {
    ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
    ...options,
  });

  return { data: data?.findBidById, ...rest };
}
