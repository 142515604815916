import React from "react";

import { OPPORTUNTIES, useVariableValue } from "lib/featureFlags";
import { useSavedSuppliers } from "lib/hooks/api/signals/useSavedSuppliers";
import { SaveToButton } from "../SaveToButton";

export const SaveSupplierButton = ({ supplierGuid }: { supplierGuid: string }) => {
  const isOpportunitiesEnabled = useVariableValue(OPPORTUNTIES, false);

  const { data: savedSuppliers } = useSavedSuppliers();
  const { partnerGuids, competitorGuids } = savedSuppliers || {
    partnerGuids: [],
    competitorGuids: [],
  };

  const timesIsPartner = partnerGuids.filter((guid) => guid === supplierGuid).length;
  const timesIsCompetitor = competitorGuids.filter((guid) => guid === supplierGuid).length;

  if (!isOpportunitiesEnabled) {
    return null;
  }

  return (
    <SaveToButton
      entityType="supplier"
      entityId={supplierGuid}
      listCount={timesIsPartner + timesIsCompetitor}
    />
  );
};
